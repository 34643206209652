<section class="content-section-2" id="new-layout">
    <app-loader [loader]="loader"></app-loader>
    <app-breadcrumb [previousPage]="previousPage" [currentPage]="currentPage"></app-breadcrumb>

    <div class="content-section-1">
        <div class="container-fluid">
            <app-part-tiles [companyId]="companyId" #partTilesRef></app-part-tiles>
            <div class="row mb-3">
                <div class="col-xl-11 col-12">
                    <div class="row align-items-center">
                        <div class="col-lg-3">

                        </div>
                        <div class="col-lg-6 select-arrow-change-icon">
                            <app-search-bar [placeholder]="searchplaceholder" [dropDownList]="SearchCategoryies" (searchEvent)="Search($event)"></app-search-bar>
                        </div>
                        <div class="col-lg-3 d-flex justify-content-end">
                            <app-add-new-btn [addNewbtnText]="addNewbtnText"
                                (clickEvent)="AddNewPart()"></app-add-new-btn>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-3 inventory-table">
                <div class="col-xl-11 col-12">
                    <div class="table-scroll mat-elevation-z8 master-table box-shadow-none" (scroll)="onScroll($event)">
                        <mat-table [dataSource]="masterList" class="table-inventory">
                            <ng-container matColumnDef="item">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Item
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element; let i = index;" data-label="Item"
                                    class="inventory-mat-cell">
                                    {{i+1}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="partNo">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Part Number
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Part Number" class="inventory-mat-cell">
                                    {{element.partNo}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="name">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Part Name
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Part Name" class="inventory-mat-cell">
                                    {{element.name}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="uom">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> UOM
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="UOM" class="inventory-mat-cell">
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="inventoryType">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Inventory Type
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Inventory Type"
                                    class="inventory-mat-cell">
                                    {{element.inventoryType}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="onhand">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> On Hand
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="On Hand" class="inventory-mat-cell">
                                    {{element.onHandInventory}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="available">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Available
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Available" class="inventory-mat-cell">
                                    {{element.availableInventory}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="committed">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Committed
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Committed" class="inventory-mat-cell">
                                    {{element.committedInventory}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="backOrdered">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Back Ordered
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Back Ordered"
                                    class="inventory-mat-cell">
                                    {{element.backOrderedInventory}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="unitcost">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Unit Cost
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Unit Cost" class="inventory-mat-cell">
                                    {{element.cost | currency}} </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="listColumns sticky: true"
                                class="header-style inventory-header"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: listColumns;let i= index;" (click)="SelectedRow(row)"
                                [ngClass]="{'highlightTableColor': selectedRowIndex == i}"
                                class="material-inventory-row"></mat-row>
                        </mat-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>