import { Component, OnInit } from '@angular/core';
import { ConstantsService } from '../constants.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LocationSetupService } from '../services/Location/location-setup.service';
import { GlobalChangeService } from '../services/global-change.service';

@Component({
  selector: 'app-location-ware-house',
  templateUrl: './location-ware-house.component.html',
  styleUrls: ['./location-ware-house.component.css']
})
export class LocationWareHouseComponent implements OnInit {
  loader: boolean = false;
  IsLocationList: boolean = false;
  LocationDS: any;
  LocationDetailsById: any;
  LocationType: any = [];
  LocationDetails: any = [];
  LocationDetailsList: any = [];
  StatusList = [{ type: 'Active', value: true }, { type: 'In-Active', value: false }];
  FilterType: string = 'LocationGenerator'
  LocationForm: FormGroup;
  LocationGeneratorForm: FormGroup;
  LocInfLocInfsubmitted: boolean = false;
  LocInfGensubmitted: boolean = false;

  constructor(private fb: FormBuilder,
    public constants: ConstantsService,
    public locationSetupService: LocationSetupService,
    private globalChangeService: GlobalChangeService,
  ) {

  }
  ngOnInit(): void {
    this.createForm();
    this.GetLocationList();
    this.GetLocationTypeList();
    //on change function
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      this.LocationForm.patchValue({ companyId: data })
      this.IsLocationList = false;
      this.GetLocationList();
      this.GetLocationTypeList();
    })
  }
  createForm() {
    this.LocationForm = this.fb.group({
      'id': new FormControl(),
      'companyId': new FormControl(Number(localStorage.getItem('GlobalSelectedCompanyId'))),
      'locationTypeId': new FormControl(0),
      'locationName': new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
      'locationDescription': new FormControl(''),
      'isActive': new FormControl(false),
      'CreatedBy': localStorage.getItem('userId')
    });
    this.LocationGeneratorForm = this.fb.group({
      'LocationLevel1': new FormGroup({
        'id': new FormControl(),
        'nameOfLevels': new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
        'numbersOfLocations': new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
        'startingLettersNumbers': new FormControl('', [Validators.required, Validators.minLength(1), Validators.pattern('^[a-zA-Z0-9_]*$')]),
        'isActive': new FormControl(false)
      }),
      'LocationLevel2': new FormGroup({
        'id': new FormControl(),
        'nameOfLevels': new FormControl(),
        'numbersOfLocations': new FormControl(),
        'startingLettersNumbers': new FormControl('', Validators.pattern('^[a-zA-Z0-9_]*$')),
        'isActive': new FormControl(false)
      }),
      'LocationLevel3': new FormGroup({
        'id': new FormControl(),
        'nameOfLevels': new FormControl(),
        'numbersOfLocations': new FormControl(),
        'startingLettersNumbers': new FormControl('', Validators.pattern('^[a-zA-Z0-9_]*$')),
        'isActive': new FormControl(false)
      }),
      'LocationLevel4': new FormGroup({
        'id': new FormControl(),
        'nameOfLevels': new FormControl(),
        'numbersOfLocations': new FormControl(),
        'startingLettersNumbers': new FormControl('', Validators.pattern('^[a-zA-Z0-9_]*$')),
        'isActive': new FormControl(false)
      }),
    });
  }
  get LocationFormError() {
    return this.LocationForm.controls;
  }
  get LocationGeneratorFormError() {
    return this.LocationGeneratorForm.controls;
  }
  GetLocationList() {
    this.loader = true;
    this.locationSetupService.getLocationDetails(this.LocationForm.value.companyId, '')
      .subscribe({
        next: (data: any) => {
          this.LocationDS = data.dataList
          this.loader = false;
        },
        error: (err) => {
          this.loader = false
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  GetLocationTypeList() {
    this.locationSetupService.GetMasterLocationTypeList()
      .subscribe({
        next: (data: any) => {
          this.LocationType = data.data;
          this.loader = false;
        },
        error: (err) => {
          this.loader = false
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  GetLocationLevelList() {
    this.loader = true;
    this.locationSetupService.getLocationLevelList(this.LocationForm.value.companyId, this.LocationForm.value.locationTypeId, this.LocationForm.value.locationName)
      .subscribe({
        next: (data: any) => {
          this.LocationDetailsList = data.dataList;
          this.LocationDetails = this.LocationDetailsList;
          this.loader = false;
        },
        error: (err) => {
          this.loader = false
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  CreateLocation() {
    this.IsLocationList = true;
    this.LocationDetailsById = undefined
    this.ResetLocInf();
    this.ResetLocGen();
  }
  ResetLocInf() {
    this.LocationForm.reset();
    this.LocationForm.patchValue({ companyId: Number(localStorage.getItem('GlobalSelectedCompanyId')), locationTypeId: 0, isActive: false, CreatedBy: localStorage.getItem('userId') })
  }
  ResetLocGen() {
    this.LocationGeneratorForm.reset();
    this.LocationGeneratorForm.patchValue({ isActive: false })
  }
  selectedRow(row: any) {
    this.IsLocationList = true;
    this.LocationDetailsById = row;
    this.BindData();
  }
  BindData() {
    if (this.LocationDetailsById != undefined) {
      this.BindLocationInfo();
      this.BindLocationGen();
    } else {
      this.CreateLocation();
    }
  }
  BindLocationInfo() {
    this.LocationForm.patchValue({
      id: this.LocationDetailsById.id,
      companyId: this.LocationDetailsById.companyId,
      locationTypeId: this.LocationDetailsById.locationTypeId,
      locationName: this.LocationDetailsById.locationName,
      locationDescription: this.LocationDetailsById.locationDescription,
      isActive: this.LocationDetailsById.isActive,
    });
  }
  BindLocationGen() {
    if (this.LocationDetailsById.lL1IsActive) {
      this.LocationGeneratorForm.patchValue({
        LocationLevel1: {
          id: this.LocationDetailsById.level1Id,
          nameOfLevels: this.LocationDetailsById.nameOfLevels1,
          numbersOfLocations: this.LocationDetailsById.numbersOfLocations1,
          startingLettersNumbers: this.LocationDetailsById.startingLettersNumbers1,
        }
      });
    }
    if (this.LocationDetailsById.lL2IsActive) {
      this.LocationGeneratorForm.patchValue({
        LocationLevel2: {
          id: this.LocationDetailsById.level2Id,
          nameOfLevels: this.LocationDetailsById.nameOfLevels2,
          numbersOfLocations: this.LocationDetailsById.numbersOfLocations2,
          startingLettersNumbers: this.LocationDetailsById.startingLettersNumbers2,
        }
      });
    }
    if (this.LocationDetailsById.lL3IsActive) {
      this.LocationGeneratorForm.patchValue({
        LocationLevel3: {
          id: this.LocationDetailsById.level3Id,
          nameOfLevels: this.LocationDetailsById.nameOfLevels3,
          numbersOfLocations: this.LocationDetailsById.numbersOfLocations3,
          startingLettersNumbers: this.LocationDetailsById.startingLettersNumbers3,
        }
      });
    }
    if (this.LocationDetailsById.lL4IsActive) {
      this.LocationGeneratorForm.patchValue({
        LocationLevel4: {
          id: this.LocationDetailsById.level4Id,
          nameOfLevels: this.LocationDetailsById.nameOfLevels4,
          numbersOfLocations: this.LocationDetailsById.numbersOfLocations4,
          startingLettersNumbers: this.LocationDetailsById.startingLettersNumbers4,
        }
      });
    }
  }
  ChangeType(value: any) {
    this.FilterType = value
    if (this.FilterType == 'LocationDetails') {
      this.GetLocationLevelList();
    }
  }
  Cancel() {
    this.BindData();
  }
  Save() {
    this.LocInfLocInfsubmitted = true;
    if (this.LocationForm.valid) {
      this.loader = true;
      this.locationSetupService.AddUpdateLocationDetails(this.LocationForm.value)
        .subscribe({
          next: (data: any) => {
            this.LocInfLocInfsubmitted = false;
            this.loader = false;
            if (!data.success) {
              this.constants.SwalModal('Message', data.successMessage);
            }
            this.LocationForm.value.id > 0 ? this.BindLocationGen() : this.BindData()
          },
          error: (err) => {
            this.loader = false
            this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
          },
        });
    }
  }
  GenLocation() {
    this.LocInfGensubmitted = true;
    if (this.LocationGeneratorForm.valid) {
      this.loader = true;
      let data = Object.assign(this.LocationGeneratorForm.value, this.LocationForm.value);
      this.locationSetupService.AddUpdateLocation(data)
        .subscribe({
          next: (data: any) => {
            this.LocInfGensubmitted = false;
            this.BindLocationInfo();
            this.ChangeType('LocationDetails');
          },
          error: (err) => {
            this.loader = false
            this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
          },
        });
    }
  }
  AddUpdLocationPositions(status: boolean, Id: any) {
    this.loader = true;
    this.locationSetupService.AddUpdLocationPositions(status, Id, localStorage.getItem('userId'))
      .subscribe({
        next: (data: any) => {
          this.ChangeType('LocationDetails');
        },
        error: (err) => {
          this.loader = false
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  BackToList() {
    this.IsLocationList = false;
    this.LocationDetailsById = undefined
    this.FilterType = 'LocationGenerator';
    this.GetLocationList();
  }
  DeleteWareHouseLocation(value: number) {
    this.loader = true;
    this.locationSetupService.deleteLocationLevelDetails(value)
      .subscribe({
        next: (data: any) => {
          this.loader = false;
          switch (data.statusCode) {
            case 200:
              this.GetLocationList();
              this.constants.SwalModal(this.constants.Success, this.constants.RecordDeletedSuccessfully);
              break;
            case 208:
              this.constants.SwalModal(this.constants.Alert, this.constants.LocationAlreadyUsed);
              break;
            default:
              this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
          }
        },
        error: (err) => {
          this.loader = false
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  SearchLocationDetails(value: string) {
    if (!this.ISNullOrEmpty(value)) {
      this.LocationDetails = this.LocationDetailsList.filter(x => x.description.trim().toUpperCase().includes(value.trim().toUpperCase()) || x.name.trim().toUpperCase().includes(value.trim().toUpperCase()));
    } else {
      this.LocationDetails = this.LocationDetailsList
    }
  }
  ISNullOrEmpty(value: any): boolean {
    let result = false;
    if (value === null || value === undefined || value === '') {
      result = true;
    }
    return result;
  }
}
