import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ReplenishmentComponent } from './replenishment/replenishment.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs';
import { PutAwayService } from 'src/app/services/put-away.service';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { ConstantsService } from 'src/app/constants.service';
import Swal from 'sweetalert2';
import { SharedService } from 'src/app/services/shared.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { InventoryService } from 'src/app/services/inventory/inventory.service';
import { PurchaseOrderService } from 'src/app/services/purchaseOrder/purchase-order.service';
import { PutAway } from 'src/app/shared/putAway';
import { PartService } from 'src/app/services/part/part.service';
import { PickListService } from 'src/app/services/pickList/pick-list.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-putaway',
  templateUrl: './putaway.component.html',
  styleUrls: ['./putaway.component.css']
})
export class PutawayComponent implements OnInit {
  ImagesModal: boolean = false;
  loader: boolean = false;
  IsNext: number = 0;
  isputAway: boolean = false;
  putawayForm: FormGroup;
  isAddLocation: boolean = false;
  isdefaultLocation: boolean = false;
  IsPickList: boolean = false;
  showNewTransaction: boolean = false;
  @ViewChild("LocationCapacityModal") public LocationCapacityModal: TemplateRef<any>;
  private widgetEditorDialogRef: MatDialogRef<TemplateRef<any>>;
  @ViewChild("locationModal") public locationModal: TemplateRef<any>;
  private locationDialogRef: MatDialogRef<TemplateRef<any>>;
  @ViewChild("PartNumberModal") public PartNumberModal: TemplateRef<any>;
  private PartNumberDialogRef: MatDialogRef<TemplateRef<any>>;
  @ViewChild("ConfirmModal") public ConfirmModal: TemplateRef<any>;
  private ConfirmModalDialogRef: MatDialogRef<TemplateRef<any>>;
  isShowList: boolean = false;
  isProgressDetail: boolean = false;
  isModalOpen: boolean = false;
  isSerialFlyout: boolean = false;
  putAwayList: any;
  location: any[] = [];
  result: any;
  companyId: number;
  received: any[] = [];
  RecLocationList: any = [];
  CompanyWareHouseList: any = [];
  statuslist: any = [];
  selectArr: any;
  selectedId: any[] = [];
  showBtn: boolean = false;
  putAwayPart: any = [];
  locationForm: FormGroup;
  level1List: any = [];
  level2List: any = [];
  level3List: any = [];
  level4List: any = [];
  defaultLevel1List: any = [];
  defaultLevel2List: any = [];
  defaultLevel3List: any = [];
  defaultLevel4List: any = [];
  isCreatePutAway: boolean = true;
  ConstImgURL: string = '';

  //Property for sliders 
  disabled = false;
  max = 100;
  min = 0;
  showTicks = false;
  step = 1;
  thumbLabel = false;
  value: number = 1;
  counter: number = 1;
  locationArray: any = [];
  PutAwayListModal: any;
  PutAwayListModalAddress: string;
  PutAwayListModalPartNO: string;
  putAwayArrayList: any[];

  //properties for serial No form 
  serialLotForm: FormGroup;
  serialNumberArray: FormArray;
  TrackingObj: any = [];
  selectedInventoryId: number = 0;
  inventoryTracking: any;
  serialNumberList: number[] = [];
  IsScan: boolean = false;
  ScanIndex: number = 0;
  selectedItemid: number;

  //properties for Inventory Loss
  infoTracking: any;
  inventoryLossForm: FormGroup;
  transactionTypeVal: number = 0;
  InvActionList: any = [];
  InvTypeList: any = [];
  InvActionListFull: any = [];
  InvActiveList: any = [];
  InvStatusList: any = [];
  InvDocTypeList: any = [];
  invReasonList: any = [];
  invlossReasonList: any = [];
  invgainReasonList: any = [];
  invmoveTypeList: any = [];
  transactionTypeLabel: string = this.constants.transactionTypeLabel;
  BatchList: any = [];
  HBatchList: any = [];
  locationCapacity: any;
  locationCapacityValue: any;
  partId: boolean;
  flyoutObject: any = {};
  sku: any;
  tracking: any;
  transId: any;
  selectedPart: any;
  selectedLoactionId: any;
  aisle: any;
  section: any;
  shelf: any;
  bin: any;
  selectedpartId: any;
  selectedWareHouse: any;
  pickListImages: any = [];
  seriallotflyoutObj: any = {};
  PutAwayObj: any;
  PartTrackingList = [];
  showPutAway: boolean = false;
  hidePutAwayList: boolean = false;
  searchText: string = '';
  moveQuantity: any;
  LocIndex: number = -1;
  actualTracking: any;
  hidePrev: boolean = true;
  hideNxt: boolean = true;
  confirmForm: FormGroup;
  partLocationForm: FormGroup;
  partNumberForm: FormGroup;
  partIds: any[] = [];
  serailObj: any;
  selectAll: boolean = false;
  TempbarScanValue: string;
  barScanValue: string;
  IsScanGun: boolean = false;
  submitted: boolean = false;

  noData: boolean;
  partLocation: boolean;
  showCongrats: boolean = false;
  sumOfMoveQty: any;
  dialogRefs: any[] = [];
  currentRoute: string;
  selected: boolean;
  LocationSetUp: any;
  ChildLocationSetUp: any;
  IsParentLoc: boolean = false;
  LocI: number = 0;
  LocJ: number = 0;
  maxSliderValue: number;
  slidervalue: number;
  isActivePA: any;
  isActiveId: number;
  movedValue: any;
  isBack: boolean = false;
  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private paService: PutAwayService,
    private globalChangeService: GlobalChangeService,
    private constants: ConstantsService,
    private sharedService: SharedService,
    private companyService: CompanyService,
    private POService: PurchaseOrderService,
    private formBuilder: FormBuilder,
    private inventoryService: InventoryService,
    private partService: PartService,
    public pickListService: PickListService,
    private router: Router
  ) {
    this.ConstImgURL = constants.PickListImagURL;
    this.isActivePA = constants.isActivePA;
    this.getStatusList();
    this.createLocation();
    this.getTrackingList();
    this.createConfirmForm();
    this.createpartLocationForm();
    this.createpartNumberForm();
  }

  ngOnInit(): void {
    this.isputAway = true;
    this.isAddLocation = true;
    this.isdefaultLocation = false;
    this.IsPickList = false;
    this.isShowList = false;
    this.isProgressDetail = false;
    this.isSerialFlyout = false;
    this.showBtn = true;
    this.received = this.constants.receivedId;
    this.hidePutAwayList = false;
    this.companyId = this.globalChangeService.getGlobalCompany();
    this.isCreatePutAway = true;
    this.showCongrats = false;
    if (this.companyId > 0) {
      this.getWareHouseWithChild(this.companyId);
      this.getLocationName(this.companyId);
    } else {
      this.popupModal(this.constants.APIError, this.constants.SelectCompany);
    }
    //on change function 
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      this.companyId = data;
      this.GetPutAwayList('');
    })
  }

  createConfirmForm() {
    this.confirmForm = new FormGroup({
      moveQty: new FormControl(0)
    })
  }

  createpartLocationForm() {
    this.partLocationForm = new FormGroup({
      partLocation: new FormControl(null)
    })
  }

  createpartNumberForm() {
    this.partNumberForm = new FormGroup({
      partNumber: new FormControl(null)
    })
  }

  createLocation() {
    this.hidePutAwayList = false;
    this.locationForm = this.fb.group({
      DLocation: this.fb.array([])
    });
  }

  getTrackingList() {
    this.partService.GetPartTrackingList().subscribe(
      (data: any) => {
        if (data.length > 0) {
          this.PartTrackingList = data;
        }
      }
    )
  }

  checkTracking(id) {
    const trackingData = this.PartTrackingList.filter(x => x.id == id);
    return trackingData[0].name;
  }

  //Start for Location Array On Mat Card 
  DLocation(): FormArray {
    return this.locationForm.get('DLocation') as FormArray;
  }

  AddLocation(value: any): FormGroup {
    this.hidePutAwayList = false;
    let loc = "";
    if (value.address != null && value.address != undefined) {
      loc = value.address.split('-')
    }
    return this.fb.group({
      image: value.imgPath,
      transID: value.transID,
      partId: value.partId,
      wareHouse: value.wareHouse,
      locName: value.location,
      id: value.id,
      aisle: loc == "" ? "" : loc[0],
      section: loc == "" ? "" : loc[1],
      shelf: loc == "" ? "" : loc[2],
      bin: loc == "" ? "" : loc[3],
      onhand: value.onHand,
      locCapacity: value.locationCapacity,
      moveQty: 0,
      isShow: false,
      totalPutAwayQty: 0,
      sku: value.sku,
      invType: value.invType,
      tracking: value.tracking,
      description: value.infoItemDescription,
      AdditionalLocation: this.fb.array([])
    });
  }

  addLocation(value: any) {
    this.DLocation().push(this.AddLocation(value));
  }


  GetDefaultLoaction(Index: number): FormArray {
    return this.DLocation()
      .at(Index)
      .get('AdditionalLocation') as FormArray;
  }

  newAddLocation(): FormGroup {
    return this.fb.group({
      wareHouse: 0,
      locName: 0,
      aisle: '',
      section: '',
      shelf: '',
      bin: '',
      onhand: 0,
      moveQty: 0,
      locCapacity: 0,
      level1List: [],
      level2List: [],
      level3List: [],
      level4List: [],
    });
  }

  addAdditionalocation(Index: number, locPartId: any, i: number, j: number) {
    if (locPartId != null || locPartId != undefined) {
      this.GetDefaultLoaction(Index).push(this.newAddLocation());
      this.selectedpartId = locPartId;
      // Added By Navneet
      this.IsParentLoc = false;
      this.LocI = Index;
      this.LocJ = this.locationForm.value.DLocation[Index].AdditionalLocation.length - 1;
      this.ChildLocationSetUp = {
        "locationId": this.locationForm.value.DLocation[Index].AdditionalLocation[this.LocJ].locName,
        "wareHouseId": this.locationForm.value.DLocation[Index].AdditionalLocation[this.LocJ].wareHouse,
        "companyId": this.companyId,
        "level1": this.locationForm.value.DLocation[Index].AdditionalLocation[this.LocJ].aisle,
        "level2": this.locationForm.value.DLocation[Index].AdditionalLocation[this.LocJ].section,
        "level3": this.locationForm.value.DLocation[Index].AdditionalLocation[this.LocJ].shelf,
        "level4": this.locationForm.value.DLocation[Index].AdditionalLocation[this.LocJ].bin,
        "pageName": 'Putaway',
        "Heading": '',
        "SubHeading": ''
      }
      // Ended By Navneet
    }
  }
  IsDisabledLocation(): boolean {
    let IsDisabled = false;
    if (this.MaxQty(false, this.infoTracking, 0) >= this.putAwayPart[this.IsNext].items) {
      IsDisabled = true;
    }
    return IsDisabled;
  }
  MaxQty(IsChild: boolean, i: number, j: number): number {
    let Qty = 0;
    if (!IsChild) {
      if (this.locationForm.value.DLocation[this.IsNext].locCapacity - this.locationForm.value.DLocation[this.IsNext].onhand >= this.putAwayPart[this.IsNext].items) {
        Qty = this.putAwayPart[this.IsNext].items;
      } else {
        Qty = this.locationForm.value.DLocation[this.IsNext].locCapacity - this.locationForm.value.DLocation[this.IsNext].onhand;
      }
    } else {
      if (this.locationForm.value.DLocation[this.IsNext].AdditionalLocation[j].locCapacity - this.locationForm.value.DLocation[this.IsNext].AdditionalLocation[j].onhand >= this.putAwayPart[this.IsNext].items - this.MaxQty(false, i, 0)) {
        Qty = this.putAwayPart[this.IsNext].items - this.MaxQty(false, i, j);
      } else {
        Qty = this.locationForm.value.DLocation[this.IsNext].AdditionalLocation[j].locCapacity - this.locationForm.value.DLocation[this.IsNext].AdditionalLocation[j].onhand;
      }
    }
    return Qty;
  }
  ChangeMoveQty(i: number, IsChild: boolean, j: number,) {
    let qty = 0;
    // qty = Number(this.locationForm.value.DLocation[i].moveQty)
    // this.locationForm.value.DLocation[i].AdditionalLocation.forEach(q => {
    //   qty = qty + Number(q.moveQty);
    // });
    if (!IsChild) {
      if (Number(this.locationForm.value.DLocation[i].moveQty) > this.MaxQty(false, i, 0)) {
        this.locationForm.get('DLocation')['controls'][i].get('moveQty').setErrors({ 'Exceed': true });
        this.hidePutAwayList = true;
      } else {
        this.locationForm.get('DLocation')['controls'][i].get('moveQty').setErrors(null);
        this.hidePutAwayList = false;
      }
    } else {
      if (Number(this.locationForm.value.DLocation[i].AdditionalLocation[j].moveQty) > this.MaxQty(true, i, j)) {
        this.locationForm.get('DLocation')['controls'][i].get('AdditionalLocation')['controls'][j].get('moveQty').setErrors({ 'Exceed': true });
        this.hidePutAwayList = true;
      } else {
        this.locationForm.get('DLocation')['controls'][i].get('AdditionalLocation')['controls'][j].get('moveQty').setErrors(null);
        this.hidePutAwayList = false;
      }
    }
  }
  removeAdditionalocation(index: number, Index: number) {
    this.GetDefaultLoaction(index).removeAt(Index);
  }
  removeDefaultlocation(i: number) {
    this.locationForm.value.DLocation[i].isShow = false;
    this.locationForm.patchValue({ DLocation: this.locationForm.value.DLocation })
  }
  //End for Location 
  AddLocationCount(type: string, i: number, j: number, IsChild: boolean) {
    if (IsChild) {
      if (type == 'add' && this.putAwayPart[this.IsNext].items > this.locationForm.value.DLocation[i].AdditionalLocation[j].moveQty) {
        this.locationForm.value.DLocation[i].AdditionalLocation[j].moveQty++;
      } else if (type == 'sub' && this.locationForm.value.DLocation[i].AdditionalLocation[j].moveQty >= 1) {
        this.locationForm.value.DLocation[i].AdditionalLocation[j].moveQty--;
        this.locationForm.value.DLocation[i].AdditionalLocation[j].moveQty < 1 == true ? (this.locationForm.value.DLocation[i].AdditionalLocation[j].moveQty = 1) : this.locationForm.value.DLocation[i].AdditionalLocation[j].moveQty;
      }
    } else {
      if (type == 'add' && this.putAwayPart[this.IsNext].items > this.locationForm.value.DLocation[i].moveQty) {
        this.locationForm.value.DLocation[i].moveQty++;
      } else if (type == 'sub' && this.locationForm.value.DLocation[i].moveQty >= 1) {
        this.locationForm.value.DLocation[i].moveQty--;
        this.locationForm.value.DLocation[i].moveQty < 1 == true ? (this.locationForm.value.DLocation[i].moveQty = 1) : this.locationForm.value.DLocation[i].moveQty;
      }
    }
    this.locationForm.patchValue({ DLocation: this.locationForm.value.DLocation })
    this.ChangeMoveQty(i, IsChild, j);
  }

  popupModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
  // Added By Navneet
  saveDefaultLocation(value: any) {
    if (this.IsParentLoc) {
      this.locationForm.value.DLocation[this.IsNext].wareHouse = value.wareHouseId;
      this.locationForm.value.DLocation[this.IsNext].locName = value.locationId;
      this.locationForm.value.DLocation[this.IsNext].aisle = value.level1;
      this.locationForm.value.DLocation[this.IsNext].section = value.level2;
      this.locationForm.value.DLocation[this.IsNext].shelf = value.level3;
      this.locationForm.value.DLocation[this.IsNext].bin = value.level4;
      this.locationForm.patchValue({ DLocation: this.locationForm.value.DLocation })
    } else {
      this.locationForm.value.DLocation[this.LocI].AdditionalLocation[this.LocJ].wareHouse = value.wareHouseId;
      this.locationForm.value.DLocation[this.LocI].AdditionalLocation[this.LocJ].locName = value.locationId;
      this.locationForm.value.DLocation[this.LocI].AdditionalLocation[this.LocJ].aisle = value.level1;
      this.locationForm.value.DLocation[this.LocI].AdditionalLocation[this.LocJ].section = value.level2;
      this.locationForm.value.DLocation[this.LocI].AdditionalLocation[this.LocJ].shelf = value.level3;
      this.locationForm.value.DLocation[this.LocI].AdditionalLocation[this.LocJ].bin = value.level4;
      this.locationForm.get('DLocation')['controls'][this.LocI].patchValue(this.locationForm.value.DLocation[this.LocI])
    }
  }
  // Ended By Navneet

  saveDefaultChangeLocation(partId: any, value: any) {
    if (value != undefined && value != null) {
      this.loader = true;
      let obj = {
        partId: partId,
        wareHouseId: this.locationForm.value.DLocation[value].wareHouse,
        locationId: this.locationForm.value.DLocation[value].locName,
        level1: this.locationForm.value.DLocation[value].aisle,
        level2: this.locationForm.value.DLocation[value].section,
        level3: this.locationForm.value.DLocation[value].shelf,
        level4: this.locationForm.value.DLocation[value].bin,
        locationCapacity: this.locationForm.value.DLocation[value].locCapacity,
        isActive: true
      }
      this.locationForm.patchValue({ DLocation: this.locationForm.value.DLocation })
      this.inventoryService.AddInventoryLocation(obj, true).subscribe(
        (response: any) => {
          this.loader = false;
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Default Location saved",
            showConfirmButton: false,
            timer: 1500
          })
        },
        (error: any) => {
          this.loader = false;
        }
      )
    } else {
      this.LocationSetUp = undefined;
    }
  }

  getLocationLevels(locationId: number) {
    // Added By Navneet
    if (locationId > 0) {
      this.defaultLocation();
    }
    this.IsParentLoc = true;
    this.locationForm.value.DLocation[this.IsNext].isShow = true;
    this.locationForm.patchValue({ DLocation: this.locationForm.value.DLocation });
    this.LocationSetUp = undefined;
    this.LocationSetUp = {
      "locationId": this.locationForm.value.DLocation[this.IsNext].locName,
      "wareHouseId": this.locationForm.value.DLocation[this.IsNext].wareHouse,
      "companyId": this.companyId,
      "level1": this.locationForm.value.DLocation[this.IsNext].aisle,
      "level2": this.locationForm.value.DLocation[this.IsNext].section,
      "level3": this.locationForm.value.DLocation[this.IsNext].shelf,
      "level4": this.locationForm.value.DLocation[this.IsNext].bin,
      "pageName": 'Putaway',
      "Heading": '',
      "SubHeading": ''
    }
    // Ended By Navneet
    // this.loader = true;
    // this.inventoryService.GetLocationLevelsPositions(this.companyId, locationId)
    //   .subscribe({
    //     next: (data: any) => {
    //       this.loader = false;
    //       if (data.dataList.length > 0) {
    //         const Asile = data.dataList.map(function (item) { return item['aileName']; });
    //         const Section = data.dataList.map(function (item) { return item['sectionName']; });
    //         const Shelf = data.dataList.map(function (item) { return item['shelfName']; });
    //         const Bin = data.dataList.map(function (item) { return item['positionName']; });
    //         this.level1List = [];
    //         this.level2List = [];
    //         this.level3List = [];
    //         this.level4List = [];
    //         this.level1List.push(...(Asile.filter((value, index, array) => array.indexOf(value) === index)));
    //         this.level2List.push(...(Section.filter((value, index, array) => array.indexOf(value) === index)));
    //         this.level3List.push(...(Shelf.filter((value, index, array) => array.indexOf(value) === index)));
    //         this.level4List.push(...(Bin.filter((value, index, array) => array.indexOf(value) === index)));
    //         this.defaultLocation();
    //       }
    //     },
    //     error: (error: any) => {
    //       this.loader = false;
    //       this.popupModal(this.constants.APIError, error);
    //     },
    //     complete: () => {
    //     }
    //   });
  }

  defaultLocation() {
    this.isdefaultLocation = !this.isdefaultLocation;
    if (this.putAwayPart.length > 0) {
      let dLoc = this.putAwayPart[this.IsNext];
      if (dLoc != undefined) {
        let loc = dLoc.address.split('-')
        this.locationForm.value.DLocation[this.IsNext].isShow = true;
        this.locationForm.value.DLocation[this.IsNext].wareHouse = dLoc.wareHouse;
        this.locationForm.value.DLocation[this.IsNext].onhand = dLoc.onHand;
        this.locationForm.value.DLocation[this.IsNext].locCapacity = dLoc.locationCapacity;
        this.locationForm.value.DLocation[this.IsNext].locName = dLoc.location;
        this.locationForm.value.DLocation[this.IsNext].aisle = loc[0];
        this.locationForm.value.DLocation[this.IsNext].section = loc[1];
        this.locationForm.value.DLocation[this.IsNext].shelf = loc[2];
        this.locationForm.value.DLocation[this.IsNext].bin = loc[3];
        this.locationForm.patchValue({ DLocation: this.locationForm.value.DLocation })
        if (dLoc.onHand > 0 && dLoc.onHand <= dLoc.locationCapacity) {
          this.maxSliderValue = dLoc.locationCapacity - dLoc.onHand;
          this.slidervalue = Math.min(this.maxSliderValue, this.putAwayPart[this.IsNext].items)
        } else {
          // this.slidervalue = 0;
        }
      }
    }
  }

  selectPutAway() {
    if (this.selectedId.length > 0) {
      this.loader = true;
      this.GetInvPartData();
      this.isputAway = false;
      this.showBtn = false;
      this.showPutAway = true;
      this.loader = false;
    } else {
      this.showBtn = true;
      this.showPutAway = false;
    }
  }

  onSearch() {
    this.GetPutAwayList(this.searchText);
  }

  createReplenishment(partId: any) {
    const obj = {
      com: this.companyId,
      partId: partId
    }
    this.dialog.open(ReplenishmentComponent, {
      data: obj,
      height: '750px',
      width: '750px',
      maxWidth: '750px'
    })
  }

  editOnHand(value) {
    this.partId = value.partId;
    this.sku = value.sku;
    this.tracking = value.tracking;
    this.transId = value.transId;
    this.selectedPart = value;
    this.OpenTransFlyout(0);
  }

  openLocationCapacityDialog(item: any) {
    this.locationCapacity = item;
    if (this.widgetEditorDialogRef) { return; }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.role = "dialog";
    dialogConfig.width = "400px";
    dialogConfig.minHeight = "100px";
    dialogConfig.maxHeight = "60vh";
    dialogConfig.position = { top: "28vh" };
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    this.widgetEditorDialogRef = this.dialog.open(
      this.LocationCapacityModal,
      dialogConfig
    );
    this.widgetEditorDialogRef.afterOpened().subscribe((result) => {
      this.isModalOpen = true;
    });

    this.widgetEditorDialogRef
      .afterClosed()
      .pipe(finalize(() => (this.widgetEditorDialogRef = undefined)))
      .subscribe((result) => {
        this.isModalOpen = false;
      });
  }


  openLocationDialog(item: any, LocIndex: number) {
    this.LocIndex = LocIndex;
    this.IsScanGun = true;
    this.submitted = true;
    this.PutAwayListModal = item;
    this.PutAwayListModalAddress = item.address;
    this.PutAwayListModalPartNO = item.sku;
    this.moveQuantity = item.moveQty;
    this.counter = item.moveQty;
    if (this.locationDialogRef) { return; }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.role = "dialog";
    dialogConfig.width = "400px";
    dialogConfig.minHeight = "100px";
    dialogConfig.maxHeight = "60vh";
    dialogConfig.position = { top: "28vh" };
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    this.locationDialogRef = this.dialog.open(
      this.locationModal,
      dialogConfig
    );
    this.dialogRefs.push(this.locationDialogRef); // Store reference in array
    this.locationDialogRef.afterOpened().subscribe((result) => {
      this.isModalOpen = true;
    });
    this.locationDialogRef
      .afterClosed()
      .pipe(finalize(() => (this.locationDialogRef = undefined)))
      .subscribe((result) => {
        this.isModalOpen = false;
        this.IsScanGun = false;
      });
  }

  openPartNumberDialog() {
    this.IsScanGun = true;
    this.submitted = true;
    if (this.PartNumberDialogRef) { return; }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.role = "dialog";
    dialogConfig.width = "400px";
    dialogConfig.minHeight = "100px";
    dialogConfig.maxHeight = "60vh";
    dialogConfig.position = { top: "28vh" };
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = false;
    if (this.partLocationForm.value.partLocation != null) {
      this.PartNumberDialogRef = this.dialog.open(
        this.PartNumberModal,
        dialogConfig
      );
      this.dialogRefs.push(this.PartNumberDialogRef); // Store reference in array
      this.PartNumberDialogRef
        .afterClosed()
        .pipe(finalize(() => (this.PartNumberDialogRef = undefined)))
        .subscribe((result) => {
          this.isModalOpen = false;
          this.partLocationForm.reset();
        });
    } else {
      dialogConfig.disableClose = true;
      this.isModalOpen = false;
    }
  }

  openConfirmDialog() {
    this.counter = this.confirmForm.value.moveQty;
    if (this.ConfirmModalDialogRef) { return; }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.role = "dialog";
    dialogConfig.width = "450px";
    dialogConfig.minHeight = "100px";
    dialogConfig.maxHeight = "60vh";
    dialogConfig.position = { top: "28vh" };
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = false;
    if (this.partNumberForm.value.partNumber != null) {
      this.ConfirmModalDialogRef = this.dialog.open(
        this.ConfirmModal,
        dialogConfig
      );
      this.dialogRefs.push(this.ConfirmModalDialogRef); // Store reference in array
      this.ConfirmModalDialogRef.afterOpened().subscribe((result) => {
        this.isModalOpen = true;
      });
      this.ConfirmModalDialogRef
        .afterClosed()
        .pipe(finalize(() => (this.ConfirmModalDialogRef = undefined)))
        .subscribe((result) => {
          this.isModalOpen = false;
          this.partNumberForm.reset();
        });
    } else {
      dialogConfig.disableClose = true;
      this.isModalOpen = false;
    }
  }

  closeAllDialogs() {
    this.dialogRefs.forEach(ref => ref.close());
    this.dialogRefs = []; // Clear the references
    this.partLocationForm.reset();
    this.partNumberForm.reset();
    this.confirmForm.reset();
  }

  @HostListener('document:keypress', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.IsScanGun) {
      if (event.key === 'Enter') {
        if (this.TempbarScanValue != undefined) {
          if (this.TempbarScanValue != undefined) {
            if (this.partLocation == true) {
              this.partLocationForm.patchValue({ partLocation: this.TempbarScanValue })
              this.barScanValue = '';
              if (this.partLocationForm.value.partLocation != this.PutAwayListModal.address) {
                this.partLocationForm.get('partLocation').setErrors({ AddressError: true });
              } else {
                this.partLocationForm.get('partLocation').setErrors(null);
              }
            } else {
              this.partNumberForm.patchValue({ partNumber: this.TempbarScanValue })
              this.barScanValue = '';
              if (this.partNumberForm.value.partNumber != this.PutAwayListModal.sku) {
                this.partNumberForm.get('partNumber').setErrors({ PartNumberError: true });
              } else {
                this.partNumberForm.get('partNumber').setErrors(null);
              }
            }
          }
        }
        this.TempbarScanValue = '';
      } else {
        if (this.TempbarScanValue === '') {
          this.TempbarScanValue = event.key;
        } else {
          this.TempbarScanValue = this.TempbarScanValue + event.key;
        }
      }
    }
  }

  ChangePartLocation() {
    if (this.partLocationForm.value.partLocation.toUpperCase() != this.PutAwayListModal.address.toUpperCase()) {
      this.partLocationForm.get('partLocation').setErrors({ AddressError: true });
    } else {
      this.partLocationForm.get('partLocation').setErrors(null);
    }
  }

  ChangePartNumber() {
    if (this.partNumberForm.value.partNumber.toUpperCase() != this.PutAwayListModal.sku.toUpperCase()) {
      this.partNumberForm.get('partNumber').setErrors({ PartNumberError: true });
    } else {
      this.partNumberForm.get('partNumber').setErrors(null);
    }
  }

  GetActiveStatus(event: any) {
    this.isActiveId = event.target.value;
    this.GetPutAwayList('');
  }

  GetPutAwayList(searchText) {
    this.loader = true;
    this.isProgressDetail = false;
    this.showCongrats = false;
    let data = {
      CompanyId: this.companyId,
      StatusId: this.received,
      SearchText: searchText
    }
    this.paService.GetPutAwayList(data).subscribe((data: any) => {
      if (data.dataList.length > 0) {
        this.loader = false;
        this.noData = false;
        let validPutAway = data.dataList.filter(x => x.quantity > 0);
        const value = Number(this.isActiveId);
        switch (value) {
          case 1:
            this.putAwayList = data.dataList;
            break;
          case 2:
            this.putAwayList = data.dataList.filter(x => x.statusActive === 1);
            break;
          case 3:
            this.putAwayList = data.dataList.filter(x => x.statusActive === 0);
            break;
          default:
            this.putAwayList = data.dataList;
            break;
        }
      } else if (data.errorMessage == "Record Not Found.") {
        this.loader = false;
        this.noData = true;
      } else {
        this.loader = false;
        this.noData = false;
        this.putAwayList = [];
      }
    }, error => {
      this.loader = false;
      this.popupModal(this.constants.APIError, error);
    })
  }

  Counter(type: string) {
    this.showCongrats = false;
    if (type == 'add' && this.TotalMoveQty(this.PutAwayListModal.partId) > this.counter) {
      this.max = this.TotalMoveQty(this.PutAwayListModal.partId);
      this.counter++;
      this.confirmForm.patchValue({ moveQty: this.counter });
    } else if (type == 'sub' && this.counter >= 1) {
      this.counter--;
      this.counter < 1 == true ? (this.counter = 1) : this.counter;
      this.confirmForm.patchValue({ moveQty: this.counter });
    }
  }

  GetInvPartData() {
    this.loader = true;
    this.showCongrats = false;
    let data = {
      Ids: this.selectedId,
      CompanyId: this.companyId,
      PartIds: this.partIds
    }
    this.paService.GetPoInvData(data).subscribe({
      next: (data: any) => {
        this.loader = false;
        this.putAwayPart = data.dataList;
        if (this.putAwayPart.length > 0) {
          this.loader = false;
          if(this.isBack == true){
            this.putAwayPart.forEach((element) => {
            });
          }
          else{
            this.putAwayPart.forEach((element) => {
              this.addLocation(element);
            });
          }
        }
      },
      error: (error: any) => {
        this.loader = false;
        this.popupModal(this.constants.APIError, error);
      },
      complete: () => {
      }
    });
  }

  NextItem(i: number, Id: number) {
    if (this.putAwayPart.length > i && i > 0) {
      this.IsNext = i;
    }
  }

  PreviousItem(i: number, Id: number) {
    if (i > 0) {
      this.IsNext = i - 1;
    }
  }

  createPutAway() {
    this.IsPickList = true;
    this.putAwayArrayList = [];
    this.locationForm.value.DLocation.forEach(p => {
      let p1: PutAway = new PutAway();
      p1.Id = p.id;
      p1.partId = p.partId;
      p1.sku = p.sku;
      p1.aisle = p.aisle;
      p1.shelf = p.shelf;
      p1.section = p.section;
      p1.bin = p.bin;
      p1.wareHouse = p.wareHouse;
      p1.transId = p.transID;
      p1.address = p.aisle + '-' + p.section + '-' + p.shelf + '-' + p.bin;
      p1.locName = p.locName;
      p1.onhand = p.onhand;
      p1.moveQty = p.moveQty;
      p1.totalQty = p.moveQty;
      p1.putAwayQty = 0;
      p1.imagePath = p.image;
      p1.trackingId = p.tracking;
      p1.invType = p.invType;
      p1.description = p.description;
      p1.locCapacity = p.locCapacity;
      this.putAwayArrayList.push(p1);
      p.AdditionalLocation.forEach(c => {
        if (c.moveQty > 0) {
          let p2: PutAway = new PutAway();
          p2.Id = p.id;
          p2.partId = p.partId;
          p2.sku = p.sku;
          p2.aisle = c.aisle;
          p2.section = c.section;
          p2.shelf = c.shelf;
          p2.bin = c.bin;
          p2.wareHouse = c.wareHouse;
          p2.transId = p.transID;
          p2.address = c.aisle + '-' + c.section + '-' + c.shelf + '-' + c.bin;
          p2.locName = c.locName;
          p2.onhand = c.onhand;
          p2.sku = p.sku;
          p2.moveQty = c.moveQty;
          p2.totalQty = c.moveQty;
          p2.putAwayQty = 0;
          p2.imagePath = p.image;
          p2.trackingId = p.tracking;
          p2.invType = p.invType;
          p2.description = p.description;
          p2.locCapacity = c.locCapacity;
          this.putAwayArrayList.push(p2);
        }
      });
    });
  }

  TotalMoveQty(partId: number): number {
    let qty = 0;
    if (partId != undefined && partId > 0) {
      this.locationForm.value.DLocation.filter(x => x.partId == partId).forEach(p => {
        qty = p.moveQty;
      });
    }
    return qty;
  }

  GetProgressList() {
    this.isProgressDetail = true;
    this.showCongrats = false;
  }

  GetPutList() {
    this.isProgressDetail = false;
    if (this.IsCongratulationPage() == 0) {
      this.closeAllDialogs();
      this.showCongrats = true;
    } else {
      this.showCongrats = false;
    }
  }

  RemoveDecimal(str, val) {
    if (!isNaN(str)) {
      str = String(str)
      if (str.indexOf(".") === -1) {
        str = String(str)
      }
      else {
        str = str.slice(0, (str.indexOf(".")) + val + 1);
      }
    } else {
      str = '';
    }
    return Number(str);
  }

  confirmPutAway() {
    if (this.confirmForm.value.moveQty != 0 && this.confirmForm.value.moveQty > 0) {
      if (this.putAwayArrayList[this.LocIndex].trackingId != 3) {
        this.GetSerialLotFlyout(this.putAwayArrayList[this.LocIndex]);
        this.movedValue = this.confirmForm.value.moveQty;
        this.closeAllDialogs();
        this.isSerialFlyout = true;
      } else {
        this.isSerialFlyout = false;
        this.GetSerialLotFlyout(this.putAwayArrayList[this.LocIndex]);
      }
    } else {
      this.isSerialFlyout = false;
    }
  }

  GetSerialLotFlyout(obj: any) {
    this.seriallotflyoutObj = {
      page: 'putaway',
      companyId: this.companyId,
      partId: obj.partId,
      SKU: obj.sku,
      infoItemDescription: obj.description,
      inventoryId: 0,
      trackingId: obj.trackingId,
      balance: this.confirmForm.value.moveQty,
      trackingObj: [],
    }
    if (obj.trackingId == 3 || Number(obj.moveQty) <= 0) {
      this.isSerialFlyout = false;
      this.PutAwayObj = this.putAwayArrayList[this.LocIndex];
      this.serailObj = {
        Id: this.PutAwayObj.Id,
        partId: this.PutAwayObj.partId,
        sku: this.PutAwayObj.sku,
        aisle: this.PutAwayObj.aisle,
        shelf: this.PutAwayObj.shelf,
        section: this.PutAwayObj.section,
        bin: this.PutAwayObj.bin,
        wareHouse: this.PutAwayObj.wareHouse,
        transId: this.PutAwayObj.transId,
        address: this.PutAwayObj.address,
        locName: this.PutAwayObj.locName,
        onhand: this.PutAwayObj.onhand,
        moveQty: Number(this.confirmForm.value.moveQty),
        tracking: [],
      }
      this.getCommonCongratsModal(this.serailObj);
    } else {
      this.isSerialFlyout = true;
    }
  }

  closeSerialLotFlyout(object) {
    this.isSerialFlyout = object.isShow;
    this.TrackingObj = object.trackingObj;
    if (object.trackingObj != null && object.trackingObj.length > 0) {
      this.PutAwayObj = this.putAwayArrayList[this.LocIndex];
      this.serailObj = {
        Id: this.PutAwayObj.Id,
        partId: this.PutAwayObj.partId,
        sku: this.PutAwayObj.sku,
        aisle: this.PutAwayObj.aisle,
        shelf: this.PutAwayObj.shelf,
        section: this.PutAwayObj.section,
        bin: this.PutAwayObj.bin,
        wareHouse: this.PutAwayObj.wareHouse,
        transId: this.PutAwayObj.transId,
        address: this.PutAwayObj.address,
        locName: this.PutAwayObj.locName,
        onhand: this.PutAwayObj.onhand,
        moveQty: Number(this.movedValue),
        tracking: object.trackingObj,
      }
    } else {
      this.PutAwayObj = [];
    }
    this.getCommonCongratsModal(this.serailObj);
  }

  getCommonCongratsModal(serailObj: any) {
    this.loader = true;
    this.paService.GetPutAwayPartDetail(serailObj).subscribe((data: any) => {
      this.loader = false;
      if (data.data != null) {
        if (serailObj.tracking.length > 0) {
          let moveQty = this.movedValue == null ? 0 : this.movedValue;
          this.putAwayArrayList[this.LocIndex].moveQty = this.putAwayArrayList[this.LocIndex].moveQty - moveQty;
          this.putAwayArrayList[this.LocIndex].putAwayQty = this.putAwayArrayList[this.LocIndex].totalQty - this.putAwayArrayList[this.LocIndex].moveQty;
          this.movedValue = 0;
          this.locationForm.patchValue({ DLocation: this.locationForm.value.DLocation });
          this.closeAllDialogs();
          this.confirmForm.controls['moveQty'].setValue(0);
        } else {
          let moveQty = this.confirmForm.value.moveQty == null ? 0 : this.confirmForm.value.moveQty;
          this.putAwayArrayList[this.LocIndex].moveQty = this.putAwayArrayList[this.LocIndex].moveQty - moveQty;
          this.putAwayArrayList[this.LocIndex].putAwayQty = this.putAwayArrayList[this.LocIndex].totalQty - this.putAwayArrayList[this.LocIndex].moveQty;
          this.confirmForm.value.moveQty = 0;
          this.locationForm.patchValue({ DLocation: this.locationForm.value.DLocation });
          this.closeAllDialogs();
          this.confirmForm.controls['moveQty'].setValue(0);
        }
      }
      if (this.IsCongratulationPage() == 0) {
        this.closeAllDialogs();
        this.showCongrats = true;
        this.sumOfMoveQty = this.putAwayArrayList.reduce((acc, current) => acc + current.putAwayQty, 0);
      }
    },
      (error: any) => {
        this.loader = false;
        this.popupModal(this.constants.APIError, this.constants.SomethingWentWrong);
      }
    )
  }

  IsCongratulationPage(): number {
    let len = 0;
    if (this.putAwayArrayList.length > 0) {
      len = this.putAwayArrayList.filter(x => x.moveQty > 0).length
    }
    return len;
  }

  getLocationName(companyId: number) {
    this.sharedService.getLocationLevel(companyId, '').subscribe({
      next: (data: any) => {
        this.loader = false;
        this.RecLocationList = data.dataList;
        this.GetPutAwayList('');
      },
      error: (error: any) => {
        this.loader = false;
        this.popupModal(this.constants.APIError, error);
      },
      complete: () => {
      }
    });
  }

  sliderChange(value: any, i: number) {
    this.locationForm.value.DLocation[i].moveQty = value;
    this.locationForm.get('DLocation')['controls'][i].patchValue(this.locationForm.value.DLocation[i])
  }

  sliderChangeAddLocation(value: any, i: number, j: number) {
    this.locationForm.value.DLocation[i].AdditionalLocation[j].moveQty = value;
    this.locationForm.get('DLocation')['controls'][i].patchValue(this.locationForm.value.DLocation[i])
  }

  slider(value: any) {
    this.confirmForm.value.moveQty = value;
    this.confirmForm.patchValue({ moveQty: value });
  }

  GetLocationValue(id): String {
    if (this.RecLocationList.length > 0) {
      const locationList = this.RecLocationList.filter(x => x.id == Number(id));
      if (locationList.length > 0) {
        return locationList[0].locationName;
      }
    }
    return 'N/A';
  }

  getWareHouseWithChild(companyId: Number) {
    this.companyService.GetWareHouseWithChild(companyId).subscribe(
      (data: any) => {
        if (data.data != null) {
          this.CompanyWareHouseList = data.data;
        }
      }
    )
  }

  GetWareHouseValue(id): String {
    if (this.CompanyWareHouseList.length > 0) {
      const wareHouseList = this.CompanyWareHouseList.filter(x => x.id == Number(id));
      if (wareHouseList.length > 0) {
        return wareHouseList[0].name;
      }
    }
    return '';
  }

  getStatusList() {
    this.POService.GetPOStatus().subscribe(
      (data: any) => {
        this.statuslist = data.filter(x => x.isPurchaseOrder == true).sort((a, b) => a.poOrdering - b.poOrdering);
      }
    )
  }

  GetStatusName(id: any) {
    if (this.statuslist.length > 0 && id > 0) {
      const list = this.statuslist.filter(x => x.statusId == Number(id));
      if (list.length > 0) {
        return list[0].statusName;
      }
    }
    return '';
  }

  selectAllData(event: any) {
    if (this.selectAll == false) {
      this.selectedId = this.putAwayList.map(x => x.id)
      this.putAwayDetail(event);
    } else {
      this.selected = false;
      this.selectedId = [];
      this.partIds = [];
    }
    this.selectAll = !this.selectAll;
  }

  putAwayDetail(event: any) {
    if (this.selectedId.includes(event.id)) {
      const index = this.selectedId.indexOf(event.id);
      const partIds = this.partIds.indexOf(event.partId);
      if (index > -1) {
        this.selectedId.splice(index, 1);
        this.partIds.splice(partIds, 1);
      }
    } else {
      this.selectedId.push(event.id);
      this.partIds.push(event.partId);
    }
  }

  showSelected(event: any) {
    if (event === true) {
      this.selected = true;
      this.putAwayList = this.putAwayList.filter(x => this.selectedId.includes(x.id));
    } else {
      event = false;
      this.selected = false;
      this.GetPutAwayList('');
      this.selectAll = false;
      this.selectedId = [];
      this.partIds = [];
    }
  }

  isputAwayList() {
    this.isputAway = true;
    this.IsPickList = false;
    this.selectedId = [];
    this.partIds = [];
    this.locationForm.reset();
    this.currentRoute = this.router.url;
    if (this.currentRoute.includes('putAway')) {
      this.router.navigate(['/putaway'])
    } else {
      this.router.navigate(['/putAway'])
    }
    this.showPutAway = false;
    this.GetPutAwayList('');
  }

  isputAwayListFromProgress() {
    this.IsPickList = false;
    this.isBack = true;
    this.selectPutAway();
  }

  //Default Location Handle for parts
  locNameChange(value, i: number) {
    this.locationForm.value.DLocation[i].locName = value;
    this.locationForm.get('DLocation')['controls'][i].patchValue(this.locationForm.value.DLocation[i])
    this.putAwayPart[this.IsNext].location = value;
    this.getLocationLevels(value); // 1 is for inventoy type location
  }

  //Child Location Handle for parts
  ClocNameChange(value, i: number, j: number) {
    this.selectedLoactionId = value;
    this.CgetLocationLevels(value, i, j); // 1 is for inventoy type location
  }

  CgetLocationLevels(locationId: number, i: number, j: number) {
    this.loader = true;
    this.inventoryService.GetLocationLevelsPositions(this.companyId, locationId)
      .subscribe({
        next: (data: any) => {
          this.loader = false;
          if (data.dataList.length > 0) {
            const Asile = data.dataList.map(function (item) { return item['aileName']; });
            const Section = data.dataList.map(function (item) { return item['sectionName']; });
            const Shelf = data.dataList.map(function (item) { return item['shelfName']; });
            const Bin = data.dataList.map(function (item) { return item['positionName']; });
            this.locationForm.value.DLocation[i].AdditionalLocation[j].level1List = [];
            this.locationForm.value.DLocation[i].AdditionalLocation[j].level2List = [];
            this.locationForm.value.DLocation[i].AdditionalLocation[j].level3List = [];
            this.locationForm.value.DLocation[i].AdditionalLocation[j].level4List = [];
            this.locationForm.value.DLocation[i].AdditionalLocation[j].level1List.push(...(Asile.filter((value, index, array) => array.indexOf(value) === index)));
            this.locationForm.value.DLocation[i].AdditionalLocation[j].level2List.push(...(Section.filter((value, index, array) => array.indexOf(value) === index)));
            this.locationForm.value.DLocation[i].AdditionalLocation[j].level3List.push(...(Shelf.filter((value, index, array) => array.indexOf(value) === index)));
            this.locationForm.value.DLocation[i].AdditionalLocation[j].level4List.push(...(Bin.filter((value, index, array) => array.indexOf(value) === index)));
            this.locationForm.get('DLocation')['controls'][i].patchValue(this.locationForm.value.DLocation[i])
          }
        },
        error: (error: any) => {
          this.loader = false;
          this.popupModal(this.constants.APIError, error);
        },
        complete: () => {
        }
      });
  }

  onParentloclevelChange(level: Number, value: any) {
    switch (level) {
      case 1:
        this.aisle = value;
        this.locationForm.patchValue({ aisle: value });
        break;
      case 2:
        this.section = value;
        this.locationForm.patchValue({ section: value });
        break;
      case 3:
        this.shelf = value;
        this.locationForm.patchValue({ shelf: value });
        break;
      case 4:
        this.bin = value;
        this.locationForm.patchValue({ bin: value });
        break;
    }
  }

  onloclevelChange(level: Number, value: any, i: number, j: number) {
    switch (level) {
      case 1:
        this.aisle = value;
        this.locationForm.patchValue({ aisle: value });
        this.GetLocationCapacity(i, j);
        break;
      case 2:
        this.section = value;
        this.locationForm.patchValue({ section: value });
        this.GetLocationCapacity(i, j);
        break;
      case 3:
        this.shelf = value;
        this.locationForm.patchValue({ shelf: value });
        this.GetLocationCapacity(i, j);
        break;
      case 4:
        this.bin = value;
        this.locationForm.patchValue({ bin: value });
        this.GetLocationCapacity(i, j);
        break;
    }
  }

  onWareHouseChange(value, i: number) {
    this.selectedWareHouse = value;
    this.locationForm.value.DLocation[i].wareHouse = value;
    this.locationForm.patchValue({ DLocation: this.locationForm.value.DLocation[i] })
  }

  onChildWareHouseChange(value, i: number, j: number) {
    this.selectedWareHouse = value;
    this.locationForm.value.DLocation[i].AdditionalLocation[j].wareHouse = value;
    this.locationForm.get('DLocation')['controls'][i].patchValue(this.locationForm.value.DLocation[i])
  }

  //methods for serial & lot flyout

  PopupModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }


  //Method for Inventory loss section

  GetBatchList(partId: number) {
    this.inventoryService.GetBatchList(this.companyId, partId).subscribe({
      next: (data: any) => {
        this.BatchList = [];
        if (data.data.length > 0) {
          this.BatchList = data.data;
          this.HBatchList = data.data;
        }
      },
      error: (error: any) => {
        this.PopupModal(this.constants.APIError, error);
      }
    })
  }

  CloseTransFlyout(isShow) {
    this.showNewTransaction = isShow;
    this.GetBatchList(Number(this.partId));
  }

  saveLocationCapacity() {
    this.AddInventoryLocation();
  }

  AddInventoryLocation() {
    let obj = {
      id: this.locationCapacity.Id,
      partId: this.locationCapacity.partId,
      wareHouseId: this.locationCapacity.wareHouse,
      locationId: this.locationCapacity.locName,
      level1: this.locationCapacity.aisle,
      level2: this.locationCapacity.section,
      level3: this.locationCapacity.shelf,
      level4: this.locationCapacity.bin,
      isActive: true,
      locationCapacity: Number(this.locationCapacityValue)
    }
    this.inventoryService.AddInventoryLocation(obj, false).subscribe((data: any) => {
      this.dialog.closeAll();
    })
  }

  OpenTransFlyout(value: number) {
    this.paService.GetDefaultPartData(this.partId).subscribe({
      next: (data: any) => {
        this.selectedPart = data.data;
      },
      error: (error: any) => {
        this.PopupModal(this.constants.APIError, error);
      },
      complete: () => {
        if (this.selectedPart != null && this.selectedPart != undefined) {
          this.showNewTransaction = true;
          let defaultLocation = {
            partId: this.selectedPart.partId,
            wareHouseId: this.selectedPart.wareHouse,
            wareHouseName: this.GetWareHouseValue(this.selectedPart.wareHouse),
            locationId: this.selectedPart.locName,
            locationName: this.GetLocationValue(this.selectedPart.locName),
            level1: this.selectedPart.aisle,
            level2: this.selectedPart.section,
            level3: this.selectedPart.shelf,
            level4: this.selectedPart.bin,
            locationCapacity: this.selectedPart.locCapacity
          }
          if (this.companyId > 0 && Number(this.partId) > 0) {
            this.flyoutObject = {
              companyId: this.companyId,
              partId: this.partId,
              SKU: this.sku,
              infoTracking: this.tracking,
              transactionTypeVal: value,
              defaulLocationValues: defaultLocation,
            }
          }
        }
      }
    });
  }

  GetLocationCapacity(i: number, j: number) {
    let data = {
      partId: this.selectedpartId,
      locationId: this.selectedLoactionId,
      wareHouseId: this.selectedWareHouse,
      level1: this.aisle,
      level2: this.section,
      level3: this.shelf,
      level4: this.bin
    }
    this.paService.GetLocationCapacityByLocId(data).subscribe((data: any) => {
      if (data.data != null) {
        this.locationForm.value.DLocation[i].AdditionalLocation[j].locCapacity = data.data.locationCapacity;
        this.locationForm.get('DLocation')['controls'][i].patchValue(this.locationForm.value.DLocation[i])
      } else {
        this.locationForm.value.DLocation[i].AdditionalLocation[j].locCapacity = 0;
        this.locationForm.get('DLocation')['controls'][i].patchValue(this.locationForm.value.DLocation[i])
      }
    })
  }

  GetParentLocationCapacity(i: number) {
    let data = {
      partId: this.selectedpartId,
      locationId: this.selectedLoactionId,
      wareHouseId: this.selectedWareHouse,
      level1: this.aisle,
      level2: this.section,
      level3: this.shelf,
      level4: this.bin
    }
    this.paService.GetLocationCapacityByLocId(data).subscribe((data: any) => {
      this.locationForm.value.DLocation[i].locCapacity = data.data.locationCapacity;
      this.locationForm.get('DLocation')['controls'][i].patchValue(this.locationForm.value.DLocation[i])
    })
  }

  PickListImagesModalClose() {
    this.ImagesModal = false;
    this.pickListImages = [];
  }
  PickListImagesModal(partId: number) {
    this.loader = true;
    this.pickListImages = [];
    this.pickListService.GetPartImages(partId)
      .subscribe({
        next: (data: any) => {
          this.loader = false;
          if (data.dataList.length > 0) {
            data.dataList.forEach((element, index) => {
              this.pickListImages.push({
                image: element.imagePath,
                thumbImage: element.imagePath
              });
            });
            this.ImagesModal = true;
          }
        },
        error: (err) => {
          this.loader = false;
        },
      });
  }
}
