import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.css']
})
export class SearchFilterComponent implements OnInit {

  Search: FormGroup;
  minToDate: any;
  maxToDate = new Date();
  minFromDate: any;
  maxFromDate = new Date();
  @Input() Data: any;
  @Output() searchEvent = new EventEmitter<any>();
  constructor() {

  }
  ngOnInit(): void {
    this.createSerchForm();
    this.BindData();
  }
  createSerchForm() {
    this.Search = new FormGroup({
      "filterTypeId": new FormControl(1),
      "statusFilterId": new FormControl(),
      "timeperiodId": new FormControl(1),
      "fromDate": new FormControl(new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000)),
      "toDate": new FormControl(new Date())
    });
  }
  BindData() {
    this.Search.patchValue({
      statusFilterId: this.Data.StatusFilterId
    })
  }
  changeStatusFilter(value: number) {

  }
  Apply() {

  }
  SearchCategory(value: any) {

  }
}
