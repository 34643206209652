<section class="content-section-1">
    <div class="loading lodingtext" *ngIf="loader"></div>
    <div class="row page_breadcrumb">
        <div class="col-12">
            <div class="top-bredcumps">
                <div class="cstmbreadcum paddingmng-right">
                    <div class="page_title_cstms">
                        <ul class="link_current">
                            <li>
                                <a type="button" (click)="backToDetails()" class="backbtns">
                                    <mat-icon>keyboard_arrow_left</mat-icon>
                                    <span>Back to Receiving Details</span>
                                </a>
                            </li>
                            <li>
                                <span>Receiving Summary</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            

            <div class="main-section">
                <div id="congrats-card">
                    <div class="congrats-title mb-3">
                        <img src="/assets/image/congrats.png" alt="congrats" />
                        <span>Congratulations!</span>
                    </div>
                    <div class="congrats-description mb-3">
                        <p>
                            You have completed receiving Purchase Order
                            {{ masterForm.value.poNumber }}
                        </p>
                        <p>
                            Please attach an image of the Packing Slip <br />
                            and verify the quantities below match what has been received.
                        </p>
                    </div>
                    <div class="row justify-content-center">
                        <app-web-cam *ngIf="openCamera" (getPicture)="handleImage($event)"></app-web-cam>
                    </div>
                    <div class="congrats-upload mb-2">
                        
                        <span><input type="file" (change)="onFileChange($event)"
                                placeholder="Add Packing Slip Photo Here" /></span>
                        
                        <a type="button" (click)="OpenCamera()"><img src="/assets/image/camera.png" alt="camera" /></a>
                    </div>
                    
                    <div>
                        <div class="row justify-content-center mb-2" *ngFor="let img of filesGet.value; let i = index">
                            <ng-container *ngIf="img.name.length > 0 && img.docType == 'receive' && img.id === 0">
                                <div class="col-6 text-left">
                                    <a class="mr-2" type="button" (click)="viewImage(img)">
                                        <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                                    </a>
                                    <span class="ml-2">{{ img.name }}</span>
                                </div>
                                <div class="col-2 text-left" (click)="deleteFile(i)">
                                    <mat-icon style="float: right">cancel</mat-icon>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="congrats-table mb-3">
                        <div class="table-div">
                            <table>
                                <thead>
                                    <tr>
                                        <th class="w-25">
                                            Company <br />
                                            Part Number
                                        </th>
                                        <th class="w-50">Description</th>
                                        <th class="w-25">Received</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor=" let item of masterForm.get('summaryItems').value">
                                    <!-- <tr *ngFor=" let item of savedObj.data.summaryItems"> -->
                                        <ng-container *ngIf="item.receiving > 0">
                                            <td>{{ item.partNo }}</td>
                                            <td>{{ item.vendorPartName }}</td>
                                            <td>{{ item.receiving }}</td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="congrats-button mb-3">
                        <p>
                            Click a button below to confirm the information is correct.
                        </p>
                        <div>
                            <button class="putaway-btn" (click)="onReceiveShipment('putaway')">
                                Proceed to Putaway
                            </button>
                        </div>
                        <div>
                            <button class="list-btn" (click)="onReceiveShipment('receiving')">
                                Back to the Receiving List
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>