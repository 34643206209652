import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConstantsService } from 'src/app/constants.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit{
  @Input() previousPage: string = '';
  @Input() currentPage: string = '';
  @Input() isCancel: boolean = false;
  @Input() isSave: boolean = false;
  @Input() isShare: boolean = false;
  @Output() backbtnClick = new EventEmitter<any>();
  @Output() cancelbtnClick = new EventEmitter<any>();
  @Output() savebtnClick = new EventEmitter<any>();
  @Output() sharebtnClick = new EventEmitter<any>();
  Action: { id: number; name: string; }[];

  constructor(
    private constants: ConstantsService,
  ){
    this.Action = this.constants.actionList;
  }
  back() {
    this.backbtnClick.emit();
  }
  cancel() {
    this.cancelbtnClick.emit();
  }
  save() {
    this.savebtnClick.emit();
  }

  GetActionName(id: any) {
    this.sharebtnClick.emit(id);
  }

  ngOnInit(): void {
    
  }
}
