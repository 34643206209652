<section class="content-section pt-2">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>
    <div class="row">
        <div class="col-6 mb-2 d-flex align-items-baseline text-xs-left">
            <div class="page-title">
                <div class="bread-crumb">
                    <a [routerLink]="['/adminDashboard/']">Home</a>
                    <span class="mx-1">-</span>
                    <span class="active">Template</span>
                    <div *ngIf="permissionMessage!=''" class='btn-danger text-center'>{{permissionMessage}} </div>
                </div>
            </div>
        </div>
    </div>
    <div class="white-box1">
        <div class="card-desc1">
            <form [formGroup]="formSearch">
                <div class="row detailSection">
                    <div class="col-md-12 container">
                        <div class="row mt-3">
                            <div class="col-lg-2 col-md-6">
                                <div class="d-flex align-items-center">
                                    <label class="customLabel mr-1" for="Type">Type</label>
                                    <select class="customInput" (change)="ChangeTCreateUpdate($event)"
                                        [(ngModel)]="TCreateUpdate" id="TCreateUpdate" formControlName="TCreateUpdate">
                                        <option value="1">Create</option>
                                        <option value="0">Update</option>
                                    </select>
                                </div>
                            </div>
                            <!-- <div class="col-lg-3 col-md-6 mt-md-0 mt-1"
                                [style.display]="SearchControl==true ? 'block' : 'none'">
                                <div class="d-flex align-items-center required borderNone"
                                    [ngClass]="((value_search==0) && (SearchValidation)) ? 'validation_border': ''">
                                    <label class="customLabel mr-1" for="Type">Company</label>
                                    <ngx-dropdown-treeview-select [config]="config_search"
                                        class="dropdown-company customInput-dropdown" [items]="userGroupObjects_search"
                                        [(value)]="value_search" (valueChange)="onValueChange_search($event)">
                                    </ngx-dropdown-treeview-select>
                                </div>
                            </div> -->
                            <div class="col-lg-5 col-md-12 mt-lg-0 mt-1"
                                [style.display]="SearchControl==true ? 'block' : 'none'">
                                <div class="d-flex align-items-center">
                                    <label class="customLabel mr-1 ws-nobreak" for="Vendor Name">Widget name</label>
                                    <input type="text" placeholder="Search Template" Class="customInput"
                                        [(ngModel)]="TemplateSearch" formControlName="TemplateSearch" autocomplete="off"
                                        required>
                                    <i class="fas fa-search cpointer ml-3 mr-4 cpointer" (click)="searchTemplate()"></i>
                                    <button class="btn blue-bg round-btn m-0" (click)="AllTemaplteItem()">All
                                        Template</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="white-box1" *ngIf="rolePermissionsList && rolePermissionsList[0].isView"
        [style.display]="ListShow==true ? 'block' : 'none'">
        <div class="white-box-title1">
            <div class="customHeading mt-3 mb-2">
                Template List

                <i class="fa fa-angle-{{ListAngle}} cpointer ml-2" aria-hidden="true" (click)="ListDivShowHide()"></i>
            </div>
        </div>
        <div class="card-desc1 table-responsive" [style.display]="ListDiv==true ? 'block' : 'none'">
            <table id="datatable" class="display table clearfix" datatable [dtOptions]="dtOptions"
                [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th style="display:none">Id</th>
                        <th>Company Name</th>
                        <th>Template Name</th>
                        <th>Created Date</th>
                        <th>Status</th>
                        <th data-orderable="false">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of alltemplatItem">
                        <td style="display:none">{{data.templateid}}</td>
                        <td>{{data.companyName}}</td>
                        <td>{{data.templateName}}</td>
                        <td>{{data.createdDate|date: "MMM-dd-yyyy"}}</td>
                        <td>{{data.status==true?"Active":''}}</td>
                        <td>
                            <a *ngIf="rolePermissionsList && rolePermissionsList[0].isUpdate"
                                (click)="templateEdit(data)" class="green-text"><i class="fas fa-edit"></i></a>
                            <a *ngIf="rolePermissionsList && rolePermissionsList[0].isDelete"
                                (click)="deletePopUp(data,content)" class="red-text"><i
                                    class="fas fa-trash-alt"></i></a>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>


    </div>

    <div class="white-box1">
        <div class="white-box-title1 mb-0">
            <div class="customHeading mt-3">
                Template
            </div>
        </div>
        <div class="card-desc1">
            <form [formGroup]="form">
                <div class="row mt-2">
                    <!-- <div class="col-lg-4 col-md-12">
                        <div class="row required">
                            <div class="col-lg-4 col-md-2 trp">
                                <label class="customLabel">Company</label>
                            </div>
                            <div class="col-lg-8 col-md-10 borderNone"
                                [ngClass]="((value==0) && (validation)) ? 'validation_border': ''">
                                <ngx-dropdown-treeview-select class="dropdown-company customInput-dropdown"
                                    [config]="config" required [items]="userGroupObjects" [(value)]="value"
                                    (valueChange)="onValueChange($event)">
                                </ngx-dropdown-treeview-select>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-lg-4 col-md-12">
                        <div class="row required">
                            <div class="col-lg-4 col-md-2 trp">
                                <label class="customLabel">Template</label>
                            </div>
                            <div class="col-lg-8 col-md-10 borderNone">
                                <input class="customInput" [(ngModel)]="templateName" formControlName="templateName"
                                    placeholder="Enter template name" autocomplete="off" minlength="500" required
                                    [ngClass]="((!templateName) && (validation)) ? 'validation_border': ''">
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="row mt-4" *ngIf="allWidgetItem!=''">
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
            <b style="font-size: 13px;">Choose Widget</b>
        </div>
        <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12">
            <b style="font-size: 13px;">Template Design</b>
        </div>
    </div>
    <div class="border mt-2 widget-area" *ngIf="allWidgetItem!=''">
        <div class="row">
            <div class="col-sm-3 pr-0">
                <ul class="company-widgets">
                    <ng-container *ngFor="let widgetCat of allWidgetWithType; let index=index">
                        <li>
                            <strong>{{widgetCat.widgetType}}</strong>
                        </li>
                        <li *ngFor="let widgetitem of widgetCat.widgetList">
                            &nbsp;&nbsp;&nbsp;<input type="checkbox" name="visitcheck"
                                (change)="isVisitCheck($event,widgetitem.id)" [(ngModel)]='widgetitem.isCheckItem'>
                            {{widgetitem.widgetname}}
                        </li>
                    </ng-container>
                </ul>
            </div>
            <div class="col-sm-9 pl-0">
                <div class="pt-2 pl-3 pr-3 pb-2 col-over">
                    <div class="row">
                        <div class="col-sm-9">
                            <div class="row mt-2">
                                <div class="col-sm-3" *ngFor="let temp of ProductionData">
                                    <a class="d-box-item">
                                        <div class="inner color-white">
                                            <i class="fa fa-area-chart" aria-hidden="true"></i>
                                            <h5 class="flip-box-header">{{temp.widgetname}}</h5>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-3" *ngFor="let alertitem of alert">
                            <h5 class="alert-notification"> <i class="fa fa-bell" aria-hidden="true"></i>
                                {{alertitem.widgetname}}
                            </h5>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-sm-9">
                            <div class="row mt-2">
                                <div class="col-sm-3" *ngFor="let traing of TraingDocument">
                                    <a class="d-box-item">
                                        <div class="inner color-white">
                                            <i class="fa fa-area-chart" aria-hidden="true"></i>
                                            <h5 class="flip-box-header">{{traing.widgetname}}</h5>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <!-- <h5 class="alert-notification"> <i class="fa fa-bell" aria-hidden="true"></i>
                                {{alertitem.widgetname}}
                            </h5> -->
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-9">
                            <div class="row mt-2">
                                <div class="col-sm-3" *ngFor="let new of new">
                                    <a class="d-box-item">
                                        <div class="inner color-white">
                                            <i class="fa fa-area-chart" aria-hidden="true"></i>
                                            <h5 class="flip-box-header">{{new.widgetname}}</h5>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-sm-3">
                        </div> -->
                    </div>

                    <div class="row mt-3">
                        <div class="col-sm-9">
                            <div class="row mt-2">
                                <div class="col-sm-3" *ngFor="let ved of vedio">
                                    <a class="d-box-item">
                                        <div class="inner color-white">
                                            <i class="fa fa-area-chart" aria-hidden="true"></i>
                                            <h5 class="flip-box-header">{{ved.widgetname}}</h5>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-sm-3">
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="submit-from text-right mt-1">
        <button *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert" class="btn blue-bg round-btn"
            (click)="AddTemplate()"><i class="fas fa-save" aria-hidden="true"></i> {{buttonValue}}
        </button>

        <button class="btn darkred-bg round-btn" (click)="exitTemplate()"><i class="fas fa" aria-hidden="true"></i>
            Exit </button>
    </div>
</section>
<div class="modal" id="add_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="addsuccess()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Added Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="addsuccess()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="add_update">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="updateitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Update Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="updateitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="delete_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="deleteitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Deleted Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="deleteitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="wrong_msg">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="msgitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Something went wrong</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="msgitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="blankTemplate">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="blankTemplateClose()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Please design the template first by choosing widget.</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="blankTemplateClose()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="templateexiste">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="templateexiste()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Template already exists</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="templateexiste()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<ng-template #content let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="modal-para m-1">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn round-btn green-bg m-1"
            (click)="deleteTemplate(deletedItem); c('Close click')">Yes</button>
        <button type="button" class="btn round-btn red-bg m-1" (click)="c('Close click')">No</button>
    </div>
</ng-template>

<div class="modal" id="Widget">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title darkred-bg sweet-heading"></h4>
            <button class="close btn alert_modal" aria-label="Close" (click)="Widgettype1Close()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p class="mb-0">{{widget1}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer">
                <button type="button" class="btn red-bg round-btn m-0" (click)="Widgettype1Close()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<!-- <div class="modal" id="Widget">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title darkred-bg sweet-heading"></h4>
            <button class="close btn alert_modal" aria-label="Close" (click)="Widgettype1Close()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p class="mb-0">{{widget1}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer">
                <button type="button" class="btn red-bg round-btn m-0" (click)="Widgettype1Close()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div> -->