export interface DefaultCompany {
    value: number;
    text: string;
    isActive?: boolean;
    isEcommerece?: boolean;
    wordpressId?: number;
    children?: DefaultCompany[];
}
// Company Start
export interface Company {
    id: number;
    companyName: string;
    parentCompanyName: string;
    addressLine1: string;
    addressLine2: string;
    parentId: string;
    itemNo: string;
    modifiedDate: Date;
    modifiedBy: string;
    isActive?: string;
    children?: Company[];
}
export interface CompanyTreeControl {
    expandable: boolean;
    id: number;
    companyName: string;
    parentCompanyName: string;
    addressLine1: string;
    addressLine2: string;
    parentId: string;
    itemNo: string;
    modifiedDate: Date;
    modifiedBy: string;
    isActive?: string;
    level: number;
}
//Company End
//Sales Order Start
export class DiscountVM {
    CustomerType: string = '';
    CustDiscountCost: number = 0;
}
//Sales Order End