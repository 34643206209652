import { Component, ElementRef, EventEmitter, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from '../services/shared.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import * as $ from 'jquery';
import { PartService } from '../services/part/part.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {

  isSideBarActive: boolean = false;
  isSideBarActiveLogOut: boolean = false;
  userName: string;
  userId: any;
  profileLogo: any = this.constants.profileLogo;
  data: any;
  companyName: any;
  companyId: any;
  @Output() menuState = new EventEmitter();
  results: any[] = [];
  keyword = 'purchaseOrderPrefix';
  errorMsg: string | undefined;
  isLoadingResult: boolean = false;
  http: any;
  dbTypeList: any;
  form: FormGroup;
  validation: boolean;
  dataAutoComp: any;
  label: any;
  database: any;
  UI: any;
  Compnay: any;
  mySubscription: any;
  loader: boolean = false;
  @ViewChild("myNameElem") myNameElem: {
    query(query: any);
    nativeElement: any;
  }
  quickorder: any;
  Curl: string;
  constructor(
    private _sanitizer: DomSanitizer,
    public sharedService: SharedService,
    private constants: ConstantsService,
    private router: Router,
    private location: Location,
    private partService: PartService
  ) {
    
  }
  opened: boolean;
  showMenu = false;


  toggleMenu() {
    this.showMenu = !this.showMenu;
    this.menuState.emit(this.showMenu);
  }

  ngOnInit(): void {
    this.sharedService.isChangeValue.subscribe(value => {
      if (value == true) {
        if(this.myNameElem!=undefined) {
          this.myNameElem.query = null;
        }
        this.form.patchValue({dbType: 1});
      }
    });
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.userName = localStorage.getItem('username');
      this.companyId = localStorage.getItem('companyId');
      this.userId = localStorage.getItem(this.constants.userId);

      this.sharedService.isChangeDbValue.subscribe(value => {
        this.GetDbTypeList(this.userId, this.companyId);
      });
      this.GetProfileDetail(this.userId);
      $(".link1").click(function (e) {
        e.stopPropagation();
        $(".drpdown1").toggleClass("show");
      });
      $("body").click(function () {
        $(".drpdown1").removeClass("show");
      });

      this.form = new FormGroup({
        'dbType': new FormControl(1)
      });
  
      this.GetDbTypeList(this.userId, this.companyId);
    }
  }

  togglesideBar() {
    this.isSideBarActive = !this.isSideBarActive;
  }
  togglesideBarLogOut() {
    this.isSideBarActiveLogOut = !this.isSideBarActiveLogOut;
  }

  tog() {
    $('body').toggleClass("menu_collapsed");
  }
  topNav() {
    $('.header-right').toggleClass("dblock");
  }
  mbview() {
    $("body").removeClass("menu_collapsed");

  }

  LogOut() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['']);
  }

  GetProfileDetail(userId) {
    this.sharedService.GetEditUserDetail(userId).subscribe(
      data => {
        this.data = data;
        if (this.data.data != null && this.data.data != "") {
          this.companyName = this.data.data.companyName == null ? 'SYSTEM ADMIN' : this.data.data.companyName;
          if (this.data.data != null && this.data.data.profileImage != null && this.data.data.profileImage != "") {
            this.profileLogo = this._sanitizer.bypassSecurityTrustUrl(this.data.data.profileImage);
          }
        }

      });
  }
  GetDbTypeList(userId, companyId) {
    this.sharedService.GetDbTypeList(userId, companyId).subscribe(
      data => {
        this.data = data;
        this.dbTypeList = data['dataList'];
      });
  }

  getServerResponse(event: any) {
    //const dbType = this.dbType;
    const dbType = this.form.value.dbType;
    if (dbType == 1) {
      this.isLoadingResult = true;
      if (event.indexOf(':') > -1) {
        var e = event;
        var spilt = e.split(":");
        var spilt1 = spilt[1].trim();
        var substr1 = " (Not Active)";
        if (spilt1.includes(substr1) == true) {
          spilt1 = spilt1.replace(substr1, "");
        }
        this.sharedService.GetSearchAllData(this.companyId, spilt1).subscribe(
          data => {
            if (data['dataList'] == undefined) {
              this.dataAutoComp = [];
              this.errorMsg = data['successMessage'];
            } else {
              // this.dataAutoComp = {"Search": data['dataList']}//data1['dataList'];
              this.dataAutoComp = data['dataList'];
              if (this.dataAutoComp.length == 0) {
                this.dataAutoComp.length = 0;
                this.dataAutoComp = [];
                this.dataAutoComp = null;
                this.errorMsg = data['errorMessage'];
              }
            }
            this.isLoadingResult = false;
          });
      }
      else {
        this.sharedService.GetSearchAllData(this.companyId, event).subscribe(
          data => {
            if (data['dataList'] == undefined) {
              this.dataAutoComp = [];
              this.errorMsg = data['successMessage'];
            } else {
              // this.dataAutoComp = {"Search": data['dataList']}//data1['dataList'];
              this.dataAutoComp = data['dataList'];
              if (this.dataAutoComp.length == 0) {
                this.dataAutoComp.length = 0;
                this.dataAutoComp = [];
                this.dataAutoComp = null;
                this.errorMsg = data['errorMessage'];
              }
            }
            this.isLoadingResult = false;
          });
      }

    }
    else {
      var splitted = dbType.split("-");
      this.label = splitted[1].replace(/[^a-zA-Z ]/g, "");
      this.database = splitted[0];
      this.UI = splitted[2];
      const companyd = Number(splitted[3]);
      this.isLoadingResult = true;
      if (event.indexOf(':') > -1) {
        var e = event;
        var spilt = e.split(":");
        var spilt1 = spilt[1].trim();
        this.sharedService.GetSearchData(this.database, spilt1, this.label, this.UI, companyd).subscribe(
          data => {

            if (data['dataList'] == undefined) {
              this.dataAutoComp = [];
              this.errorMsg = data['successMessage'];
            } else {
              // this.dataAutoComp = {"Search": data['dataList']}//data1['dataList'];
              this.dataAutoComp = data['dataList'];
              if (this.dataAutoComp.length == 0) {
                this.dataAutoComp.length = 0;
                this.dataAutoComp = [];
                this.dataAutoComp = null;
                this.errorMsg = data['errorMessage'];
              }
            }
            this.isLoadingResult = false;
          });
      }
      else {
        this.sharedService.GetSearchData(this.database, event, this.label, this.UI, companyd).subscribe(
          data => {

            if (data['dataList'] == undefined) {
              this.dataAutoComp = [];
              this.errorMsg = data['successMessage'];
            } else {
              // this.dataAutoComp = {"Search": data['dataList']}//data1['dataList'];
              this.dataAutoComp = data['dataList'];
              if (this.dataAutoComp.length == 0) {
                this.dataAutoComp.length = 0;
                this.dataAutoComp = [];
                this.dataAutoComp = null;
                this.errorMsg = data['errorMessage'];
              }
            }
            this.isLoadingResult = false;
          });
      }
    }
  }
  searchCleared() {
    if (this.dataAutoComp != undefined) {
      this.dataAutoComp.length = 0;
    }
    this.dataAutoComp = [1, 2];
    this.errorMsg = "";
  }

  selectEvent(item: any) {
    const ui = item.defaultUI;
    const id = item.id;
    this.router.navigate(['/' + item.defaultUI + '/' + id], { skipLocationChange: true });
    if (ui == 'purchasorder') {
      this.sharedService.purchaseLoadedComponent.next(id);
    }
    else if (ui == 'parts') {
      this.partService.GetPartById(item.id).subscribe(
        (data: any) => {
          if (data.data.isCustompart == true) {
            this.router.navigate(['/customPart/' + id], { skipLocationChange: true });
            this.sharedService.custompartLoadedComponent.next(id);

          }
          else {
            this.sharedService.IndividualpartLoadedComponent.next(id);

          }
        });

    }
    else if (ui == 'vendors') {
      this.sharedService.VendorLoadedComponent.next(id);
    }
    else if (ui == 'customers') {
      this.sharedService.CustomerLoadedComponent.next(id);
    }
    else {

    }

  }
  onFocused(e: any) {
    // const dbType = this.dbType;
    const dbType = this.form.value.dbType;
    if (e.target.value == "") {
      this.searchCleared();
    }
    else {
      if (e.target.value.indexOf(':') > -1) {
        var e = e.target.value;
        var spilt = e.split(":");
        var spilt1 = spilt[1].trim();
        var substr1 = " (Not Active)";
        if (spilt1.includes(substr1) == true) {
          spilt1 = spilt1.replace(substr1, "");
        }
        if (dbType === 1 || dbType === "1"
        ) {
          this.isLoadingResult = true;
          this.sharedService.GetSearchAllData(this.companyId, spilt1).subscribe(
            data => {
              if (data['dataList'] == undefined) {
                this.dataAutoComp = [];
                this.errorMsg = data['successMessage'];
              } else {
                // this.dataAutoComp = {"Search": data['dataList']}//data1['dataList'];
                this.dataAutoComp = data['dataList'];
                if (this.dataAutoComp.length == 0) {
                  this.dataAutoComp.length = 0;
                  this.errorMsg = data['errorMessage'];
                }
              }
              this.isLoadingResult = false;
            });
        }
        else {
          var splitted = dbType.split("-");
          this.label = splitted[1].replace(/[^a-zA-Z ]/g, "");
          this.database = splitted[0];
          this.UI = splitted[2];
          const companyd = Number(splitted[3]);
          this.isLoadingResult = true;
          this.sharedService.GetSearchData(this.database, spilt1, this.label, this.UI, companyd).subscribe(
            data => {

              if (data['dataList'] == undefined) {
                this.dataAutoComp = [];
                this.errorMsg = data['successMessage'];
              } else {
                // this.dataAutoComp = {"Search": data['dataList']}//data1['dataList'];
                this.dataAutoComp = data['dataList'];
                if (this.dataAutoComp.length == 0) {
                  this.dataAutoComp.length = 0;

                  this.errorMsg = data['errorMessage'];
                }
              }
              this.isLoadingResult = false;
            });
        }

      }
      else {
        this.getServerResponse(e.target.value);
      }
    }
  }

  changevalue(e) {
    this.myNameElem.query = null;
  }

  doEvent() {
    this.quickorder = true;
    sessionStorage.setItem('QcompId', this.companyId);
    localStorage.setItem('IsSalesOrderList', 'true');
    location.pathname == '/SO' ? location.reload() : this.router.navigate(['/SO']);
  }

}
