import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConstantsService } from 'src/app/constants.service';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { PickListService } from 'src/app/services/pickList/pick-list.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pick-lists',
  templateUrl: './pick-lists.component.html',
  styleUrls: ['./pick-lists.component.css'],
})
export class PickListsComponent implements OnInit {
  loader: boolean = false;
  _mode: boolean = false;
  OrderPick: boolean = false;
  IsPickList: boolean = false;
  IsProgressDetails: boolean = false;
  IsScan: boolean = false;
  IsScanGun: boolean = false;
  IsToteScan: boolean = false;
  BatchType: string = '';
  counter: number = 0;
  qrResultString: string = '';
  targetVal: string = 'Ready to Ship';
  ScanIndex: number = -1;
  PickListForm: FormGroup;
  pickListDetails: any = [];
  hideShow: boolean = false;
  selectedCustomerSearch = 'All';
  allPickList: any = [];
  allPickListDS: any = new MatTableDataSource(this.allPickList);
  pickListByOrderId: any = [];
  rolePermissionsList: any;
  permissionMessage: string;
  CompanyId: any;
  selectedStatusSearch = '0';
  SearchPOValidation: boolean = false;
  SoSearchTxt: any = '';
  radioTarget: any = '';
  CountItemsAndOrdersForPickList: any = null;
  _pickListIndex: number = 0;
  OrderId: number = 0;
  selection = new SelectionModel<any>(true, []);
  displayedColumn: string[] = ['menu', 'item', 'orderDate', 'orderNumber', 'customerName', 'shippingMethod', 'quantity', 'status', 'bulkAction', 'CreatePickingTicket'];
  pickListImages: any = [];
  selectedCompanyText: string = 'All';
  SearchList = ['Order Date', 'Order Number', 'Customer Name', 'Shipping Method', 'Quantity', 'Status'];
  ConstImgURL: string = '';
  barScanValue: string = '';
  TempbarScanValue: string = '';
  pageName: number = 0;
  ScanPartNO: any;
  ToteId: any;
  OrderPartIds: any = [];
  isProcessingList: boolean = false;
  userId: string = '';
  public devices: MediaDeviceInfo[] = [];
  public selectedDevice: MediaDeviceInfo | null = null;
  showCongrats: boolean = false;

  constructor(
    public pickListService: PickListService,
    private globalChangeService: GlobalChangeService,
    public datepipe: DatePipe,
    private router: Router,
    private constants: ConstantsService,
  ) {
    this.ConstImgURL = this.constants.PickListImagURL;
  }

  ngOnInit(): void {
    this.userId = localStorage.getItem('userId');
    this.CompanyId = localStorage.getItem('GlobalSelectedCompanyId');
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
      this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 1017);
      this.permissionMessage = '';
      if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
        if (!this.rolePermissionsList[0].isView) {
          this.permissionMessage += 'view';
        } if (!this.rolePermissionsList[0].isInsert) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'add';
        } if (!this.rolePermissionsList[0].isUpdate) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'edit';
        } if (!this.rolePermissionsList[0].isDelete) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'delete';
        }
      }
      if (this.permissionMessage != '') {
        this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
      }
      //on change function 
      this.globalChangeService.dropdownChange$.subscribe((data) => {
        location.reload();
      })
      this.getMediaDevices();
      this.GetEqualShippedSerialLot(true);
    }
  }
  onSearchChange(value: any) {
    this.targetVal = value;
    if (this.targetVal == 'Picked') {
      this.isProcessingList = false;
      this.displayedColumn = ['menu', 'item', 'orderDate', 'orderNumber', 'customerName', 'shippingMethod', 'quantity', 'status', 'pickedDate', 'pickeedBy'];
      this.GetPickedData();
    } else if (this.targetVal == 'Processing') {
      this.isProcessingList = true;
      this.displayedColumn = ['menu', 'item', 'orderDate', 'orderNumber', 'customerName', 'shippingMethod', 'quantity', 'status', 'pickDate', 'pickBy'];
      this.GetProcessingData();
    } else {
      this.isProcessingList = false;
      this.displayedColumn = ['menu', 'item', 'orderDate', 'orderNumber', 'customerName', 'shippingMethod', 'quantity', 'status', 'bulkAction', 'CreatePickingTicket'];
      this.GetAllPickList();
    }
  }
  SearchListView(value: any) {
    this.selectedCustomerSearch = value.value;
    if (this.selectedCustomerSearch == 'Order Date') {
      this.selectedCompanyText = 'Order Date';
    } else if (this.selectedCustomerSearch == 'Order Number') {
      this.selectedCompanyText = 'Order Number';
    } else if (this.selectedCustomerSearch == 'Customer Name') {
      this.selectedCompanyText = 'Customer Name';
    } else if (this.selectedCustomerSearch == 'Shipping Method') {
      this.selectedCompanyText = 'Shipping Method';
    } else if (this.selectedCustomerSearch == 'Quantity') {
      this.selectedCompanyText = 'Quantity';
    } else if (this.selectedCustomerSearch == 'Status') {
      this.selectedCompanyText = 'Status ';
    } else {
      this.selectedCompanyText = 'All';
      this.selectedCustomerSearch = 'All';
    }
  }
  FilterListByValue(value: any) {
    if (value.target.value != '') {
      if (this.selectedCustomerSearch == 'Order Date') {
        this.allPickListDS = this.allPickList.filter(x => this.datepipe.transform(x.orderDate, 'LLL-dd-yyyy').includes(value.target.value.trim()))
      } else if (this.selectedCustomerSearch.trim() == 'Order Number') {
        this.allPickListDS = this.allPickList.filter(x => x.soNo.trim().toUpperCase().includes(value.target.value.trim().toUpperCase()))
      } else if (this.selectedCustomerSearch.trim() == 'Customer Name') {
        this.allPickListDS = this.allPickList.filter(x => x.customerName != null ? x.customerName.trim().toUpperCase().includes(value.target.value.trim().toUpperCase()) : '')
      } else if (this.selectedCustomerSearch.trim() == 'Shipping Method') {
        this.allPickListDS = this.allPickList.filter(x => x.shippingType.toUpperCase().includes(value.target.value.trim().toUpperCase()))
      } else if (this.selectedCustomerSearch.trim() == 'Quantity') {
        this.allPickListDS = this.allPickList.filter(x => x.totalInvQty == value.target.value.trim())
      } else if (this.selectedCustomerSearch.trim() == 'Status') {
        this.allPickListDS = this.allPickList.filter(x => x.status.trim().toUpperCase().includes(value.target.value.trim().toUpperCase()))
      } else if (this.selectedCustomerSearch.trim() == 'All') {
        this.allPickListDS = new MatTableDataSource(this.allPickList);
        this.allPickListDS.filter = value.target.value.trim().toUpperCase();
      } else {
        this.allPickListDS = new MatTableDataSource(this.allPickList);
      }
    } else {
      this.allPickListDS = new MatTableDataSource(this.allPickList);
    }
  }
  Mode(type: boolean) {
    this._mode = type;
  }
  Back() {
    this.isProcessingList = false;
    if (this.pageName == 1) {
      this.counter = this.BatchType == 'Sales Order' ? 1 : 0;
      this.BatchType = '';
      this.OrderPick = false;
      this.pageName = 0;
    } else if (this.pageName == 2) {
      this.hideShow = false;
      this.IsPickList = false;
      this.OrderPick = true;
      this.showCongrats = false;
      this.pageName = 1;
      this.selection = new SelectionModel<any>(true, [])
      this.counter = this.BatchType == 'Sales Order' ? 1 : 0;
      this.allPickList = [];
      this.allPickListDS = new MatTableDataSource(this.allPickList)
      this.displayedColumn = ['menu', 'item', 'orderDate', 'orderNumber', 'customerName', 'shippingMethod', 'quantity', 'status', 'bulkAction', 'CreatePickingTicket'];
    } else if (this.pageName == 3) {
      this.hideShow = true;
      this.IsPickList = false;
      this.pageName = 2;
      this.showCongrats = false;
      this.OrderPartIds = undefined;
      this.onSearchChange(this.targetVal);
    }
  }
  Home() {
    window.location.reload();
  }
  BatchTypeMode(type: string) {
    this.BatchType = type;
    this.counter = this.BatchType == 'Sales Order' ? 1 : 0;
    this.OrderPick = true;
    this.showCongrats = false;
    this.pageName = 1;
  }
  ManuallySelectOrder() {
    this.hideShow = true;
    this.IsPickList = false;
    this.OrderPick = false;
    this.pageName = 2;
    this.GetAllPickList();
  }
  Counter(type: string) {
    if (type == 'add') {
      this.counter++;
    } else if (type == 'sub') {
      this.counter--;
      this.counter < 0 == true ? (this.counter = 0) : this.counter
    }
  }
  AvailableDevice(index: number, partNo: any) {
    this.ScanIndex = index;
    this._pickListIndex = index;
    $("#ScanPartNumber").show();
  }

  ScanPartNumber(PartNo: any) {
    if (PartNo != '') {
      this.ScanPartNO = PartNo.trim().toUpperCase();
    }
  }
  ScanToteId(ToteId: any) {
    this.ToteId = ToteId;
  }
  onCodeResult(resultString: string) {
    this.qrResultString = resultString;
    this.barScanValue = this.qrResultString;
    this.IsScan = false;
    if (this.IsToteScan) {
      this.pickListByOrderId.filter(x => x.partNumber == this.ScanPartNO)[0].toteId = this.barScanValue;
      this.ToteUpdate(this.pickListByOrderId.filter(x => x.partNumber == this.ScanPartNO)[0]);
      this.barScanValue = '';
      this.IsToteScan = false;
    } else {
      this.ConfirmPickCount(this.ScanIndex, this.ScanPartNO);
    }
  }
  StartPicking(value: number) {
    this.hideShow = false;
    this.IsPickList = true;
    this.pageName = value == 0 ? 2 : 3;
    this.GetAllPickingList(value, true);
  }
  GetAllPickingList(type: number, IsOpIdTaken: boolean) {
    this.loader = true;
    let OrderId = "";
    let count = 0;
    count = IsOpIdTaken == true ? this.counter : (this.allPickList.length == 0 ? this.pickListByOrderId.length : this.selection.selected.length);
    if (type == 0) {
      OrderId = "";
    } else {
      if (this.allPickList.length == 0 && this.pickListByOrderId.length > 0) {
        this.pickListByOrderId.forEach(element => {
          OrderId = OrderId + element.orderId + ', '
        });
        OrderId = OrderId.slice(0, -2);
      }
      if (this.selection.selected.length > 0) {
        this.selection.selected.forEach(element => {
          OrderId = OrderId + element.orderId + ', '
        });
        OrderId = OrderId.slice(0, -2);
      }
    }
    this.pickListService.PickListTypeByOrderIds(OrderId, this.CompanyId, this.BatchType, count, this.userId)
      .subscribe({
        next: (data: any) => {
          if (data.dataList.length > 0) {
            this.pickListByOrderId = [];
            if (data.dataList.length > 0)
              this.pickListByOrderId = data.dataList;
            if (IsOpIdTaken && this.pickListByOrderId.length > 0) {
              this.OrderPartIds = [];
              this.pickListByOrderId.forEach(element => {
                this.OrderPartIds.push(element);
              });
            }
          }
          this.loader = false;
        },
        error: (err) => {
          this.loader = false;
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  GetAllPickList() {
    this.loader = true;
    this.showCongrats = false;
    this.pickListService.GetReadyToPickList(this.CompanyId, this.BatchType)
      .subscribe({
        next: (data: any) => {
          this.allPickList = [];
          this.selection.clear()
          this.allPickList = data.dataList;
          this.allPickListDS = new MatTableDataSource(this.allPickList);
          this.loader = false;
        },
        error: (err) => {
          this.loader = false;
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  GetPickedData() {
    this.loader = true;
    this.pickListService.GetPickedData(this.CompanyId)
      .subscribe({
        next: (data: any) => {
          this.allPickList = [];
          this.allPickList = data.dataList;
          this.allPickListDS = new MatTableDataSource(this.allPickList);
          this.loader = false;
        },
        error: (err) => {
          this.loader = false;
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  GetProcessingData() {
    this.loader = true;
    this.pickListService.GetProcessingData(this.CompanyId)
      .subscribe({
        next: (data: any) => {
          this.allPickList = [];
          this.allPickList = data.dataList;
          this.allPickListDS = new MatTableDataSource(this.allPickList);
          this.loader = false;
        },
        error: (err) => {
          this.loader = false;
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  GetEqualShippedSerialLot(IsLoader: boolean) {
    this.loader = IsLoader;
    this.pickListService.CountItemsAndOrdersForPickList(this.CompanyId)
      .subscribe({
        next: (data: any) => {
          this.CountItemsAndOrdersForPickList = data.data.pickListHeader;
          this.loader = false;
        },
        error: (err) => {
          this.loader = false;
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  GetPickList() {
    if (this.OrderPartIds.length > 0) {
      this.loader = true;
      this.pickListService.GetPickListNew(this.CompanyId, this.OrderPartIds)
        .subscribe({
          next: (data: any) => {
            this.pickListByOrderId = data.dataList;
            this.loader = false;
          },
          error: (err) => {
            this.loader = false;
            this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
          },
        });
    }
  }
  removeDuplicates(myArray, Prop) {
    return myArray.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[Prop]).indexOf(obj[Prop]) === pos;
    });
  }
  toggleSelection(row: any): void {
    this.selection.toggle(row);
  }
  isSomeSelected() {
    return (this.selection.selected.length == this.counter) == true ? true : false;
  }
  InsUpdPicklist(value: any) {
    this.loader = true;
    this.pickListService.InsUpdPicklistNew(value, this.counter)
      .subscribe({
        next: (data: any) => {
          this._pickListIndex = 0;
          this.GetPickList();
          this.GetEqualShippedSerialLot(false);
        },
        error: (err) => {
          this.loader = false;
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  ToteUpdate(value: any) {
    this.loader = true;
    this.pickListService.ToteUpdate(value.id, value.toteId)
      .subscribe({
        next: (data: any) => {
          this.GetPickList();
        },
        error: (err) => {
          this.loader = false;
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  IsCongratulationPage(): number {
    let len = 0;
    if (this.pickListByOrderId.length > 0) {
      len = this.pickListByOrderId.filter(x => x.orderedQnty > 0).length
    }
    return len;
  }
  CloseConfirmPickCount() {
    this.counter = 0;
    this.barScanValue = '';
    this.IsScan = false;
    this.ScanPartNO = '';
    $("#ConfirmPickCount").hide();
    $("#new-layout").show();
  }
  CloseScanPartNumber() {
    this.barScanValue = '';
    this.IsScan = false;
    this.ScanPartNO = '';
    $("#ScanPartNumber").hide();
    $("#new-layout").show();
  }
  ShowScanTote() {
    this.barScanValue = '';
    this.IsScan = false;
    this.ScanPartNO = '';
    this.ToteId = '';
    $("#ScanTote").show();
    $("#ScanPartNumber").hide();
    $("#ConfirmPickCount").hide();
    $("#new-layout").show();
  }
  CloseScanTote() {
    this.barScanValue = '';
    this.IsScan = false;
    this.ScanPartNO = '';
    this.ToteId = '';
    $("#ScanTote").hide();
    $("#new-layout").show();
  }
  SwapPickProgressList(value: boolean) {
    this.IsProgressDetails = value;
    this.GetPickList();
  }
  PickListImagesModal(partId: number) {
    this.loader = true;
    this.pickListImages = [];
    this.pickListService.GetPartImages(partId)
      .subscribe({
        next: (data: any) => {
          if (data.dataList.length > 0) {
            data.dataList.forEach((element) => {
              this.pickListImages.push({
                image: element.imagePath,
                thumbImage: element.imagePath
              });
            });
          }
          this.loader = false;
          $("#PickListImagesModal").show();
        },
        error: (err) => {
          this.loader = false;
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  PickListImagesModalClose() {
    $("#PickListImagesModal").hide();
    this.pickListImages = [];
  }

  EditTote(tote: any, i: number) {
    this.pickListByOrderId.filter(x => x.partId == tote.partId)[0].isEditable = true;
  }
  changeTote(value: any, i: number) {
    this.pickListByOrderId[i].toteId = value.value;
  }
  pickedCountValue(e: any, part: any) {
    if (e.target.value != "" && Number(e.target.value) > 0) {
      if (Number(e.target.value) <= part.orderedQnty) {
        this.counter = Number(e.target.value);
      } else if (Number(e.target.value) > 0) {
        this.counter = part.orderedQnty;
        e.target.value = this.counter;
      }
    } else {
      this.counter = 0;
      e.target.value = "";
    }
  }
  MoveReadyToPick(value: any) {
    this.loader = true;
    this.pickListService.MoveReadyToPick(value.orderId)
      .subscribe({
        next: (data: any) => {
          this.GetProcessingData();
        },
        error: (err) => {
          this.loader = false;
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  GetSinglePickList(value: any) {
    if (value.statusId == 6 && this.targetVal == 'Processing') {
      this.loader = true;
      this.pickListService.GetSinglePickList(value.orderId, this.CompanyId)
        .subscribe({
          next: (data: any) => {
            if (data.dataList.length > 0) {
              this.hideShow = false;
              this.IsPickList = true;
              this.pageName = 3;
              this.pickListByOrderId = [];
              if (data.dataList.length > 0)
                this.pickListByOrderId = data.dataList;
              if (this.pickListByOrderId.length > 0) {
                this.OrderPartIds = [];
                this.pickListByOrderId.forEach(element => {
                  this.OrderPartIds.push(element);
                });
              }
            }
            this.loader = false;
          },
          error: (err) => {
            this.loader = false;
            this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
          },
        });
    }
  }
  //Swal Start
  ScanTote(part: any, IsSkip: boolean) {
    if (part.pickedQnty == 0) {
      part.pickedQnty = this.counter;
      part.orderedQnty = part.orderedQnty - part.pickedQnty;
      part.onHand = part.onHand - part.pickedQnty;
    } else {
      part.pickedQnty = part.pickedQnty + this.counter;
      part.orderedQnty = part.orderedQnty - this.counter;
      part.onHand = part.onHand - this.counter;
    }
    this.pickListByOrderId[this._pickListIndex].toteId = IsSkip ? this.pickListByOrderId[this._pickListIndex].toteId : this.ToteId;
    this.pickListByOrderId.forEach(e => {
      e.onHand = e.fromLocation == part.fromLocation == true ? part.onHand : e.onHand;
    });
    this.CloseScanTote()
    $("#ConfirmPickCount").hide();
    this.InsUpdPicklist(part)
    if (this.IsCongratulationPage() == 0) {
      $("#Congratulations").show();
      this.showCongrats = true;
    } else {
      this.showCongrats = false;
      this.CloseConfirmPickCount();
    }
  }
  camerasNotFound(e: any) {
    if (e.message == 'Requested device not found') {
      // for scanGun
      this.IsScan = false;
      this.IsScanGun = true;
    } else {
      Swal.fire({
        text: 'Device not found.',
        showCancelButton: true,
        cancelButtonText: 'Close',
        showConfirmButton: false
      }).then((result) => {
        this.IsScan = false;
        this.CloseConfirmPickCount();
      });
    }
  }
  private async getMediaDevices(): Promise<void> {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      this.devices = devices.filter(device => device.kind === 'videoinput');
      // Optionally set the first found camera as selected
      this.selectedDevice = this.devices.length > 0 ? this.devices[0] : null;
    } catch (error) {
    }
  }
  ConfirmPickCount(index: number, partNo: string) {
    this._pickListIndex = index;
    this.counter = 0;
    if (!this.IsToteScan) {
      if (this.barScanValue.toUpperCase().trim() == partNo.toUpperCase().trim()) {
        $("#new-layout").hide();
        $("#ConfirmPickCount").show();
      } else if (this.pickListByOrderId[this._pickListIndex].partNumber.trim().toUpperCase() == this.ScanPartNO) {
        this.CloseScanPartNumber();
        $("#ConfirmPickCount").show();
      } else {
        this.ScanToteMismatch();
      }
    } else if (this.IsToteScan) {
      this.pickListByOrderId.filter(x => x.partNumber == this.ScanPartNO)[0].toteId = this.barScanValue;
      this.barScanValue = '';
      this.IsToteScan = false;
    }
  }
  ScanToteMismatch() {
    Swal.fire({
      titleText: 'Part Number Mismatch',
      text: 'The part number you scanned does not match the part number from the Pick List. Please check the part and try again.',
      cancelButtonText: 'Close',
      showConfirmButton: false,
      showCancelButton: true,
      allowOutsideClick: false
    }).then((result: any) => {
      if (result.dismiss != 'backdrop')
        this.CloseConfirmPickCount();
    });
  }
  ConfirmPickCountTest(index: number, part: any) {
    this.counter = 0;
    this._pickListIndex = index;
    $("#new-layout").hide();
    $("#ConfirmPickCount").show();
  }
  slider(value: any, type: string) {
    if (type == 'min') {
      this.counter = 0;
    } else {
      this.counter = Number(value) <= this.pickListByOrderId[this._pickListIndex].orderedQnty ? Number(value) : this.pickListByOrderId[this._pickListIndex].orderedQnty;
    }
  }
}



