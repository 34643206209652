<!-- -- View History flyout Start-->
<div class="overcstfixedsidebar active" *ngIf="IsHistoryFlyOut"></div>
<div class="sidebar_trackingnum active left-sidebar" *ngIf="IsHistoryFlyOut">
    <div class="titlecstmsidebar">
        <h2 class="top-heading">Order History</h2>
        <h4>Scroll to view the Purchase Order History.</h4>
    </div>
    <!-- ----------------------------------------------------------------------PO History flyout Start------------------------------------------------------- -->
    <div class="table-order-history">
        <table class="table-material border-0">
            <thead class="table-responsive-header">
                <tr>
                    <th>Document</th>
                    <th>Order Number</th>
                    <th>Order Status</th>
                    <th>Confirmation Number</th>
                    <th>Modified Date</th>
                    <th>Changed By</th>
                </tr>
            </thead>
            <tbody class="table-responsive-body" >
                <tr *ngFor="let log of logList; let i = index;">
                    <td data-label="Document">
                            <mat-icon *ngIf="log.snap!=''" (click)="GeneratePOPdf(log.snap, log.poOrderNumber)">insert_drive_file</mat-icon>
                    </td>
                    <td data-label="Order Number"> {{log.poOrderNumber}}</td>
                    <td data-label="Order Status">{{log.status}}</td>
                    <td data-label="Confirmation Number">{{log.confirmationNo}}</td>
                    <!-- <td data-label="Modified Date">{{log.createdDate | date :'M/d/yy h:mm a'}}</td> -->
                    <td data-label="Modified Date">{{log.status}} - {{log.createdDate | date :'M/d/yy'}}</td>
                    <td data-label="Changed By"> {{log.createdBy}}</td>
            </tbody>
        </table>
    </div>
    <div class="footer-btn">
        <button type="button" class="save-btn btn update-btn" (click)="CloseHistoryFlyOut()">Close</button>
    </div>
    <!-- ----------------------------------------------------------------------PO History flyout Start------------------------------------------------------- -->

</div>
<!-- -- View History flyout End-->