<!--Section to create new Purchase Order-->
<section class="content-section-2" id="new-layout" style="background-color:#f4f3f7;">
    <div class="loading lodingtext" *ngIf="loader">
    </div>

    
    <form [formGroup]="masterForm">
        <!-- --------------top bredcumps css here --------- -->
        <div class="top-bredcumps">
            <div class="cstmbreadcum paddingmng-right">
                <div class="page_title_cstms">
                    <ul class="link_current">
                        <li>
                            <a class="backbtns" (click)="backPOList()">
                                <mat-icon>keyboard_arrow_left</mat-icon>
                                <span>Back to List</span>
                            </a>
                        </li>
                        <li>
                            <span>Purchase Order Details</span>
                        </li>
                    </ul>
                </div>
                <div class="top-button d-flex justify-content-between align-items-center gap-3">
                    <button class="btn cancel-btn update-btn" (click)="Cancel()">
                        Cancel
                    </button>
                    <button class="save-btn btn update-btn" [matMenuTriggerFor]="menu"
                        [disabled]="masterForm.value.statusOld ==10 && masterForm.value.id >= 0"
                        (click)="showlist()">Share</button>
                    <mat-menu #menu="matMenu" style="background-color: #74777f;">
                        <button mat-menu-item (click)="AddUpdatePO(true, item);"
                            *ngFor="let item of pdfOption">{{item}}</button>
                    </mat-menu>
                    <button class="btn save-btn update-btn" (click)="AddUpdatePO()">
                        Save
                    </button>
                </div>
            </div>
        </div>
        <!-- --------------top bredcumps css here --------- -->
        <div class="content-section-1">
            <div class="container-fluid mt-30">
                <div class="main-section">
                    <!-- {{masterForm.value | json}} -->
                    <!-- ------------- top cards row section start here------------- -->
                    <div class="row top-section">
                        <!-- for vendor -->
                        <div class="col-xl-4 col-md-6 col-12 mb-3 first-card column-width">
                            <app-vendor-card [companyId]="companyId" [formGrp]="masterForm"
                                [isVendor]="isVendor" (vendorIdEvent)="getVendor($event)"
                                (addVendorEvent)="addVendor()"></app-vendor-card>
                        </div>
                        <!-- for shipto -->
                        <div class="col-xl-4 col-md-6 col-12 mb-3 second-card column-width">
                            <app-po-shipto [companyId]="companyId" [formGrp]="masterForm" [ShipTo]="ShipTo"
                                [isShipTo]="isShipTo" [customerList]="customerList"
                                (shiptoEvent)="changeShipto($event)"
                                (customerEvent)="changeDDLCustomer($event)"></app-po-shipto>
                        </div>
                        <div
                            class="col-xl-4 col-md-12 col-12 mb-3 fourth-card mat-header-change column-width-full">
                            <app-po-orderdetials-card [formGrp]="masterForm" [companyId]="companyId"
                                [statuslist]="statuslist" (historyEvent)="showHistoryFlyOut()"
                                (statusEvent)="onStatusChange($event)"></app-po-orderdetials-card>
                        </div>
                    </div>

                    <!-- ---------------------second row section start here--------------- -->
                    <div class="section-2 mt-2">
                        <div class="row cards-padding">
                            <div class="col-xl-6">
                                <div class="row">
                                    <div class="col-12 mb-3 first-card">
                                        <mat-form-field class="forShipping m-0 mt-12 p-0 w-100"
                                            appearance="outline">
                                            <mat-label>Buyer</mat-label>
                                            <mat-select class="select-arrow-change-icon"
                                                formControlName="buyerId">
                                                <mat-option *ngFor="let item of buyerList"
                                                    [value]="item.userId">
                                                    {{item.name}} - {{item.userEmail}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 mb-sm-3">
                                <div class="row align-items-center h-100">
                                    <div class="col-md-9 col-7">
                                        <div class="first-card-1 border-div" appFileUpload
                                            (filesChangeEmiter)="onFileChange($event)">
                                            <div class="dropzone">
                                                <div class="text-wrapper">
                                                    <div class="centered">
                                                        <input type="file" accept=".pdf"
                                                            class="choose-file-hidden upload_icon_custom"
                                                            (change)="onFileChange($event)" accept=".pdf"
                                                            multiple>
                                                        <label for="file"
                                                            class="drop-file-text w-100 text-center mb-0">Add
                                                            attachment here !</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-5 select-file-column d-flex justify-content-end">
                                        <button class="btn save-btn update-btn">
                                            <input type="file" accept=".pdf"
                                                class="choose-file-hidden upload_icon_custom"
                                                (change)="onFileChange($event)" multiple>
                                            <span>Select File</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="row">
                                    <div class="col-md-4 first-card mb-sm-3">
                                        <mat-form-field class="forShipping m-0 mt-12 p-0 w-100"
                                            appearance="outline">
                                            <mat-label>Payment Terms</mat-label>
                                            <mat-select class="select-arrow-change-icon"
                                                formControlName="paymentTerm">
                                                <mat-option *ngFor="let item of termsData" [value]="item.id">
                                                    {{item.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-4 mb-sm-3 second-card">
                                        <mat-form-field class="forShipping m-0 mt-12 p-0 w-100"
                                            appearance="outline">
                                            <mat-label>Shipping Terms</mat-label>
                                            <mat-select class="select-arrow-change-icon"
                                                formControlName="shippingTerm">
                                                <mat-option *ngFor="let item of shippingData" [value]="item.id">
                                                    {{item.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-4 mb-sm-3 second-card">
                                        <mat-form-field class="forShipping m-0 mt-12 p-0 w-100"
                                            appearance="outline">
                                            <mat-label>Sales Tax</mat-label>
                                            <mat-select class="select-arrow-change-icon"
                                                formControlName="salesTax">
                                                <mat-option *ngFor="let tax of TaxOption" [value]="tax.id">
                                                    {{tax.value}}
                                                </mat-option>


                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 ">
                                <div class="row">
                                    <div class="col-md-4 mb-sm-3 second-card">
                                        <mat-form-field class="forShipping m-0 mt-12 p-0 w-100"
                                            appearance="outline">
                                            <mat-label>FOB Point</mat-label>
                                            <mat-select class="select-arrow-change-icon"
                                                formControlName="fobPoint">
                                                <mat-option *ngFor="let item of FobPoint" [value]="item.id">
                                                    {{item.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-4 mb-sm-3 second-card">
                                        <mat-form-field class="forShipping m-0 mt-12 p-0 w-100"
                                            appearance="outline">
                                            <mat-label>Carrier</mat-label>
                                            <mat-select class="select-arrow-change-icon"
                                                formControlName="carrier">
                                                <mat-option *ngFor="let item of carrierData" [value]="item.id">
                                                    {{item.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-4 mb-sm-3 fourth-card">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <!-- <mat-label>Est. Ship Date</mat-label> -->
                                            <mat-label>Est. Arrival Date</mat-label>
                                            <input matInput [matDatepicker]="picker2"
                                                formControlName="estShipDate">
                                            <mat-hint>MM/DD/YYYY</mat-hint>
                                            <mat-datepicker-toggle matIconSuffix [for]="picker2">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker2></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>


                            </div>
                            <div class="col-xl-6">
                                <div class="row">
                                    <div class="col-md-12 first-card mb-xl-0 mb-lg-3 mb-md-3 mb-sm-3">
                                        <mat-form-field class="example-full-width outline w-100"
                                            appearance="outline">
                                            <mat-label>Vendor Notes</mat-label>
                                            <input matInput placeholder="Enter Vendor Note" value="Sushi"
                                                formControlName="vendorNote">
                                        </mat-form-field>
                                    </div>

                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="row">
                                    <div class="col-md-12 fourth-card">
                                        <mat-form-field class="example-full-width outline w-100"
                                            appearance="outline">
                                            <mat-label>Internal Notes</mat-label>
                                            <input matInput placeholder="Enter Internal Note" value="Sushi"
                                                formControlName="internalNote">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- --------------third section start here------------------------------- -->
                    <div class="row third-section mt-4 align-items-center mb-3">
                        <div class="col-md-4 tabs-section d-flex justify-content-between">
                            <div [ngClass]="isValidItems == true ? 'add-btn' : 'add-btn-danger'">
                                <button mat-stroked-button class="add-item-btn update-btn"
                                    (click)="openFlyOut()">
                                    <mat-icon matSuffix class="plus-icon">add</mat-icon>Add Item</button>
                            </div>
                        </div>
                        <div class="col-md-4 tab-section">
                            <div class="cstm-radiosearch">
                                <div class="filter_cstm_radio" (change)="onItemChange($event)">
                                    <input type="radio" name="status_filter" id="all_open" checked>
                                    <label for="all_open">
                                        <div>
                                            <mat-icon>check</mat-icon>
                                        </div>
                                        <span>Summary</span>
                                    </label>
                                    <input type="radio" name="status_filter" id="allopen">
                                    <label for="allopen">
                                        <div>
                                            <mat-icon>check</mat-icon>
                                        </div>
                                        <span>Detail</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-12 collapse-section d-flex justify-content-end align-items-center"
                            *ngIf="radioTarget == 'allopen'">
                            <div class="button-1 btn link" (click)="onToggleAll(false)">Collapse all</div>
                            <div class="button-2 btn link" (click)="onToggleAll(true)">Expand All</div>
                        </div>

                    </div>
                    <!-- ----------------purchase order details table section------------- -->
                    <div class="purchase-order-table">
                        <div class="" *ngIf="radioTarget == 'all_open'">
                            <div class="card-desc example-container cstm_scrollbar_theme company-table">
                                <ul class="heading-section">
                                    <li class="menu-section">Menu</li>
                                    <li class="item-section">Item</li>
                                    <li class="w-8 text-break">Vendor <br> Part Number</li>
                                    <li class="w-8 text-break">Company <br> Part Number</li>
                                    <li class="w-30 description">Description</li>
                                    <li class="w-10">Ordered</li>
                                    <li class="w-5 text-center">UOM</li>
                                    <li class="w-10">Unit Cost</li>
                                    <li class="w-5 text-break">Update Unit Cost</li>
                                    <li class="w-10 extension">Extension</li>
                                </ul>
                                <div formArrayName="summaryItems"
                                    *ngFor="let item of masterForm.controls.summaryItems?.controls; let i = index;">
                                    <ul [formGroupName]="i" class="value-section">
                                        <li class=" responsive-menu" data-label="Menu">
                                            <button mat-icon-button [matMenuTriggerFor]="menu1"
                                                aria-label="More Options">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menu1="matMenu">
                                                <button mat-menu-item (click)="deleteItem(i)">
                                                    <!-- <button mat-menu-item> -->
                                                    <mat-icon>delete</mat-icon>
                                                    <span>Delete</span>
                                                </button>
                                            </mat-menu>
                                        </li>
                                        <li class="item-section" data-label="Item">
                                            {{i+1}}
                                        </li>
                                        <li class="w-8 text-break" data-label="Vendor Part Number">
                                            {{masterForm.value.summaryItems[i].vendorPartNumber}}
                                        </li>
                                        <li class="w-8 text-break" data-label="Company Part Number">
                                            {{masterForm.value.summaryItems[i].partNo}}
                                        </li>
                                        <li class="w-30 text-break description" data-label="Description">
                                            {{masterForm.value.summaryItems[i].vendorPartName}}
                                        </li>
                                        <li class="w-10 order-section quantity-div" data-label="Ordered">
                                            <mat-form-field appearance="outline">
                                                <input matInput class="w-100" formControlName="quantity"
                                                    placeholder="Quantity" (input)="calcExtension(i)" />
                                            </mat-form-field>
                                        </li>
                                        <li class="w-5" data-label="UOM">
                                            <div>{{(masterForm.value.summaryItems[i].unitofMeasureId ==
                                                undefined ||
                                                masterForm.value.summaryItems[i].unitofMeasureId == '') ? 'NA' :
                                                masterForm.value.summaryItems[i].unitofMeasureId}}</div>
                                        </li>
                                        <li class="w-10 unit-cost-section quantity-div" data-label="Unit Cost">
                                            <div *ngIf="!masterForm.value.summaryItems[i].isUpdateUnitCost && masterForm.value.summaryItems[i].vendorDiscount.length>0"
                                                class="">
                                                <mat-form-field appearance="outline" class="text-link">
                                                    <a type="button"
                                                        (click)="openPODiscountModal(masterForm.value.summaryItems[i].vendorDiscount)">
                                                        <input matInput class="w-100" formControlName="cost"
                                                            style="color: #005ead;" placeholder="Cost"
                                                            readonly />
                                                    </a>
                                                    <span matTextPrefix>$&nbsp;</span>
                                                </mat-form-field>

                                            </div>
                                            <div *ngIf="!masterForm.value.summaryItems[i].isUpdateUnitCost && masterForm.value.summaryItems[i].vendorDiscount.length<=0"
                                                class="">
                                                <mat-form-field appearance="outline" class="text-link">
                                                    <input matInput class="w-100" formControlName="cost"
                                                        (blur)="calcExtension(i)" placeholder="Cost" />
                                                    <span matTextPrefix>$&nbsp;</span>
                                                </mat-form-field>

                                            </div>
                                            <div *ngIf="masterForm.value.summaryItems[i].isUpdateUnitCost"
                                                class="">
                                                <mat-form-field appearance="outline">
                                                    <input matInput class="w-100" formControlName="cost"
                                                        (blur)="calcExtension(i)" placeholder="Cost" />
                                                    <span matTextPrefix>$&nbsp;</span>
                                                </mat-form-field>
                                            </div>
                                        </li>
                                        <li class="w-5" data-label="Update Unit Cost">
                                            <mat-checkbox formControlName="isUpdateUnitCost"
                                                (change)="onUpdateUnitCost(i)"></mat-checkbox>
                                        </li>
                                        <li class="w-10 text-end extension" data-label="Extension">
                                            {{masterForm.value.summaryItems[i].extension | currency}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="" *ngIf="radioTarget == 'allopen'">
                            <div class="card-desc example-container cstm_scrollbar_theme company-table">
                                <ul class="heading-section">
                                    <li class="text-center">Menu</li>
                                    <li class="item-section">Item</li>
                                    <li class="w-8 text-break">Vendor Part Number</li>
                                    <li class="w-8 text-break">Company Part Number</li>
                                    <li class="w-30 description">Description</li>
                                    <li class="w-4">Purpose</li>
                                    <li class="w-4">Ordered</li>
                                    <li class="w-4">Channel</li>
                                    <li class="w-4">Received</li>
                                    <li class="w-4">UOM</li>
                                    <li class="w-10">Unit Cost</li>
                                    <li class="w-5 text-break">Update Unit Cost</li>
                                    <li class="extension-div">Extension</li>
                                </ul>
                                <ng-container formArrayName="summaryItems"
                                    *ngFor="let item of masterForm.controls.summaryItems?.controls; let i = index;">
                                    <div class="mt-0 p-0" [formGroupName]="i">
                                        <ul class="value-section list-unstyled py-2 align-items-center">
                                            <li class="responsive-menu" data-label="Menu">
                                                <button mat-icon-button [matMenuTriggerFor]="menu"
                                                    aria-label="More Options" class="p-0">
                                                    <mat-icon>more_vert</mat-icon>
                                                </button>
                                                <mat-menu #menu="matMenu">
                                                    <button mat-menu-item>
                                                        <mat-icon>delete</mat-icon>
                                                        <span>Delete</span>
                                                    </button>
                                                </mat-menu>
                                            </li>
                                            <li class="d-flex item-section" data-label="Item">
                                                <label>{{i+1}}</label>
                                                <!-- <span class="collapse-icon"
                                                    *ngIf="masterForm.value.summaryItems[i].detailList.length > 0">
                                                    <mat-icon
                                                        (click)="AddSubItems(i)">
                                                        {{masterForm.value.summaryItems[i].isShow === true ?
                                                        'expand_more' :
                                                        'keyboard_arrow_right'}}
                                                    </mat-icon>
                                                    {{masterForm.value.summaryItems[i].isShow}}
                                                </span> -->

                                                <span *ngIf="masterForm.value.summaryItems[i].detailList.length > 0">
                                                    <mat-icon class="mat-icon-rtl-mirror" *ngIf="masterForm.value.summaryItems[i].isShow"
                                                        (click)="AddSubItems(i,false)">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-icon class="mat-icon-rtl-mirror" *ngIf="!masterForm.value.summaryItems[i].isShow"
                                                        (click)="AddSubItems(i,true)">
                                                        keyboard_arrow_right
                                                    </mat-icon>
                                                </span>
                                            </li>
                                            <li class="w-8 text-break" data-label="Vendor Part Number">
                                                {{masterForm.value.summaryItems[i].vendorPartNumber}}
                                            </li>
                                            <li class="w-8 text-break" data-label="Company Part Number">
                                                {{masterForm.value.summaryItems[i].partNo}}
                                            </li>
                                            <li class="w-30 description" data-label="Description">
                                                {{masterForm.value.summaryItems[i].vendorPartName}}
                                            </li>
                                            <li class="w-4 text-break" data-label="Purpose">
                                                {{masterForm.value.summaryItems[i].purpose}}
                                            </li>
                                            <li class="w-4" data-label="Ordered">
                                                {{masterForm.value.summaryItems[i].quantity}}
                                            </li>
                                            <li class="w-4" data-label="Channel">
                                                Retail
                                            </li>
                                            <li class="w-4" data-label="Received">
                                                {{masterForm.value.summaryItems[i].received}}
                                            </li>
                                            <li class="w-4" data-label="UOM">
                                                <small>{{(masterForm.value.summaryItems[i].unitofMeasureId ==
                                                    undefined
                                                    ||
                                                    masterForm.value.summaryItems[i].unitofMeasureId == '') ?
                                                    'NA' :
                                                    masterForm.value.summaryItems[i].unitofMeasureId}}</small>
                                            </li>
                                            <li class="w-10 unit-cost-section" data-label="Unit Cost">
                                                <div *ngIf="!masterForm.value.summaryItems[i].isUpdateUnitCost && masterForm.value.summaryItems[i].vendorDiscount.length>0"
                                                    class="">
                                                    <mat-form-field appearance="outline" class="text-link">
                                                        <a type="button"
                                                            (click)="openPODiscountModal(masterForm.value.summaryItems[i].vendorDiscount)">
                                                            <input matInput class="w-100" formControlName="cost"
                                                                style="color: #cde5f8;" placeholder="Cost"
                                                                readonly />
                                                        </a>
                                                        <span matTextPrefix>$&nbsp;</span>
                                                    </mat-form-field>

                                                </div>
                                                <div *ngIf="!masterForm.value.summaryItems[i].isUpdateUnitCost && masterForm.value.summaryItems[i].vendorDiscount.length<=0"
                                                    class="">
                                                    <mat-form-field appearance="outline" class="text-link">
                                                        <input matInput class="w-100" formControlName="cost"
                                                            (blur)="calcExtension(i)" placeholder="Cost" />
                                                        <span matTextPrefix>$&nbsp;</span>
                                                    </mat-form-field>
                                                </div>
                                                <div *ngIf="masterForm.value.summaryItems[i].isUpdateUnitCost"
                                                    class="">
                                                    <mat-form-field appearance="outline">
                                                        <input matInput class="w-100" formControlName="cost"
                                                            (blur)="calcExtension(i)" placeholder="Cost" />
                                                        <span matTextPrefix>$&nbsp;</span>
                                                    </mat-form-field>
                                                </div>
                                            </li>
                                            <li class="w-5" data-label="Update Unit Cost">
                                                <mat-checkbox formControlName="isUpdateUnitCost"
                                                    (change)="onUpdateUnitCost(i)"></mat-checkbox>
                                            </li>
                                            <li class="extension-div" data-label="Extension">
                                                {{masterForm.value.summaryItems[i].extension | currency}}
                                            </li>
                                        </ul>
                                        <ng-container formArrayName="detailList"
                                            *ngFor="let subItem of summaryItemDetailsGet(i).controls; let j = index;">
                                            <ul class="value-section list-unstyled py-2 align collapse-value" *ngIf="summaryItemsGet.value[i].isShow"
                                                [formGroupName]="j">
                                                <li class="" data-label="Menu">
                                                    <button mat-icon-button [matMenuTriggerFor]="menu"
                                                        aria-label="More Options" class="p-0">
                                                        <mat-icon>more_vert</mat-icon>
                                                    </button>
                                                    <mat-menu #menu="matMenu">
                                                        <button mat-menu-item>
                                                            <mat-icon>delete</mat-icon>
                                                            <span>Delete</span>
                                                        </button>
                                                    </mat-menu>
                                                </li>
                                                <li class="d-flex" data-label="item">
                                                    <label>{{i+1}}.{{j+1}}</label>
                                                </li>
                                                <li class="w-8 text-break">
                                                </li>
                                                <li class="w-8 text-break">
                                                </li>
                                                <li class="w-30 description">
                                                </li>
                                                <li class="w-4 text-break" data-label="Purpose">
                                                    {{subItem.value.docId > 0 ? subItem.value.docNo :
                                                    'Inventory'}}
                                                </li>
                                                <li class="w-4" data-label="Ordered">
                                                    <ng-container
                                                        *ngIf="subItem.value.docId == 0 || subItem.value.docId == null">
                                                        <mat-form-field appearance="outline">
                                                            <input matInput class="w-100"
                                                                formControlName="quantity"
                                                                (input)="calcExtension(i, true)" />
                                                        </mat-form-field>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="subItem.value.docId > 0 && subItem.value.docId != null">
                                                        {{subItem.value.quantity}}
                                                    </ng-container>
                                                </li>
                                                <li class="w-4" data-label="Channel">
                                                    Retail
                                                </li>
                                                <li class="w-4" data-label="Received">
                                                    {{subItem.value.received}}
                                                </li>
                                                <li class="w-4">
                                                </li>
                                                <li class="w-10" data-label="Unit Cost">
                                                    {{subItem.value.cost | currency}}
                                                </li>
                                                <li class="w-4">
                                                </li>
                                                <li class="extension-div" data-label="Extension">
                                                    <!-- {{subItem.value.extension | currency}} -->
                                                    {{subItem.value.quantity * subItem.value.cost | currency}}
                                                </li>
                                            </ul>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="row justify-content-end subtotal-section">
                            <div class="col-lg-8 pr-0">
                                <div class="card bg-change border-0">
                                    <div class="row additional-border-row image-row mt-2"
                                        *ngFor="let img of filesGet.value;let i = index;">
                                        <ng-container *ngIf="img.name.length > 0 && img.docType=='po'">
                                            <div class="col-6">
                                                <a class="mr-2" type="button" (click)="viewImage(img.base64)">
                                                    <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                                                </a>
                                                <span class="ml-2">{{img.name}}</span>
                                            </div>
                                            <div class="col-2" (click)="deleteFile(i)">
                                                <mat-icon style="float: right;">cancel</mat-icon>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 w-100 pl-0">
                                <ul class="heading-section subtotal-heading">
                                    <li class="total-heading">Subtotal:</li>
                                    <li class="total-value">{{masterForm.value.subTotal | currency}}
                                    </li>
                                </ul>
                                <ul class="heading-section subtotal-heading">
                                    <li class="total-heading">Shipping:</li>
                                    <li class="btn-section">
                                        <a type="button" class="cost-details"
                                            (click)="ShippingCostFlyout()">Cost
                                            Details</a>
                                    </li>
                                    <li class="total-value">{{masterForm.value.shipTaxTotal |
                                        currency}}</li>
                                </ul>
                                <ul class="heading-section subtotal-heading">
                                    <li class="total-heading">Sales Tax:</li>
                                    <li class="btn-section">Exempt</li>
                                    <li class="total-value">{{masterForm.value.salesTaxTotal |
                                        currency}}</li>
                                </ul>
                                <ul class="heading-section subtotal-heading">
                                    <li class="total-heading">Total:</li>
                                    <li class="total-value">{{masterForm.value.total | currency}}</li>
                                </ul>
                            </div>
                        </div>

                        <!-- Shipping Cost Flyout Start-->
                        <div class="Over-Manual-flyout" *ngIf="isShippingCostFlyout"></div>
                        <div class="serial-flyout" *ngIf="isShippingCostFlyout">
                            <div>
                                <div class="col-12 p-0 titlecstmsidebar border-bottom">
                                    <h2 class="offcanvas-top-heading">Add Shipping Cost Details</h2>
                                    <h4 class="mb-3 para-top">Add rows below to itemize the shipping costs for
                                        this
                                        Purchase
                                        Order</h4>
                                </div>
                                <div class="col-12 p-0 titlecstmsidebar mb-2">
                                    <div class="row">
                                        <div class="col-8">
                                            <h4 class="m-0 cost-description">Cost Description</h4>
                                        </div>
                                        <div class="col-3">
                                            <h4 class="m-0 cost-description">Amount</h4>
                                        </div>
                                        <div class="col-1">
                                            <h4 class="m-0"></h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 p-0">
                                    <div class="col-12 p-0" formArrayName="shipCostDetails">
                                        <div class="flex-between">
                                            <div class="col-12 scroll-serial path-classes">
                                                <div class="row"
                                                    *ngFor="let item of masterForm.controls.shipCostDetails?.controls; let i = index;">
                                                    <div class="col-12" [formGroupName]="i">
                                                        <div class="row mb-3">
                                                            <!-- <div class="col-2">
                                                {{i+1}}
                                            </div> -->
                                                            <div class="col-8 first-card">
                                                                <mat-form-field appearance="outline"
                                                                    class="w-100">
                                                                    <input matInput type="text"
                                                                        placeholder="Description"
                                                                        formControlName="description">
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-3 fourth-card">
                                                                <mat-form-field appearance="outline"
                                                                    class="w-100">
                                                                    <input matInput formControlName="cost"
                                                                        (blur)="calcShipCost(i)">
                                                                    <span matTextPrefix>$&nbsp;</span>
                                                                </mat-form-field>
                                                            </div>
                                                            <div
                                                                class="col-1 text-right d-flex align-items-center">
                                                                <a type="button" (click)="deleteCostDetails(i)">
                                                                    <mat-icon
                                                                        style="float: right;">cancel</mat-icon>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ng-container>
                                                    <div class="row mb-3">
                                                        <div class="col-8 d-flex justify-content-between">
                                                            <a type="button" (click)="addCostRow()"
                                                                class="add-row-btn"><span><i class="fa fa-plus"
                                                                        aria-hidden="true"></i></span>&nbsp;Add
                                                                Row</a>
                                                            <span class="bottom-value">
                                                                Total
                                                            </span>
                                                        </div>
                                                        <div class="col-3">
                                                            <span
                                                                class="bottom-value">{{masterForm.value.shipTaxTotal
                                                                | currency}}</span>
                                                        </div>
                                                        <div class="col-1 text-right">

                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                            <div class="col-12 p-0">
                                                <div class="footer-btn">
                                                    <button class="save-btn btn update-btn"
                                                        (click)="saveShippingCostFlyout()">Save and
                                                        Close</button>
                                                    <button (click)="closeShippingCostFlyout()"
                                                        class="btn cancel-btn update-btn">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Shipping Cost Flyout End -->
                    </div>
                </div>
            </div>
        </div>
    </form>
</section>
<!-- <app-flyout-po *ngIf="IsAddPartFlyOut"></app-flyout-po> -->
<app-order-part-fly-out *ngIf="IsAddPartFlyOut" [VendorId]="vendorId" [partIds]="selectedids" [CompanyId]="companyId"
    [pageName]="'PurchaseOrder'" (IsSalesOrderFlyOut)="PartFlyOutClose($event)"></app-order-part-fly-out>

<!-- Dialog to Add Vendor  -->
<form [formGroup]="addVendorForm">
    <ng-template #vendorDialog>
        <h2 matDialogTitle class="p-0 vendor-header">New Vendor</h2>
        <mat-dialog-content class="mat-content">
            <div class="row">
                <div class="col-12 first-card-1 mb-2">
                    <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                        <mat-label>Vendor Name</mat-label>
                        <input matInput type="text" placeholder="Name" formControlName="vendorName" required>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8 col-12 first-card mb-2">
                    <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                        <mat-label>Address Line</mat-label>
                        <input matInput type="text" placeholder="Address" formControlName="addressLine1" required>
                    </mat-form-field>
                </div>
                <div class="col-md-4 col-12 fourth-card select-arrow-change-icon mb-2">
                    <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                        <mat-label>Country</mat-label>
                        <mat-select placeholder="Country" formControlName="CountryId"
                            (selectionChange)="getCountryId($event)" required>
                            <mat-option *ngFor="let country of countryList" [value]="country.id">
                                {{country.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-5 col-12 first-card mb-2">
                    <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                        <mat-label>City</mat-label>
                        <input matInput type="text" placeholder="City" formControlName="cityId" required>
                    </mat-form-field>
                </div>
                <div class="col-md-3 col-12 second-card select-arrow-change-icon mb-2">
                    <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                        <mat-label>State</mat-label>
                        <mat-select placeholder="State" formControlName="stateId" required>
                            <mat-option *ngFor="let state of getStateFromCountry" [value]="state.id">
                                <span *ngIf="state.abbreviation.length != 0">{{state.abbreviation}}</span>
                                <span *ngIf="state.abbreviation.length == 0">{{state.name}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-4 col-12 fourth-card mb-2">
                    <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                        <mat-label>ZipCode</mat-label>
                        <input matInput type="text" placeholder="ZipCode" formControlName="zipCode" mask="00000-0000"
                            required>
                    </mat-form-field>
                    <mat-error *ngIf="addVendorForm.get('zipCode').hasError('minlength')">
                        Enter a valid ZipCode!
                    </mat-error>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8 col-12 first-card mb-2">
                    <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput type="text" placeholder="Email" formControlName="email" required>
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="addVendorForm.get('phoneNumber').hasError('email') || addVendorForm.get('phoneNumber').hasError('pattern')">
                        Enter a valid email address
                    </mat-error>
                </div>
                <div class="col-md-4 col-12 fourth-card mb-2">
                    <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                        <mat-label>Phone Number</mat-label>
                        <input matInput type="text" placeholder="Phone Number" formControlName="phoneNumber"
                            mask="0 (000) 000 0000" required>
                    </mat-form-field>
                    <mat-error *ngIf="addVendorForm.get('phoneNumber').hasError('minlength')">
                        Enter a valid Phone Number!
                    </mat-error>
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-dialog-close class="btn cancel-btn update-btn mx-2">Cancel</button>
            <button mat-dialog-close class="save-btn btn update-btn" [disabled]="!addVendorForm.valid"
                (click)="addNewVendor()">Save</button>
        </mat-dialog-actions>
    </ng-template>
</form>


<!-- Sales Discount Modal Start -->
<div class="modal" id="PODiscountModal" style="color: #74777F;">
    <div class="modal-dialog">
        <div class="modal-content change-backg">
            <!-- Modal Header -->
            <div class="modal-header border-0 flex-column">
                <h2 class="sales-heading">Vendor Discounts</h2>
                <span class="sales-discount-para">The discounts available for this product are displayed in the tables
                    below.</span>
            </div>
            <div class="modal-body border-0 pt-0">
                <div class="row mt-2">
                    <div class="col-12 first-column-padding">
                        <h6 class="table-top-heading text-center">Vendor Pricing Discounts</h6>
                        <table class="sales-discount-table">
                            <thead>
                                <tr>
                                    <th>Min Qty</th>
                                    <th>Max Qty</th>
                                    <th>Unit Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let r of discountList">
                                    <td>{{r.min}}</td>
                                    <td>{{r.max != 0 ? r.max : '+'}}</td>
                                    <td>{{r.discountedCost | currency}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <!-- Modal footer -->
            <div class="modal-footer border-0">
                <button class="btn save-btn update-btn" (click)="closePODiscountModal()">Close</button>
            </div>
        </div>
    </div>
</div>
<!-- Sales Discount Modal End -->

<!--Selector for PO View History-->
<app-po-history [IsHistoryFlyOut]="IsHistoryFlyOut" [logList]="logList" (HistoryFlyOut)="hideHistoryFlyOut($event)"
    (GeneratPdfEmit)="GeneratePOPdf($event)"></app-po-history>

<!--Validation Modal start for Vendor & Ship to data-->

<div class="modal" id="POFormValidationModal" style="color: #74777F;">
    <div class="modal-dialog">
        <div class="modal-content change-backg">
            <!-- Modal Header -->
            <div class="modal-header border-0 flex-column">
                <h2 class="sales-heading">{{title}}</h2>
                <span class="sales-discount-para mb-3">To save this order you must save as Draft or complete the following information:</span>
                <span class="disc-list" [innerHtml]="messageList">
                </span>
            </div>
            <!-- Modal footer -->
            <div class="modal-footer border-0">
                <button class="btn cancel-btn update-btn" (click)="closeValidationModal()">Cancel</button>
                <button class="btn save-btn update-btn" (click)="SaveAsDraft()">Save as Draft</button>
            </div>
        </div>
    </div>
</div>
<!--Validation Modal End -->