import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
// import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { DomSanitizer } from "@angular/platform-browser";
import { GlobalChangeService } from '../services/global-change.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.css']
})

export class WidgetComponent implements OnInit {

  /*Search Property*/
  formSearch: FormGroup;
  searchWidget: any;
  TCreateUpdate: any = 1;
  SearchValidation: any;
  SearchControl: boolean = false;
  SearchType: any = false;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  ListAngle: any;
  value_search = 0;
  // config_search = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  userGroupObjects_search = new Array();
  companyList_search: any;
  // items_search: TreeviewItem[];
  widgetList: any;

  /*Form Property*/
  form: any;
  value = 0;
  // config = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  userGroupObjects = new Array();
  companyList: any;

  widgetName: any;
  WQuery = '';
  WQueryDropdown = this.constants.WidgetQuery;
  widgetUrl: any;
  WType = '';
  WTypeArr = this.constants.WidgetType;
  isNewsType: boolean = false;
  validation: boolean = false;
  isWidgetUrl: boolean = false;
  isWidgetQuery: boolean = false;

  /*Datatable Property*/
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  /*Others*/
  loader: boolean = false;
  clearbutton: boolean = true;
  rolePermissionsList: any;
  permissionMessage = '';
  id: number;
  userId: string;
  companyLoginId: any;
  data: any;
  buttonValue: string;
  settingDetailCSS: any;
  settingLogoImage: any;
  createdBy: any;
  createdDate: any;
  deletedItem: string;
  deleteDetail: string;
  createdDateEdit: any;

  //Editer propertise
  name = "ng2-ckeditor";
  ckeConfig: any;
  templateBody: any;
  @ViewChild("myckeditor") ckeditor: any;

  /* Message Modal Properties */
  messageModalImage: any;
  messageModalHeading: any;
  messageModalDescription: any;
  @ViewChild('messageModal', { static: false }) private messageModal;


  constructor(public sharedService: SharedService,
    private constants: ConstantsService,
    private _sanitizer: DomSanitizer,
    private router: Router, 
    private modalService: NgbModal,
    private globalChangeService: GlobalChangeService) {
  }

  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    }
    this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
    this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 1020);
    this.permissionMessage = '';
    if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
      if (!this.rolePermissionsList[0].isView) {
        this.permissionMessage += 'view';
      } if (!this.rolePermissionsList[0].isInsert) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'add';
      } if (!this.rolePermissionsList[0].isUpdate) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'edit';
      } if (!this.rolePermissionsList[0].isDelete) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'delete';
      }
    }
    if (this.permissionMessage != '') {
      this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
    }

    this.buttonValue = this.constants.Save;
    this.userId = localStorage.getItem(this.constants.userId);
    this.companyLoginId = localStorage.getItem(this.constants.companyId);
    // this.getAllCompany(this.companyLoginId);
    // this.getAllCompany_search(this.companyLoginId);

    this.formSearch = new FormGroup({
      'TCreateUpdate': new FormControl(),
      'searchWidget': new FormControl(),
    });

    this.form = new FormGroup({
      'WType': new FormControl(),
      'WQuery': new FormControl(),
      'widgetName': new FormControl(),
      'widgetUrl': new FormControl(),
      'templateBody': new FormControl(),
    });

    //Editor
    this.ckeConfig = {
      extraPlugins:
        "dialogui,dialog,a11yhelp,about,basicstyles,bidi,blockquote,clipboard," +
        "button,panelbutton,panel,floatpanel,colorbutton,colordialog,menu," +
        "contextmenu,dialogadvtab,div,elementspath,enterkey,entities,popup," +
        "filebrowser,find,fakeobjects,floatingspace,listblock,richcombo," +
        "font,format,forms,horizontalrule,htmlwriter,iframe,indent," +
        "indentblock,indentlist,justify,link,list,liststyle,magicline," +
        "maximize,newpage,pagebreak,pastefromword,pastetext,preview,print," +
        "removeformat,resize,menubutton,scayt,selectall,showblocks," +
        "showborders,smiley,sourcearea,specialchar,stylescombo,tab,table," +
        "tabletools,templates,toolbar,undo,wsc,wysiwygarea",
      removeButtons: "About",
      removePlugins: 'elementspath'
    };

    //ankit implementaion
    // get company by default set value
    var company = this.globalChangeService.getGlobalCompany();
    if(company>0){
      this.value = company;
      this.value_search = company;
    }
    else{
      this.ErrorModal('Error', 'Please select company.');
    }

    //on change function 
    this.globalChangeService.dropdownChange$.subscribe((data)=>{
      this.onValueChange(data);
      this.onValueChange_search(data);
    })

    this.dtOptions = {
      pagingType: this.constants.full_numbers,
      pageLength: 10,
      ordering: false
    };
  }

  ChangeWidgetQuery(value) {
    if (value) {
      const arrItem = this.constants.WidgetQuery.filter(x => x.id == value);
      if (arrItem.length > 0) {
        this.WQuery = arrItem[0].id;
        this.widgetUrl = '/dashboardlist/' + arrItem[0].id;
      }
    }
  }

  ChangeWidgetType(value) {
    if (value) {
      this.WType = value;
      if (Number(value) == 1 || Number(value) == 2 || Number(value) == 4 || Number(value) == 5) {
        this.isWidgetQuery = true;
        this.isWidgetUrl = false;
        this.isNewsType = false;
        this.templateBody = '';
        this.widgetUrl = '';
        this.WQuery = '';
      }
      if (Number(value) == 3) {
        this.isWidgetUrl = false;
        this.isWidgetQuery = false;
        this.isNewsType = false;
        this.templateBody = '';
        this.widgetUrl = '';
        this.WQuery = '';
      }
      // if (Number(value) == 4) {
      //   this.isNewsType = true;
      //   this.isWidgetQuery = false;
      //   this.isWidgetUrl = false;
      //   this.templateBody = '';
      //   this.widgetUrl = '';
      //   this.WQuery = '';
      // }
      // else if (Number(value) == 5) {
      //   this.isWidgetUrl = true;
      //   this.isWidgetQuery = false;
      //   this.isNewsType = false;
      //   this.templateBody = '';
      //   this.widgetUrl = '';
      //   this.WQuery = '';
      // }
    }
    else {
      this.isWidgetUrl = false;
      this.isWidgetQuery = false;
      this.isNewsType = false;
      this.templateBody = '';
    }
  }

  ChangeTCreateUpdate(event) {
    this.TCreateUpdate = event.target.value;
    if (this.TCreateUpdate == "1") {
      this.SearchControl = false;
      this.ListShow = false;
      this.ListDiv = false;
      this.SearchValidation = false;
      this.clearbutton = true;
      this.buttonValue = this.constants.Save;
      this.resetWidgetData();
    }
    else if (this.TCreateUpdate == "0") {
      this.SearchControl = true;
      this.searchWidget = "";
    }
  }

  ListDivShowHide() {
    if (this.ListDiv == true) {
      this.ListDiv = false;
      this.ListAngle = this.constants.DownArrow;
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
      this.ListAngle = this.constants.UpArrow;
    }
  }

  searchWidgetF() {
    this.SearchType = false;
    if (!this.searchWidget || this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.GetAllWidget(this.value_search, this.searchWidget);
  }

  AllWidget() {
    this.SearchType = true;
    if (this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.GetAllWidget(this.value_search, '');
  }

  GetAllWidget(companySearch, searchTerm) {
    this.loader = true;
    this.sharedService.GetAllWidget(companySearch, searchTerm).subscribe(
      data => {
        this.ListShow = true;
        this.ListDiv = true;
        this.ListAngle = this.constants.UpArrow;
        this.data = data;
        this.widgetList = this.data.dataList;
        this.loader = false;
        this.rerender();
      }
    )
  }

  getWTypeInList(WType) {
    const WData = this.WTypeArr.filter(item => Number(item.id) == Number(WType));
    if (WData.length > 0) {
      return WData[0].name;
    }
    else {
      return '';
    }
  }

  toggleEdit(item: any) {
    this.ListDiv = false;
    this.ListAngle = this.constants.DownArrow;
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.buttonValue = this.constants.Update;
    this.clearbutton = false;
    this.sharedService.GetWidget(item.id).subscribe(
      (data: any) => {
        this.id = item.id;
        this.value = data.data.companyId;
        this.widgetName = data.data.widgetname;
        this.WQuery = data.data.query_type;
        this.WType = data.data.widgetType;
        this.widgetUrl = data.data.url;

        if (data.data.widgetType) {
          this.ChangeWidgetType(data.data.widgetType);

          switch (Number(data.data.widgetType)) {
            case 1:
              this.WQuery = data.data.query_type;
              break;
            case 2:
              this.widgetUrl = data.data.url;
              break;
            case 4:
              this.templateBody = data.data.newsContent;
              break;
            case 5:
              this.widgetUrl = data.data.url;
              break;
            default:
              break;
          }
        }

        if (data.data.query_type) {
          this.ChangeWidgetQuery(data.data.query_type);
        }

        this.createdDateEdit = data.data.createdDate;
      });
  }

  // getAllCompany_search(companyId) {
  //   this.sharedService.GetDropdownCompanyHierarchyModel(this.companyLoginId).subscribe(
  //     data => {
  //       this.data = data;
  //       this.items_search = this.data.dataList;
  //       this.companyList_search = this.data.dataList;
  //       this.companyList_search.forEach((x) => {
  //         this.userGroupObjects_search.push(new TreeviewItem(x, false));
  //       });
  //       this.value_search = Number(companyId);
  //     });
  // }

  onValueChange_search(value: any): void {
    this.value_search = value;
  }

  // getAllCompany(companyId) {
  //   this.sharedService.GetDropdownCompanyHierarchyModel(companyId).subscribe(
  //     data => {
  //       this.data = data;
  //       if (this.data.dataList != null && this.data.dataList != undefined) {
  //         this.companyList = this.data.dataList;
  //         this.companyList.forEach((x) => {
  //           this.userGroupObjects.push(new TreeviewItem(x, false));
  //         });
  //       }
  //       this.value = Number(companyId);
  //     });
  // }

  onValueChange(value: any): void {
    this.value = value;
  }

  addWidget() {
    this.validation = true;
    this.clearbutton = true;
    if (
      this.value === 0 ||
      this.form.controls.widgetName.status === this.constants.Invalid ||
      this.form.controls.WType.status === this.constants.Invalid) {
      return;
    }
    this.loader = true;
    const obj = {};
    obj['CompanyId'] = Number(this.value);
    obj['Widgetname'] = this.widgetName;
    obj['Query_type'] = this.WQuery;
    obj['widgettype'] = Number(this.WType);
    obj['Url'] = this.widgetUrl;
    obj['NewsContent'] = this.templateBody;
    obj['IsActive'] = true;
    if (this.id != undefined && !Number.isNaN(this.id) && this.id != 0) {
      obj['Id'] = this.id;
      obj['ModifiedBy'] = this.userId;
      obj['CreatedDate'] = this.createdDateEdit;
      this.sharedService.UpdateWidget(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.buttonValue = this.constants.Save;
                this.validation = false;
                this.modalSuccess(this.constants.RecordUpdateSuccessfully);
                this.id = null;
                this.TCreateUpdate = 1;
                this.SearchControl = false;
                this.ListShow = false;
                this.ListDiv = false;
                this.resetWidgetData();
                this.rerender();
                break;
              case 400:
                this.modalError(this.constants.SomethingWentWrong);
                break;
              default:
                this.modalError(this.constants.SomethingWentWrong);
            }
            this.loader = false;
          }
        );
    }
    else {
      obj['CreatedBy'] = this.userId;
      obj['CreatedDate'] = new Date();
      this.sharedService.AddWidget(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.modalSuccess(this.constants.RecordAddedSuccessfully);
                this.validation = false;
                this.TCreateUpdate = 1;
                this.SearchControl = false;
                this.ListShow = false;
                this.ListDiv = false;
                this.resetWidgetData();
                this.rerender();
                break;
              case 400:
                this.modalError(this.constants.SomethingWentWrong);
                break;
              case 208:
                this.modalError(this.constants.WidgetNameExist);
                break;
              default:
                this.modalError(this.constants.SomethingWentWrong);
            }
            this.loader = false;
          }
        );
    }
  }

  resetWidgetData() {
    this.isWidgetUrl = false;
    this.isWidgetQuery = false;
    this.isNewsType = false;
    this.id = 0;
    this.buttonValue = this.constants.Save;
    this.widgetName = '';
    this.WQuery = '';
    this.widgetUrl = '';
    this.WType = '';
    this.templateBody = '';
  }

  widgetback() {
    this.clearbutton = true;
    this.id = null;
    this.buttonValue = this.constants.Save;
    this.TCreateUpdate = 1;
    this.SearchControl = false;
    this.ListShow = false;
    this.ListDiv = false;
    this.resetWidgetData();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next('');
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next('');
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
  }


  DeleteConfirm(item, content) {

    this.deletedItem = '';
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = this.constants.DeletePopup;
    this.deletedItem = item;
  }


  DeleteWidget(item: any) {
    this.loader = true;
    this.sharedService.deleteWidget(item.id).subscribe(
      data => {
        this.data = data;
        switch (data.statusCode) {
          case 200:
            this.modalSuccess(this.constants.RecordDeletedSuccessfully);
            this.GetAllWidget(this.value_search, this.searchWidget == "" ? "" : this.searchWidget);
            this.rerender();
            break;
          case 400:
            this.modalError(this.constants.SomethingWentWrong);
            break;
          default:
            this.modalError(this.constants.SomethingWentWrong);
        }
        this.loader = false;
      });
  }


  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
        $(".blue-bg").css("background", settingCSS.split(',')[1]);
      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
        $(".darkred-bg").css("background", settingCSS.split(',')[2]);
      }
    }

    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }
  }

  modalSuccess(msg) {
    this.messageModalImage = this.constants.SuccessPng;
    this.messageModalDescription = msg;
    this.modalService.dismissAll();
    this.modalService.open(this.messageModal, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
  }

  modalError(msg) {
    this.messageModalImage = this.constants.ClosePng;
    this.messageModalDescription = msg;
    this.modalService.dismissAll();
    this.modalService.open(this.messageModal, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  //ankit implemenation
  ErrorModal(title:string, message:string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
}