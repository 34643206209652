import {  Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
// import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { DomSanitizer } from "@angular/platform-browser";
import { GlobalChangeService } from '../services/global-change.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css']
})


export class TemplateComponent implements OnInit {
  userGroupObjects = new Array();
  companyList: any;
  data: any;
  value = 0;
  companyLoginId: any;
  form: any;
  isvisitcheck: boolean = false;
  templatvisititem = [];
  TCreateUpdate: any = 1;
  ProductSearch: any = "";
  SearchValidation: any;
  SearchControl: boolean = false;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  ListAngle: any;
  formSearch: FormGroup;
  companyList_search: any;
  // items_search: TreeviewItem[];
  value_search = 0;
  userGroupObjects_search = new Array();
  // config = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  // config_search = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  loader = false;
  alltemplatItem: any;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject<any>();
  rolePermissionsList: any;
  permissionMessage = '';
  buttonValue: string;
  userId: any;
  dtOptions: DataTables.Settings = {};
  SearchType: any;
  TemplateSearch: any;
  settingDetailCSS: any;
  settingLogoImage: any;
  allWidgetItem: any;
  allWidgetWithType = [];
  recordAlreadyExist = false;
  validation: boolean = false;
  TempDataITem = [];
  templateName: any;
  now: Date;
  isCheckItem: boolean = false;
  deletedItem: string;
  deleteDetail: string;
  ProductionData = [];
  alert = [];
  TraingDocument = [];
  new = [];
  vedio = [];
  id: any;
  EditWidget = [];
  templateId: any;
  widget1: any;

  constructor(
    public sharedService: SharedService, 
    private constants: ConstantsService, 
    private router: Router, 
    private modalService: NgbModal, 
    private _sanitizer: DomSanitizer,
    private globalChangeService: GlobalChangeService) {
  }

  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
      this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 1019);
      this.permissionMessage = '';
      if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
        if (!this.rolePermissionsList[0].isView) {
          this.permissionMessage += 'view';
        } if (!this.rolePermissionsList[0].isInsert) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'add';
        } if (!this.rolePermissionsList[0].isUpdate) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'edit';
        } if (!this.rolePermissionsList[0].isDelete) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'delete';
        }
      }

      if (this.permissionMessage != '') {
        this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
      }
      this.buttonValue = this.constants.Save;
      this.userId = localStorage.getItem(this.constants.userId);
      this.companyLoginId = localStorage.getItem(this.constants.companyId);
      // this.getAllCompany_search(this.companyLoginId);
      // this.getAllCompany(this.companyLoginId);
      // this.getWidgetItem(this.companyLoginId);
      this.dtOptions = {
        pagingType: this.constants.full_numbers,
        pageLength: 10
      };

      //ankit implementaion
      // get company by default set value
      var company = this.globalChangeService.getGlobalCompany();
      if(company>0){
        this.value = company;
        this.value_search = company;

        this.getWidgetItem(company);
      }
      else{
        this.ErrorModal('Error', 'Please select company.');
      }

      //on change function 
      this.globalChangeService.dropdownChange$.subscribe((data)=>{
        this.onValueChange(data);
        this.onValueChange_search(data);
      })
    }

    this.companyLoginId = localStorage.getItem(this.constants.companyId);
    this.widget1 = this.constants.Widget1;

    this.form = new FormGroup({
      'templateName': new FormControl(),
    });
    this.formSearch = new FormGroup({
      'TCreateUpdate': new FormControl(),
      'template': new FormControl(),
      'Widgetname': new FormControl(),
      'TemplateSearch': new FormControl(),
    });
  }

  onValueChange(value: any): void {
    this.value = value;
    this.getWidgetItem(this.value);
  }

  isVisitCheck($event, id) {
    const index = this.allWidgetItem.findIndex(x => x.id === id);
    let widgetitem = this.allWidgetItem.filter(x => x.id == id);
    if ($event.target.checked === true) {
      this.isCheckItem = $event.target.checked;
      let obj = {
        widgetname: widgetitem[0].widgetname,
        id: widgetitem[0].id,
        query_type: widgetitem[0].query_type,
        isCheckItem: this.isCheckItem,
        newscontent: this._sanitizer.bypassSecurityTrustHtml(widgetitem[0].newsContent),
        url: this._sanitizer.bypassSecurityTrustResourceUrl(widgetitem[0].url),

      }
      if (widgetitem[0].widgetType == 1) {
        if (this.ProductionData.length <= 3) {
          this.ProductionData.push(obj);
          this.templatvisititem.push(obj);
        }
        else {
          $("#Widget").show();
          this.widget1 = this.constants.Widget1;
        }
      }
      else if (widgetitem[0].widgetType == 3) {
        if (this.alert.length < 1) {
          this.alert.push(obj);
          this.templatvisititem.push(obj);
        }
        else {
          $("#Widget").show();
          obj.isCheckItem = false;
          this.widget1 = this.constants.Widget1;
        }
      }
      else if (widgetitem[0].widgetType == 2) {
        if (this.TraingDocument.length <= 3) {
          this.TraingDocument.push(obj);
          this.templatvisititem.push(obj);

        }
        else {
          $("#Widget").show();
          this.widget1 = this.constants.Widget1;
        }
      }
      else if (widgetitem[0].widgetType == 4) {
        if (this.new.length <= 3) {
          this.new.push(obj);
          this.templatvisititem.push(obj);
        }
        else {
          $("#Widget").show();
          this.widget1 = this.constants.Widget1;
        }
      }
      else if (widgetitem[0].widgetType == 5) {
        if (this.vedio.length <= 3) {
          this.vedio.push(obj);
          this.templatvisititem.push(obj);
        }
        else {
          $("#Widget").show();
          this.widget1 = this.constants.Widget1;
        }
      }
    }
    else if ($event.target.checked === false) {
      this.ProductionData = this.ProductionData.filter(x => x.id != id);
      this.alert = this.alert.filter(x => x.id != id);
      this.TraingDocument = this.TraingDocument.filter(x => x.id != id);
      this.new = this.new.filter(x => x.id != id);
      this.vedio = this.vedio.filter(x => x.id != id);
      this.templatvisititem = this.templatvisititem.filter(x => x.id != id);

    }
  }

  // getAllCompany(companyId) {
  //   this.loader = true;
  //   this.sharedService.GetDropdownCompanyHierarchyModel(companyId).subscribe(
  //     data => {
  //       this.data = data;
  //       if (this.data.dataList != null && this.data.dataList != undefined) {
  //         this.companyList = this.data.dataList;
  //         this.companyList.forEach((x) => {
  //           this.userGroupObjects.push(new TreeviewItem(x, false));
  //         });
  //       }
  //       this.value = Number(companyId);
  //       this.loader = false;
  //     });
  // }


  // getAllCompany_search(companyId) {
  //   this.loader = true;
  //   this.sharedService.GetDropdownCompanyHierarchyModel(this.companyLoginId).subscribe(
  //     data => {
  //       this.data = data;
  //       this.items_search = this.data.dataList;
  //       this.companyList_search = this.data.dataList;
  //       this.companyList_search.forEach((x) => {
  //         this.userGroupObjects_search.push(new TreeviewItem(x, false));
  //       });
  //       this.value_search = Number(companyId);
  //       this.loader = false;
  //     });
  // }

  ChangeTCreateUpdate(event) {
    this.TCreateUpdate = event.target.value;
    if (this.TCreateUpdate == "1") {
      this.SearchControl = false;
      this.ListShow = false;
      this.ListDiv = false;
      this.SearchValidation = false;
      this.ClearModel();
      this.getWidgetItem(this.value);
    }
    else if (this.TCreateUpdate == "0") {
      this.SearchControl = true;
      this.ProductSearch = "";
    }
  }

  getAllTemplate(companySearch, searchTerm) {
    this.loader = true;
    this.sharedService.getAllTemplate(companySearch, searchTerm).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList != null && this.data.dataList != undefined) {
          this.alltemplatItem = this.data.dataList;
        }
        this.ListAngle = this.constants.UpArrow;
        this.loader = false;
        this.rerender();
      });
  }

  AllTemaplteItem() {
    this.SearchType = true;
    if (this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.ProductSearch = '';
    this.SearchValidation = false;
    this.ListShow = true;
    this.ListDiv = true;
    this.getAllTemplate(this.value_search, this.ProductSearch);
  }


  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next('');
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next('');
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ListDivShowHide() {
    if (this.ListDiv == true) {
      this.ListDiv = false;
      this.ListAngle = this.constants.DownArrow;
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
      this.ListAngle = this.constants.UpArrow;
    }
  }

  searchTemplate() {
    this.SearchType = false;
    if (!this.TemplateSearch || this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.ListShow = true;
    this.ListDiv = true;
    this.getAllTemplate(this.value_search, this.TemplateSearch);
  }

  getWidgetItem(companySearch) {
    this.loader = true;
    companySearch = Number(companySearch);
    this.sharedService.getAllWidgetItem(companySearch).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList != null && this.data.dataList != undefined) {
          if (this.data.dataList) {
            this.allWidgetItem = this.data.dataList;
            this.allWidgetWithType = [];
            let widgetType = [...new Set(this.allWidgetItem.map(item => item.widgetType))].sort();
            widgetType.forEach(element => {
              let obj = {
                widgetType: this.constants.WidgetType.find(x => x.id == element).name,
                widgetList: this.allWidgetItem.filter(x => x.widgetType == element)
              }
              this.allWidgetWithType.push(obj);
            });
          }
        }
        else {

        }
        this.loader = false;
      });
  }

  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
      }
    }
    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }
  }

  AddTemplate() {
    this.validation = true;
    this.recordAlreadyExist = false;
    if (this.templateName === undefined ||
      this.templateName === "" ||
      this.templateName === null ||
      this.value === 0
    ) {
      return;
    }

    if (this.templatvisititem.length == 0) {
      $('#blankTemplate').show();
      return;
    }

    let objTemItem = {};
    objTemItem['TemplateName'] = this.templateName;
    objTemItem['CompanyId'] = Number(this.value);
    objTemItem["IsActive"] = true;
    let templatITem = this.templatvisititem;

    for (var j = 0; j < this.templatvisititem.length; j++) {
      let tempOjt = {};
      tempOjt["WidgetId"] = Number(templatITem[j].id);
      tempOjt["IsChecked"] = templatITem[j].isCheckItem;
      tempOjt["CreatedBy"] = this.userId;
      tempOjt["IsActive"] = true;
      tempOjt['CreatedDate'] = this.now = new Date();
      tempOjt['ModifiedBy'] = this.userId;
      tempOjt['ModifiedDate'] = this.now = new Date();
      tempOjt["TemplateId"] = this.templateId;

      this.TempDataITem.push(tempOjt);
    }

    objTemItem["WidgetTemplate"] = this.TempDataITem;
    objTemItem['CreatedBy'] = this.userId;
    objTemItem['CreatedDate'] = this.now = new Date();
    objTemItem['ModifiedBy'] = this.userId;
    objTemItem['ModifiedDate'] = this.now = new Date();
    this.loader = true;
    if (this.templateId != undefined && !Number.isNaN(this.templateId)) {
      objTemItem['Id'] = this.templateId;
      objTemItem['ModifiedBy'] = this.userId;
      objTemItem['CreatedBy'] = this.userId;
      this.sharedService.UpdateTemplate(objTemItem)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.buttonValue = this.constants.Save;
                $("#add_update").show();
                this.validation = false;
                this.ClearModel();
                this.getWidgetItem(this.value);
                this.TCreateUpdate = 1;
                this.SearchControl = false;
                this.ListShow = false;
                this.ListDiv = false;
                this.loader = false;
                this.id = null;
                break;
              case 400:
                $("#wrong_msg").show();
                break;
              default:
                $("#wrong_msg").show();
            }
            this.loader = false;
          }
        );
    }
    else {
      this.sharedService.AddTemplateItem(objTemItem)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.recordAlreadyExist = false;
                $("#add_success").show();
                this.validation = false;
                this.ClearModel();
                this.getWidgetItem(this.value);
                this.TCreateUpdate = 1;
                this.SearchControl = false;
                this.ListShow = false;
                this.ListDiv = false;
                break;
              case 400:
                if (data.errorMessage === 'Record Already Exist') {
                  this.recordAlreadyExist = true;
                  $("#templateexiste").show();
                } else {
                  $("#wrong_msg").show();
                }
                break;
              default:
                $("#wrong_msg").show();
            }
            this.loader = false;
          }
        );
    }
  }

  ClearModel() {
    this.templateId = null;
    this.templateName = '';
    this.value = 0;
    this.recordAlreadyExist = false;
    this.templatvisititem = [];
    this.ProductionData = [];
    this.alert = [];
    this.TraingDocument = [];
    this.new = [];
    this.vedio = [];
    this.allWidgetItem = [];
    this.TempDataITem = [];
    this.buttonValue = this.constants.Save;
    if (this.companyLoginId > 0) {
      this.value = Number(this.companyLoginId)
    }
    else {
      this.value = 0;
    }
    this.allWidgetWithType = [];
  }

  onValueChange_search(value: any): void {
    this.value_search = value;
  }

  deletePopUp(item, content) {
    this.deletedItem = '';
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = this.constants.DeletePopup;
    this.deletedItem = item;
  }

  deleteTemplate(item: any) {
    this.sharedService.deleteTemplate(item.templateid).subscribe(
      data => {
        this.data = data;
        switch (data.statusCode) {
          case 200:
            $("#delete_success").show();
            this.getAllTemplate(this.value_search, this.ProductSearch == "" ? "" : this.ProductSearch);
            this.rerender();
            break;
          case 400:
            $("#wrong_msg").show();
            break;
          default:
            $("#wrong_msg").show();
        }
      });
  }

  addsuccess() {
    $("#add_success").hide();
  }

  updateitem() {
    $("#add_update").hide();
  }

  deleteitem() {
    $("#delete_success").hide();
  }

  msgitem() {
    $("#wrong_msg").hide();
  }

  templateexiste() {
    $("#templateexiste").hide();
  }

  templateEdit(item: any) {
    this.ListDiv = false;
    this.ListAngle = this.constants.DownArrow;
    this.allWidgetItem = [];
    this.ProductionData = [];
    this.buttonValue = this.constants.Update;
    this.templateId = item.templateid;
    this.sharedService.GetEditTemplateItem(item.templateid).subscribe(
      (data: any) => {
        this.value = data.data.companyId;
        this.templateName = data.data.templateName;
        let companySearch = Number(this.value);
        this.sharedService.getAllWidgetItem(companySearch).subscribe(
          data1 => {

            this.data = data1;
            if (this.data.dataList != null && this.data.dataList != undefined) {
              this.allWidgetItem = this.data.dataList;
              this.allWidgetWithType = [];
              let widgetType = [...new Set(this.allWidgetItem.map(item => item.widgetType))].sort();
              widgetType.forEach(element => {
                let obj = {
                  widgetType: this.constants.WidgetType.find(x => x.id == element).name,
                  widgetList: this.allWidgetItem.filter(x => x.widgetType == element)
                }
                this.allWidgetWithType.push(obj);
              });
            }

            this.templatvisititem = [];
            this.ProductionData = [];
            this.alert = [];
            this.TraingDocument = [];
            this.new = [];
            this.vedio = [];

            for (var temp = 0; temp < data.data.widgetMaster.length; temp++) {

              let objtemp = {
                widgetname: data.data.widgetMaster[temp].widgetname,
                isCheckItem: data.data.widgetMaster[temp].isChecked,
                query_type: data.data.widgetMaster[temp].query_type,
                id: data.data.widgetMaster[temp].id,
                TemplateId: data.data.widgetMaster[temp].templateId,
                newscontent: this._sanitizer.bypassSecurityTrustHtml(data.data.widgetMaster[temp].newsContent),
                url: this._sanitizer.bypassSecurityTrustResourceUrl(data.data.widgetMaster[temp].url),
              }
              let itemIndex = this.allWidgetItem.findIndex(item => item.id == objtemp.id);
              this.allWidgetItem[itemIndex].isCheckItem = objtemp.isCheckItem;
              if (data.data.widgetMaster[temp].widgetType == 1) {
                if (this.ProductionData.length <= 3) {
                  this.ProductionData.push(objtemp);
                  this.templatvisititem.push(objtemp);
                }
              }
              else if (data.data.widgetMaster[temp].widgetType == 3) {
                if (this.alert.length <= 1) {
                  this.alert.push(objtemp);
                  this.templatvisititem.push(objtemp);
                }
              }
              else if (data.data.widgetMaster[temp].widgetType == 2) {
                if (this.TraingDocument.length <= 4) {
                  this.TraingDocument.push(objtemp);
                  this.templatvisititem.push(objtemp);

                }
              }
              else if (data.data.widgetMaster[temp].widgetType == 4) {
                if (this.new.length <= 3) {
                  this.new.push(objtemp);
                  this.templatvisititem.push(objtemp);
                }
              }
              else if (data.data.widgetMaster[temp].widgetType == 5) {
                if (this.vedio.length <= 3) {
                  this.vedio.push(objtemp);
                  this.templatvisititem.push(objtemp);
                }

              }

            }
          });

      });
  }

  Widgettype1Close() {
    $("#Widget").hide();
  }

  blankTemplateClose() {
    $('#blankTemplate').hide();
  }

  exitTemplate() {
    this.router.navigate(['/adminDashboard/']);
  }

  //ankit implemenation
  ErrorModal(title:string, message:string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
}
