<section class="content-section-2" id="new-layout" *ngIf="isputAway">
    <div class="top-bredcumps-second">
        <div class="col-12 p-0">
            <div class="loading lodingtext" *ngIf="loader">
                <!-- <p>{{lodingtext}}</p> -->
            </div>
            <!-- --------------top bredcumps css here --------- -->
            <div class="top-bredcumps">
                <div class="cstmbreadcum paddingmng-right put-away-bread">
                    <div class="page_title_cstms">
                        <ul class="link_current">
                            <li>
                                <span>Put Away List View</span>
                            </li>
                        </ul>
                    </div>
                    <div class="top-button d-flex justify-content-end align-items-center gap-3">
                        <button class="save-button" mat-button type="submit" (click)="createReplenishment()">
                            Create Replenishment
                        </button>
                    </div>
                </div>
            </div>
            <!-- --------------top bredcumps css here --------- -->
        </div>
    </div>
    <!-- ---------------------------------------- -->
    <div class="content-section-1">
        <div class="container-fluid">
            <div class="main-section main-section-second">
                <!-- -----------------------selected section--------------------- -->
                <div class="top-selected d-flex justify-content-between align-items-center">
                    <div class="left-side">
                        <mat-checkbox [disabled]="selectedId.length == 0" [checked]="selected"
                            (change)="showSelected($event.checked)">
                            <span *ngIf="selectedId.length > 0"> View Selected</span>
                        </mat-checkbox>
                    </div>
                    <div class="middle-side">
                        <div class="search_filter-alltype margin-auto">
                            <select  (change)=GetActiveStatus($event)>
                                <option *ngFor="let item of isActivePA" [value]="item.id">
                                        {{item.name}}
                                    </option>
                            </select>
                            <input type="text" class="searchalltype" [(ngModel)]="searchText" #input
                                placeholder="Search by Part Number or Description" (input)="onSearch()">
                            <button class="btnsearch_alltype" type="button" (click)="onSearch()">
                                <mat-icon>search</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="right-side">
                        <button class="save-button" mat-button type="submit" [disabled]="showPutAway"
                            (click)="selectPutAway()">
                            <span>Put Away Selected</span>
                        </button>
                    </div>
                </div>
                <!-- -----------------------selected section--------------------- -->
                <!-- --------------------putaway-table section start here---------- -->
                <div class="put-away-table mt-4">
                    <div class="card-desc example-container cstm_scrollbar_theme company-table table-responsive">
                        <ul class="heading-section d-flex justify-content-between">
                            <li class="w-5 select-all-btn" (click)="selectAllData($event)">Select All</li>
                            <li class="w-5">Item</li>
                            <li class="w-8">Part Number</li>
                            <li class="wd-25 text-start">Part Description</li>
                            <li class="w-30">Order Number</li>
                            <li class="w-10">Quantity</li>
                            <li class="w-8">Warehouse</li>
                            <li class="w-10">Location</li>
                            <li class="w-5">Address</li>
                            <li class="w-10">Status</li>
                        </ul>
                        <div *ngIf="!noData">
                            <ul class="value-section d-flex justify-content-between"
                                *ngFor="let item of putAwayList; let i = index;">
                                <li class="w-5" data-label="Select All">
                                    <input matCheckbox [checked]="selectAll" type="checkbox"
                                        (click)="putAwayDetail(item)" />
                                </li>
                                <li class="w-5" data-label="Item">
                                    {{i + 1}}
                                </li>
                                <li class="w-8" data-label="Part Number">
                                    {{item.partNumber}}
                                </li>
                                <li class="wd-25 text-start" data-label="Part Description">
                                    {{item.partName}}
                                </li>
                                <li class="w-30" data-label="Order Number">
                                    {{item.poNumber}}
                                </li>
                                <li class="w-10" data-label="Quantity">
                                    {{item.quantity}}
                                </li>
                                <li class="w-8" data-label="Warehouse">
                                    {{GetWareHouseValue(item.warehouseId)}}
                                </li>
                                <li class="w-10" data-label="Location">
                                    {{GetLocationValue(item.locationName)}}
                                </li>
                                <li class="w-5" data-label="Address">
                                    {{item.location}}
                                </li>
                                <li class="w-10" data-label="Status">
                                    {{GetStatusName(item.statusId)}}
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div *ngIf="noData" class="text-danger" style="text-align: center;">
                        No Record Found !
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- ------------------second section start here------------------------- -->

<section class="content-section-2" id="new-layout" *ngIf="!isputAway && !IsPickList">
    <form [formGroup]="locationForm">
        <!-- ------------top bredcumps start here--------- -->
        <div class="top-bredcumps">
            <div class="cstmbreadcum paddingmng-right">
                <div class="page_title_cstms mt-2">
                    <ul class="link_current">
                        <li>
                            <a class="backbtns">
                                <mat-icon>keyboard_arrow_left</mat-icon>
                                <span (click)="isputAwayList()">Back</span>
                            </a>
                        </li>
                        <li>
                            <span>Put Away Item</span>
                        </li>
                    </ul>
                </div>
                <button class="save-btn update-btn" type="submit" [disabled]="hidePutAwayList"
                    (click)="createPutAway()">
                    <span>Create Put Away List</span>
                </button>
            </div>
        </div>
        <!-- ------------top bredcumps end here--------- -->
        <div class="content-section-1">
            <div class="container-fluid">
                <div class="main-section second-section">
                    <mat-card class="put-away-card" appearance="outlined">
                        <mat-card-content *ngIf="putAwayPart.length>0">
                            <div class="image-section">
                                <img src="{{ConstImgURL}}{{putAwayPart[IsNext].imgPath}}">
                            </div>
                            <div class="about-section mt-2">
                                <h4 class="sku-heading text-center">SKU:{{putAwayPart[IsNext].sku}}</h4>
                                <h3 class="put-away-heading text-center">There are <span
                                        class="part-item">{{putAwayPart[IsNext].items}}
                                        items</span> to Put Away.
                                </h3>
                            </div>
                            <div class="default-location d-flex justify-content-between align-items-center">
                                <div class="left-side mb-2">
                                    <h4>Default Location</h4>
                                </div>
                                <div class="right-side mb-2">
                                    <h4 *ngIf="!DLocation().value[IsNext].isShow && putAwayPart.length>0"
                                        (click)="getLocationLevels(putAwayPart[this.IsNext].location)">
                                        Change</h4>
                                    <div class="row" *ngIf="DLocation().value[IsNext].isShow">
                                        <h4 style="margin-right: 10px;"
                                            (click)="saveDefaultChangeLocation(putAwayPart[this.IsNext].partId,IsNext)">
                                            Save</h4>
                                        <h4 (click)="removeDefaultlocation(IsNext)">
                                            Cancel</h4>
                                    </div>

                                </div>
                            </div>
                            <div *ngIf="!DLocation().value[IsNext].isShow" class="address-section mt-2">
                                <div class="main-address">
                                    <span>Warehouse: {{GetWareHouseValue(putAwayPart[IsNext].wareHouse)}},&nbsp;</span>
                                    <span>Location: {{GetLocationValue(putAwayPart[IsNext].location)}},&nbsp;</span>
                                    <span>Address:{{putAwayPart[IsNext].address}}</span>
                                </div>
                                <div class="on-hand-address">
                                    On Hand:{{putAwayPart[IsNext].onHand}}
                                </div>
                                <div class="location-capacity">
                                    Location Capacity:{{putAwayPart[IsNext].locationCapacity}}
                                </div>
                            </div>
                            <!-- <form [formGroup]="locationForm" (ngSubmit)="onSubmit()"> -->
                            <form [formGroup]="locationForm">
                                <div formArrayName="DLocation">
                                    <div *ngFor="let loc of DLocation(IsNext).controls; let i = index"
                                        [formGroupName]="i">
                                        <div>
                                            <!-- <div *ngIf="this.IsNext == i"> -->
                                            <!-- ---------------- default location section start ----------------- -->
                                            <div *ngIf="DLocation().value[IsNext].isShow"
                                                class="addition-location mt-3">
                                                <!-- <div class="d-flex">
                                                    <div class="col-6 first-card">
                                                        <mat-form-field class="forShipping mt-2 p-0 w-100"
                                                            appearance="outline">
                                                            <mat-label>Warehouse1</mat-label>
                                                            <mat-select formControlName="wareHouse"
                                                                placeholder="Warehouse"
                                                                (selectionChange)="onWareHouseChange($event.value,IsNext)">
                                                                <mat-option *ngFor="let item of CompanyWareHouseList"
                                                                    [value]="item.id">
                                                                    {{ item.name }}</mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-6 fourth-card">
                                                        <mat-form-field class="forShipping mt-2 p-0 w-100"
                                                            appearance="outline">
                                                            <mat-label>Location Name</mat-label>
                                                            <mat-select formControlName="locName"
                                                                placeholder="Location Name"
                                                                (selectionChange)="locNameChange($event.value,IsNext)">
                                                                <mat-option *ngFor="let location of RecLocationList"
                                                                    [value]="location.id">
                                                                    {{ location.locationName }}</mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div>

                                                <div class="d-flex flex-wrap">
                                                    <div class="col-md-3 col-6 first-card">
                                                        <mat-form-field class="forShipping m-0 mt-2 p-0 w-100"
                                                            appearance="outline">
                                                            <mat-label>Aisle</mat-label>
                                                            <mat-select formControlName="aisle" placeholder="Aisle"
                                                                (selectionChange)="onParentloclevelChange(1,$event.value)">
                                                                <mat-option *ngFor="let item of level1List"
                                                                    [value]="item">{{item}}</mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-md-3 col-6 second-card">
                                                        <mat-form-field class="forShipping m-0 mt-2 p-0 w-100"
                                                            appearance="outline">
                                                            <mat-label>Section</mat-label>
                                                            <mat-select formControlName="section" placeholder="Section"
                                                                (selectionChange)="onParentloclevelChange(2,$event.value)">
                                                                <mat-option *ngFor="let item of level2List"
                                                                    [value]="item">{{item}}</mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-md-3 col-6 third-card">
                                                        <mat-form-field class="forShipping m-0 mt-2 p-0 w-100"
                                                            appearance="outline">
                                                            <mat-label>Shelf</mat-label>
                                                            <mat-select formControlName="shelf" placeholder="Shelf"
                                                                (selectionChange)="onParentloclevelChange(3,$event.value)">
                                                                <mat-option *ngFor="let item of level3List"
                                                                    [value]="item">{{item}}</mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-md-3 col-6 fourth-card">
                                                        <mat-form-field class="forShipping m-0 mt-2 p-0 w-100"
                                                            appearance="outline">
                                                            <mat-label>Bin</mat-label>
                                                            <mat-select formControlName="bin" placeholder="Bin"
                                                                (selectionChange)="onParentloclevelChange(4,$event.value)">
                                                                <mat-option *ngFor="let item of level4List"
                                                                    [value]="item">{{item}}</mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div> -->
                                                <!-- Added by Navneet -->
                                                <app-default-location
                                                    *ngIf="LocationSetUp != undefined && LocationSetUp != null"
                                                    [CompanyDetail]="LocationSetUp"
                                                    (CloseDefLocation)="saveDefaultLocation($event)"></app-default-location>
                                                <!-- Ended by Navneet -->

                                                <div class="d-flex">
                                                    <div class="col-6 first-card">
                                                        <mat-form-field class="forShipping mt-2 p-0 w-100"
                                                            appearance="outline">
                                                            <mat-label>On Hand</mat-label>
                                                            <input matInput type="number" maxlength="100" minlength="1"
                                                                formControlName="onhand">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-6 fourth-card">
                                                        <mat-form-field class="forShipping mt-2 p-0 w-100"
                                                            appearance="outline">
                                                            <mat-label>Location Capacity</mat-label>
                                                            <input matInput type="number" maxlength="100" minlength="1"
                                                                formControlName="locCapacity">
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- -------------------- increse and decrese section---------------------- -->
                                            <div class="d-flex justify-content-center my-3 align-items-center">
                                                <button class="btnMinus"
                                                    (click)="AddLocationCount('sub',i,j,false)"><b>-</b></button>
                                                <input matInput class="put-away-input w-25" formControlName="moveQty"
                                                    appNumberOnly (input)="ChangeMoveQty(i,false,0)">
                                                <button class="btnPlus"
                                                    (click)="AddLocationCount('add',i,j,false)"><b>+</b></button>
                                            </div>
                                            <mat-error class="text-danger"
                                                *ngIf="locationForm.get('DLocation')['controls'][i].get('moveQty').hasError('Exceed')">
                                                You can only Put Away {{MaxQty(false,i,0)}} items on this Location.
                                            </mat-error>
                                            <!-- -------------------- increse and decrese section end here---------------------- -->
                                            <!-- -------------slider section start here---------------- -->
                                            <div class="d-flex align-items-center">
                                                <div class="col-1">
                                                    <span (click)="sliderChange(0,i)">0</span>
                                                </div>
                                                <div class="col-md-10 col-9">
                                                    <mat-slider class="custom-mat-slider w-100" min="0"
                                                        max="{{MaxQty(false,i,0)}}" step="1">
                                                        <input type="range"
                                                            [value]="locationForm.get('DLocation')['controls'][i].get('moveQty').value"
                                                            (change)="sliderChange($event.target.value,i)"
                                                            matSliderThumb>
                                                    </mat-slider>
                                                </div>
                                                <div class="col-md-1 col-2">
                                                    <span (click)="sliderChange(MaxQty(false,i,0),i)">
                                                        <!-- {{putAwayPart[IsNext].locationCapacity}} -->
                                                        <!-- {{slidervalue}} -->
                                                        {{MaxQty(false,i,0)}}
                                                    </span>
                                                </div>
                                            </div>
                                            <!-- -------------slider section start here---------------- -->
                                            <!-- --------------------add location section start here------------------- -->
                                            <div formArrayName="AdditionalLocation" class="add-location-section">
                                                <div
                                                    *ngFor="let addLoc of GetDefaultLoaction(i).controls; let j = index">
                                                    <div [formGroupName]="j">
                                                        <div class="additional-location d-flex justify-content-between">
                                                            <div class="left-side mb-2">
                                                                Additional Location {{j + 1}}
                                                            </div>
                                                            <div class="right-side delete-btn mb-2"
                                                                (click)="removeAdditionalocation(i, j)">
                                                                Delete
                                                            </div>
                                                        </div>
                                                        <!-- <div class="d-flex select-arrow-change-icon">
                                                            <div class="col-6 first-card">
                                                                <mat-form-field class="forShipping mt-2 p-0 w-100"
                                                                    appearance="outline">
                                                                    <mat-label>Warehouse</mat-label>
                                                                    <mat-select formControlName="wareHouse"
                                                                        placeholder="Warehouse"
                                                                        (selectionChange)="onChildWareHouseChange($event.value,i,j)">
                                                                        <mat-option
                                                                            *ngFor="let item of CompanyWareHouseList"
                                                                            [value]="item.id">
                                                                            {{ item.name }}</mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-6 fourth-card">
                                                                <mat-form-field class="forShipping mt-2 p-0 w-100"
                                                                    appearance="outline">
                                                                    <mat-label>Location Name</mat-label>
                                                                    <mat-select formControlName="locName"
                                                                        placeholder="Location Name"
                                                                        (selectionChange)="ClocNameChange($event.value,i,j)">
                                                                        <mat-option
                                                                            *ngFor="let location of RecLocationList"
                                                                            [value]="location.id">
                                                                            {{ location.locationName }}</mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex flex-wrap select-arrow-change-icon">
                                                            <div class="col-md-3 col-6 first-card">
                                                                <mat-form-field class="forShipping m-0 mt-2 p-0 w-100"
                                                                    appearance="outline">
                                                                    <mat-label>Aisle</mat-label>
                                                                    <mat-select formControlName="aisle"
                                                                        placeholder="Aisle"
                                                                        (selectionChange)="onloclevelChange(1,$event.value, i, j)">
                                                                        <mat-option
                                                                            *ngFor="let item of locationForm.value.DLocation[i].AdditionalLocation[j].level1List"
                                                                            [value]="item">{{item}}</mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-md-3 col-6 second-card">
                                                                <mat-form-field class="forShipping m-0 mt-2 p-0 w-100"
                                                                    appearance="outline">
                                                                    <mat-label>Section</mat-label>
                                                                    <mat-select formControlName="section"
                                                                        placeholder="Section"
                                                                        (selectionChange)="onloclevelChange(2,$event.value, i, j)">
                                                                        <mat-option
                                                                            *ngFor="let item of locationForm.value.DLocation[i].AdditionalLocation[j].level2List"
                                                                            [value]="item">{{item}}</mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-md-3 col-6 third-card">
                                                                <mat-form-field class="forShipping m-0 mt-2 p-0 w-100"
                                                                    appearance="outline">
                                                                    <mat-label>Shelf</mat-label>
                                                                    <mat-select formControlName="shelf"
                                                                        placeholder="Shelf"
                                                                        (selectionChange)="onloclevelChange(3,$event.value, i, j)">
                                                                        <mat-option
                                                                            *ngFor="let item of locationForm.value.DLocation[i].AdditionalLocation[j].level3List"
                                                                            [value]="item">{{item}}</mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-md-3 col-6 fourth-card">
                                                                <mat-form-field class="forShipping m-0 mt-2 p-0 w-100"
                                                                    appearance="outline">
                                                                    <mat-label>Bin</mat-label>
                                                                    <mat-select formControlName="bin" placeholder="Bin"
                                                                        (selectionChange)="onloclevelChange(4,$event.value, i, j)">
                                                                        <mat-option
                                                                            *ngFor="let item of locationForm.value.DLocation[i].AdditionalLocation[j].level4List"
                                                                            [value]="item">{{item}}</mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </div>
                                                        </div> -->
                                                        <app-default-location
                                                            *ngIf="ChildLocationSetUp != undefined && ChildLocationSetUp != null"
                                                            [CompanyDetail]="ChildLocationSetUp"
                                                            (CloseDefLocation)="saveDefaultLocation($event)"></app-default-location>

                                                        <div class="d-flex">
                                                            <div class="col-6 first-card">
                                                                <mat-form-field class="forShipping mt-2 p-0 w-100"
                                                                    appearance="outline">
                                                                    <mat-label>On Hand</mat-label>
                                                                    <input matInput type="number" maxlength="100"
                                                                        minlength="1" formControlName="onhand">
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-6 fourth-card">
                                                                <mat-form-field class="forShipping mt-2 p-0 w-100"
                                                                    appearance="outline">
                                                                    <mat-label>Location Capacity</mat-label>
                                                                    <input matInput type="number" maxlength="100"
                                                                        minlength="1" formControlName="locCapacity">
                                                                </mat-form-field>
                                                            </div>
                                                        </div>

                                                        <div  *ngIf="ChildLocationSetUp != undefined && ChildLocationSetUp != null"
                                                            class="d-flex justify-content-center mt-3 mb-3 align-items-center">
                                                            <button class="btnMinus"
                                                                (click)="AddLocationCount('sub',i,j,true)"><b>-</b></button>
                                                            <input matInput class="put-away-input w-25" appNumberOnly
                                                                formControlName="moveQty"
                                                                (input)="ChangeMoveQty(i,true,j)">
                                                            <button class="btnPlus"
                                                                (click)="AddLocationCount('add',i,j,true)"><b>+</b></button>
                                                            <mat-error class="text-danger"
                                                                *ngIf="locationForm.get('DLocation')['controls'][i].get('AdditionalLocation')['controls'][j].get('moveQty').hasError('Exceed')">
                                                                 You can only Put Away {{MaxQty(true,i,j)}} items on this Location.
                                                            </mat-error>
                                                        </div>
                                                        <!-- ---------second slider section in additional location---- -->

                                                        <div class="d-flex align-items-center">
                                                            <div class="col-md-1 col-1">
                                                                <span (click)="sliderChangeAddLocation(0,i,j)">0</span>
                                                            </div>
                                                            <div class="col-md-10 col-9">
                                                                <mat-slider class="custom-mat-slider w-100" min="0" max="{{MaxQty(true,i,j)}}">
                                                                    <input type="range"
                                                                        [value]="locationForm.get('DLocation')['controls'][i].get('AdditionalLocation')['controls'][j].get('moveQty').value"
                                                                        (change)="sliderChangeAddLocation($event.target.value,i,j)"
                                                                        matSliderThumb>
                                                                </mat-slider>
                                                            </div>
                                                            <div class="col-md-1">
                                                                <span
                                                                    (click)="sliderChangeAddLocation(MaxQty(true,i,j),i,j)">{{MaxQty(true,i,j)}}</span>
                                                                <!-- <span
                                                                    (click)="sliderChangeAddLocation(100,i,j)">100</span> -->
                                                            </div>
                                                        </div>
                                                        <!-- ---------second slider section in additional location---- -->

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="location-add mt-2"
                                                *ngIf="!IsDisabledLocation() && this.locationForm.value.DLocation != undefined"
                                                (click)="addAdditionalocation(IsNext,loc.value.partId)">
                                                <span class="add-location-btn">
                                                    + Add Location
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </mat-card-content>
                        <mat-card-footer
                            class="putaway-card-footer d-flex justify-content-end align-items-center gap-3">
                            <button mat-button [disabled]="IsNext ==0"
                                [ngClass]="IsNext == 0 ? 'previous-btn': 'save-btn1'"
                                (click)="PreviousItem(IsNext,putAwayPart[IsNext].partId)">
                                < Previous Item </button>
                                    <button class="next-btn save-btn update-btn"
                                        [disabled]="putAwayPart.length-1 == IsNext"
                                        [ngClass]="putAwayPart.length-1 == IsNext ? 'previous-btn': 'next-btn'"
                                        (click)="NextItem(IsNext+1,putAwayPart[IsNext].partId)">
                                        Next Item >
                                    </button>
                        </mat-card-footer>
                    </mat-card>
                </div>
            </div>
        </div>
    </form>
</section>
<!-- ------------------second section end here------------------------- -->
<!-- -------------------------------------------------------------------------------third section start here---------------------------------------------------------------------------------------------------------------- -->

<section class="content-section-2" id="new-layout" *ngIf="IsPickList">
    <!-- Put Away List work & Progress Detail-->
    <!-- ---------------top bredcumps start here---------- -->
    <div class="top-bredcumps">
        <div class="cstmbreadcum paddingmng-right">
            <div class="page_title_cstms mt-2">
                <ul class="link_current">
                    <li>
                        <a class="backbtns">
                            <mat-icon>keyboard_arrow_left</mat-icon>
                            <span (click)="isputAwayListFromProgress()">Back</span>
                        </a>
                    </li>
                    <li *ngIf="!showCongrats">
                        <span>Put Away List</span>
                    </li>
                    <li *ngIf="showCongrats">
                        <span>Home</span>
                    </li>
                    <li *ngIf="showCongrats">
                        <span style="color: #005FAE">Put Away</span>
                    </li>
                    <li *ngIf="showCongrats">
                        <span style="color: #005FAE">Summary</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- ---------------top bredcumps end here---------- -->
    <div class="content-section-1">
        <div class="container-fluid">
            <div class="main-section second-main-section">
                <mat-card class="putaway-list-card p-0 h-100 pickListTitle">
                    <mat-card-header class="mat-header custom-mat-header" *ngIf="!isProgressDetail">
                        <div class="put-awaylist-heading">
                            <span>Put Away List</span>
                        </div>
                        <div class="progress-detail-btn" (click)="GetProgressList()">
                            <span>Progress Details</span>
                        </div>
                    </mat-card-header>

                    <mat-card-header class="mat-header custom-mat-header" *ngIf="isProgressDetail">
                        <div class="put-awaylist-heading">
                            <span>Progress Details</span>
                        </div>
                        <div class="progress-detail-btn" (click)="GetPutList()">
                            <span> Put Away List</span>
                        </div>
                    </mat-card-header>

                    <mat-card-content class="card_cstm-mngsd pickListBody" *ngIf="putAwayPart.length > 0">
                        <!-- <div class="nodata" *ngIf="getMoveQty() > 0">
                        <span>
                            Select Items to Put Away
                        </span>
                    </div> -->
                        <div *ngFor="let item of putAwayArrayList; let k = index;">
                            <div *ngIf="putAwayArrayList.length > 0">
                                <!-- Putaway List Start -->
                                <div class="row putaway-list-product" *ngIf="!isProgressDetail && item.moveQty>0">
                                    <div class="col-md-2 col-6 PickListColPickHeading picklis-putaway-section"
                                        data-bs-toggle="modal" data-bs-target="#picklist_modal">
                                        <span class="picklist-heading text-center">Put Away</span>
                                        <span class="putaway-item-quantity text-center"> {{item.moveQty}}</span>
                                        <b class="PickListColPickValue"></b>
                                    </div>
                                    <div class="col-md-2 col-6 second-col" *ngIf="item.imagePath != null">
                                        <div class="card card-image">
                                            <img class="PickListColImgBorder" src="{{ConstImgURL}}{{item.imagePath}}"
                                                (click)="PickListImagesModal(item.partId)">
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-6 second-col" *ngIf="item.imagePath == null">
                                        <div class="card card-image">
                                            <img src="./assets/image/No-Image.png">
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-8 putaway-product-info">
                                        <div class="right-heading">SKU :{{item.sku}}</div>
                                        <div class="PickListCoDesc"></div>
                                        <div class="location-section">
                                            <div class="row">
                                                <div class="col-7 location-heading">Location
                                                    Capacity:{{item.locCapacity}}
                                                </div>
                                                <div class="PickListCoDesc"></div>
                                            </div>
                                            <div class="row">
                                                <span class="col-7 location-heading">Location:
                                                    {{GetWareHouseValue(item.wareHouse)}}
                                                    {{GetLocationValue(item.locName)}} {{item.address}}</span>
                                                <span class="onhand-section"> On Hand:{{item.onhand}}</span>
                                                <span class="PickListCoDesc "></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-4 d-flex last-column">
                                        <div class="button-dot">
                                            <button mat-icon-button [matMenuTriggerFor]="Childmenu"
                                                #ChildmenuTrigger="matMenuTrigger"
                                                (click)="ChildmenuTrigger.openMenu()">
                                                <mat-icon class="icon_fix cstm-font">more_vert</mat-icon>
                                            </button>
                                            <mat-menu #Childmenu="matMenu" class="putaway-btn">
                                                <button mat-menu-item (click)="createReplenishment(item.partId)">
                                                    <span>Request Replenishment</span>
                                                </button>
                                                <button mat-menu-item (click)="editOnHand(item)">
                                                    <span>Edit Quantity On Hand</span>
                                                </button>
                                                <button mat-menu-item (click)="openLocationCapacityDialog(item)">
                                                    <span>Edit Location Capacity</span>
                                                </button>
                                            </mat-menu>
                                        </div>
                                        <div (click)="openLocationDialog(item,k)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64"
                                                viewBox="0 0 64 64" fill="none">
                                                <g filter="url(#filter0_d_1448_76537)">
                                                    <rect x="2" y="2" width="56" height="56" rx="4" />
                                                    <path
                                                        d="M41.1108 10H38.8884C37.555 10 36.666 10.8889 36.666 12.2223C36.666 13.5556 37.555 14.4445 38.8884 14.4445H41.1108C43.5554 14.4445 45.5555 16.4446 45.5555 18.889V21.3335C45.5555 22.6669 46.4444 23.5558 47.7779 23.5558C49.1113 23.5558 50.0002 22.6669 50.0002 21.3335V18.889C50.0002 14.0001 46 10 41.1108 10Z"
                                                        fill="#43474E" />
                                                    <path
                                                        d="M47.7779 36.666C46.4444 36.666 45.5555 37.5549 45.5555 38.8883V41.1105C45.5555 43.555 43.5554 45.5551 41.1108 45.5551H38.8884C37.555 45.5551 36.666 46.444 36.666 47.7773C36.666 49.1107 37.555 49.9996 38.8884 49.9996H41.1108C46 49.9996 50.0002 45.9995 50.0002 41.1105V38.8883C50.0002 37.5549 49.1113 36.666 47.7779 36.666Z"
                                                        fill="#43474E" />
                                                    <path
                                                        d="M21.1118 45.5551H18.8895C16.4449 45.5551 14.4447 43.555 14.4447 41.1105V38.8883C14.4447 37.5549 13.5558 36.666 12.2224 36.666C10.8889 36.666 10 37.5549 10 38.8883V41.1105C10 45.9995 14.0003 49.9996 18.8895 49.9996H21.1118C22.4453 49.9996 23.3342 49.1107 23.3342 47.7773C23.3342 46.444 22.4453 45.5551 21.1118 45.5551Z"
                                                        fill="#43474E" />
                                                    <path
                                                        d="M12.2224 23.3336C13.5558 23.3336 14.4447 22.4447 14.4447 21.1113V18.889C14.4447 16.4446 16.4449 14.4445 18.8895 14.4445H21.1118C22.4453 14.4445 23.3342 13.5556 23.3342 12.2223C23.3342 10.8889 22.4453 10 21.1118 10H18.8895C14.0003 10 10 14.0001 10 18.889V21.1113C10 22.2224 10.8889 23.3336 12.2224 23.3336Z"
                                                        fill="#43474E" />
                                                    <path
                                                        d="M29.9983 21.1105C29.9983 18.6661 27.9981 16.666 25.5535 16.666H21.1088C18.6642 16.666 16.6641 18.6661 16.6641 21.1105V25.5551C16.6641 27.9995 18.6642 29.9996 21.1088 29.9996H25.5535C27.9981 29.9996 29.9983 27.9995 29.9983 25.5551V21.1105ZM21.1088 25.5551V21.1105H25.5535V25.5551H21.1088Z"
                                                        fill="#43474E" />
                                                    <path
                                                        d="M43.3342 38.889V34.4445C43.3342 32 41.3341 30 38.8895 30H34.4447C32.0001 30 30 32 30 34.4445V38.889C30 41.3335 32.0001 43.3336 34.4447 43.3336H38.8895C41.3341 43.3336 43.3342 41.3335 43.3342 38.889ZM34.4447 34.4445H38.8895V38.889H34.4447V34.4445Z"
                                                        fill="#43474E" />
                                                    <path
                                                        d="M34.4431 27.7773H36.6654C37.9989 27.7773 38.8878 26.8884 38.8878 25.5551V21.1105H41.1102C42.4436 21.1105 43.3325 20.2216 43.3325 18.8883C43.3325 17.5549 42.4436 16.666 41.1102 16.666H36.6654C35.332 16.666 34.4431 17.5549 34.4431 18.8883V23.3328C33.1097 23.3328 32.2207 24.2217 32.2207 25.5551C32.2207 26.8884 33.1097 27.7773 34.4431 27.7773Z"
                                                        fill="#43474E" />
                                                    <path
                                                        d="M25.5535 32.2227H18.8864C17.553 32.2227 16.6641 33.1116 16.6641 34.4449C16.6641 35.7783 17.553 36.6672 18.8864 36.6672H23.3312V41.1117C23.3312 42.4451 24.2201 43.334 25.5535 43.334C26.887 43.334 27.7759 42.4451 27.7759 41.1117V34.4449C27.7759 33.1116 26.887 32.2227 25.5535 32.2227Z"
                                                        fill="#43474E" />
                                                </g>
                                                <defs>
                                                    <filter id="filter0_d_1448_76537" x="0" y="0" width="64" height="64"
                                                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                            result="hardAlpha" />
                                                        <feOffset dx="2" dy="2" />
                                                        <feGaussianBlur stdDeviation="2" />
                                                        <feComposite in2="hardAlpha" operator="out" />
                                                        <feColorMatrix type="matrix"
                                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                                                        <feBlend mode="normal" in2="BackgroundImageFix"
                                                            result="effect1_dropShadow_1448_76537" />
                                                        <feBlend mode="normal" in="SourceGraphic"
                                                            in2="effect1_dropShadow_1448_76537" result="shape" />
                                                    </filter>
                                                </defs>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <!-- Putaway List End -->
                                <!-- Progress List Start -->
                                <div class="row putaway-list-product" *ngIf="isProgressDetail">
                                    <div class="col-md-2 col-6 p-0">
                                        <div class="PickListColPickHeading detail-section picklis-putaway-section"
                                            data-bs-toggle="modal" data-bs-target="#picklist_modal">
                                            <span class="picklist-heading "> Put Away</span>
                                            <span class="putaway-item-quantity-last ">{{item.putAwayQty}} of
                                                {{item.totalQty}}</span>
                                        </div>
                                        <div class="item-quantity">
                                        </div>
                                        <div class="progress-percentage">
                                            <mat-progress-bar mode="determinate"
                                                [value]="item.putAwayQty*100/item.totalQty"
                                                class="progress-bar"></mat-progress-bar>
                                            <span
                                                class="float-left percentage-quantity">{{item.putAwayQty*100/item.totalQty
                                                |
                                                number}}%</span>
                                            <span class="float-right">
                                                <span class="percentage-quantity">100%</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-6 second-col" *ngIf="item.imagePath != null">
                                        <div class="card card-image">
                                            <img class="PickListColImgBorder" src="{{ConstImgURL}}{{item.imagePath}}"
                                                (click)="PickListImagesModal(item.partId)">
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-6 second-col" *ngIf="item.imagePath == null">
                                        <div class="card card-image">
                                            <img src="./assets/image/No-Image.png">
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-8">
                                        <div class="right-heading">SKU :{{item.sku}}</div>
                                        <div class="PickListCoDesc"></div>
                                        <div class="location-section">
                                            <div class="row">
                                                <div class="col-7 location-heading">Location
                                                    Capacity:{{item.locCapacity}}
                                                </div>
                                                <div class="PickListCoDesc"></div>
                                                <div class="col-5 location-heading p-0" *ngIf="isProgressDetail">
                                                    Tracking:{{checkTracking(item.trackingId)}}</div>
                                            </div>
                                            <div class="row">
                                                <span class="col-7 location-heading">Location:{{item.address}}</span>
                                                <span class="col-5 onhand-section p-0"> On Hand:{{item.onhand}}</span>
                                                <span class="PickListCoDesc "></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-4 d-flex last-column">
                                        <div class="button-dot">
                                            <button mat-icon-button [matMenuTriggerFor]="Childmenu"
                                                #ChildmenuTrigger="matMenuTrigger"
                                                (click)="ChildmenuTrigger.openMenu()">
                                                <mat-icon class="icon_fix cstm-font">more_vert</mat-icon>
                                            </button>
                                            <mat-menu #Childmenu="matMenu" style="background-color: #74777f;">
                                                <button mat-menu-item (click)="createReplenishment(item.partId)">
                                                    <span>Request Replenishment</span>
                                                </button>
                                                <button mat-menu-item (click)="editOnHand(item)">
                                                    <span>Edit Quantity On Hand</span>
                                                </button>
                                                <button mat-menu-item (click)="openLocationCapacityDialog(item)">
                                                    <span>Edit Location Capacity</span>
                                                </button>
                                            </mat-menu>
                                        </div>
                                    </div>
                                </div>
                                <!-- Progress List End -->
                            </div>
                        </div>

                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>

    <!-- Inventory Loss Start -->
    <div class="overcstfixedsidebar active" *ngIf="showNewTransaction"></div>
    <div class="sidebar_trackingnum active select-arrow-change-icon" *ngIf="showNewTransaction">
        <app-inventory-flyout [flyoutObject]="flyoutObject"
            (closeFlyoutEvent)="CloseTransFlyout($event)"></app-inventory-flyout>
    </div>
    <!-- Inventory Loss End -->

    <!--For location Capacity Start-->
    <ng-template #LocationCapacityModal>
        <div class="mat-dialog-header">
            <h3 class="scan-location-heading">Location Capacity</h3>
        </div>
        <mat-dialog-content>
            <div class="scan-location-para">Please enter the number of parts that can fit in this location:</div>
            <div class="scan-location-modal">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Location Capacity</mat-label>
                    <input matInput type="text" maxlength="100" minlength="3" [(ngModel)]="locationCapacityValue">
                </mat-form-field>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions class="footerbtnswrps footer-btn">
            <button type="button" class="cancel-button" matDialogClose>Cancel</button>
            <button type="submit" class="saveclosebnt" matDialogClose (click)="saveLocationCapacity()">Save</button>
        </mat-dialog-actions>
    </ng-template>
    <!--location Capacity End-->

    <!--Location Address Start-->
    <ng-template #locationModal>
        <form [formGroup]="partLocationForm">
            <div class="mat-dialog-header">
                <h3 class="scan-location-heading">Scan Location Address</h3>
            </div>
            <mat-dialog-content>
                <div class="scan-location-para">
                    <p>Please scan or enter the location ID where the item will be Put Away.</p>
                </div>
                <div class="scan-location-modal">
                    <div class="location-adress-input">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Location Address</mat-label>
                            <!-- <input matInput type="text" [(ngModel)]="PutAwayListModalAddress"> -->
                            <input matInput type="text" formControlName="partLocation" toUppercase
                                (input)="ChangePartLocation()">
                        </mat-form-field>
                        <mat-error class="section-valid"
                            *ngIf="(partLocationForm.get('partLocation').touched || submitted) && partLocationForm.get('partLocation').hasError('AddressError')">
                            Location Address Mismatch.
                        </mat-error>
                    </div>
                    <div class="location-scan">
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 64 64" fill="none">
                            <g filter="url(#filter0_d_1448_76537)">
                                <rect x="2" y="2" width="56" height="56" rx="4" />
                                <path
                                    d="M41.1108 10H38.8884C37.555 10 36.666 10.8889 36.666 12.2223C36.666 13.5556 37.555 14.4445 38.8884 14.4445H41.1108C43.5554 14.4445 45.5555 16.4446 45.5555 18.889V21.3335C45.5555 22.6669 46.4444 23.5558 47.7779 23.5558C49.1113 23.5558 50.0002 22.6669 50.0002 21.3335V18.889C50.0002 14.0001 46 10 41.1108 10Z"
                                    fill="#43474E" />
                                <path
                                    d="M47.7779 36.666C46.4444 36.666 45.5555 37.5549 45.5555 38.8883V41.1105C45.5555 43.555 43.5554 45.5551 41.1108 45.5551H38.8884C37.555 45.5551 36.666 46.444 36.666 47.7773C36.666 49.1107 37.555 49.9996 38.8884 49.9996H41.1108C46 49.9996 50.0002 45.9995 50.0002 41.1105V38.8883C50.0002 37.5549 49.1113 36.666 47.7779 36.666Z"
                                    fill="#43474E" />
                                <path
                                    d="M21.1118 45.5551H18.8895C16.4449 45.5551 14.4447 43.555 14.4447 41.1105V38.8883C14.4447 37.5549 13.5558 36.666 12.2224 36.666C10.8889 36.666 10 37.5549 10 38.8883V41.1105C10 45.9995 14.0003 49.9996 18.8895 49.9996H21.1118C22.4453 49.9996 23.3342 49.1107 23.3342 47.7773C23.3342 46.444 22.4453 45.5551 21.1118 45.5551Z"
                                    fill="#43474E" />
                                <path
                                    d="M12.2224 23.3336C13.5558 23.3336 14.4447 22.4447 14.4447 21.1113V18.889C14.4447 16.4446 16.4449 14.4445 18.8895 14.4445H21.1118C22.4453 14.4445 23.3342 13.5556 23.3342 12.2223C23.3342 10.8889 22.4453 10 21.1118 10H18.8895C14.0003 10 10 14.0001 10 18.889V21.1113C10 22.2224 10.8889 23.3336 12.2224 23.3336Z"
                                    fill="#43474E" />
                                <path
                                    d="M29.9983 21.1105C29.9983 18.6661 27.9981 16.666 25.5535 16.666H21.1088C18.6642 16.666 16.6641 18.6661 16.6641 21.1105V25.5551C16.6641 27.9995 18.6642 29.9996 21.1088 29.9996H25.5535C27.9981 29.9996 29.9983 27.9995 29.9983 25.5551V21.1105ZM21.1088 25.5551V21.1105H25.5535V25.5551H21.1088Z"
                                    fill="#43474E" />
                                <path
                                    d="M43.3342 38.889V34.4445C43.3342 32 41.3341 30 38.8895 30H34.4447C32.0001 30 30 32 30 34.4445V38.889C30 41.3335 32.0001 43.3336 34.4447 43.3336H38.8895C41.3341 43.3336 43.3342 41.3335 43.3342 38.889ZM34.4447 34.4445H38.8895V38.889H34.4447V34.4445Z"
                                    fill="#43474E" />
                                <path
                                    d="M34.4431 27.7773H36.6654C37.9989 27.7773 38.8878 26.8884 38.8878 25.5551V21.1105H41.1102C42.4436 21.1105 43.3325 20.2216 43.3325 18.8883C43.3325 17.5549 42.4436 16.666 41.1102 16.666H36.6654C35.332 16.666 34.4431 17.5549 34.4431 18.8883V23.3328C33.1097 23.3328 32.2207 24.2217 32.2207 25.5551C32.2207 26.8884 33.1097 27.7773 34.4431 27.7773Z"
                                    fill="#43474E" />
                                <path
                                    d="M25.5535 32.2227H18.8864C17.553 32.2227 16.6641 33.1116 16.6641 34.4449C16.6641 35.7783 17.553 36.6672 18.8864 36.6672H23.3312V41.1117C23.3312 42.4451 24.2201 43.334 25.5535 43.334C26.887 43.334 27.7759 42.4451 27.7759 41.1117V34.4449C27.7759 33.1116 26.887 32.2227 25.5535 32.2227Z"
                                    fill="#43474E" />
                            </g>
                            <defs>
                                <filter id="filter0_d_1448_76537" x="0" y="0" width="64" height="64"
                                    filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dx="2" dy="2" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix"
                                        result="effect1_dropShadow_1448_76537" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1448_76537"
                                        result="shape" />
                                </filter>
                            </defs>
                        </svg>
                    </div>
                </div>
            </mat-dialog-content>
            <mat-dialog-actions class="footerbtnswrps footer-btn">
                <button type="button" class="cancel-button" (click)="closeAllDialogs()">Cancel</button>
                <button type="submit" class="saveclosebnt" (click)="openPartNumberDialog()">Continue</button>
            </mat-dialog-actions>
        </form>
    </ng-template>
    <!-- Location Address End-->

    <!--Part Number Popup Start-->
    <ng-template #PartNumberModal>
        <form [formGroup]="partNumberForm">
            <div class="mat-dialog-header">
                <h3 class="scan-location-heading">Enter Part Number</h3>
            </div>
            <mat-dialog-content>
                <div class="scan-location-para">
                    <p>Please scan or enter the Part Number of the items to be Put Away in this location.</p>
                </div>
                <div class="scan-location-modal">
                    <div class="location-adress-input">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Part Number</mat-label>
                            <input matInput type="text" formControlName="partNumber" toUppercase
                                (input)="ChangePartNumber()">
                        </mat-form-field>
                        <mat-error class="section-valid"
                            *ngIf="(partNumberForm.get('partNumber').touched || submitted) && partNumberForm.get('partNumber').hasError('PartNumberError')">
                            Part Number Mismatch.
                        </mat-error>
                    </div>
                    <div class="location-scan">
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 64 64" fill="none">
                            <g filter="url(#filter0_d_1448_76537)">
                                <rect x="2" y="2" width="56" height="56" rx="4" />
                                <path
                                    d="M41.1108 10H38.8884C37.555 10 36.666 10.8889 36.666 12.2223C36.666 13.5556 37.555 14.4445 38.8884 14.4445H41.1108C43.5554 14.4445 45.5555 16.4446 45.5555 18.889V21.3335C45.5555 22.6669 46.4444 23.5558 47.7779 23.5558C49.1113 23.5558 50.0002 22.6669 50.0002 21.3335V18.889C50.0002 14.0001 46 10 41.1108 10Z"
                                    fill="#43474E" />
                                <path
                                    d="M47.7779 36.666C46.4444 36.666 45.5555 37.5549 45.5555 38.8883V41.1105C45.5555 43.555 43.5554 45.5551 41.1108 45.5551H38.8884C37.555 45.5551 36.666 46.444 36.666 47.7773C36.666 49.1107 37.555 49.9996 38.8884 49.9996H41.1108C46 49.9996 50.0002 45.9995 50.0002 41.1105V38.8883C50.0002 37.5549 49.1113 36.666 47.7779 36.666Z"
                                    fill="#43474E" />
                                <path
                                    d="M21.1118 45.5551H18.8895C16.4449 45.5551 14.4447 43.555 14.4447 41.1105V38.8883C14.4447 37.5549 13.5558 36.666 12.2224 36.666C10.8889 36.666 10 37.5549 10 38.8883V41.1105C10 45.9995 14.0003 49.9996 18.8895 49.9996H21.1118C22.4453 49.9996 23.3342 49.1107 23.3342 47.7773C23.3342 46.444 22.4453 45.5551 21.1118 45.5551Z"
                                    fill="#43474E" />
                                <path
                                    d="M12.2224 23.3336C13.5558 23.3336 14.4447 22.4447 14.4447 21.1113V18.889C14.4447 16.4446 16.4449 14.4445 18.8895 14.4445H21.1118C22.4453 14.4445 23.3342 13.5556 23.3342 12.2223C23.3342 10.8889 22.4453 10 21.1118 10H18.8895C14.0003 10 10 14.0001 10 18.889V21.1113C10 22.2224 10.8889 23.3336 12.2224 23.3336Z"
                                    fill="#43474E" />
                                <path
                                    d="M29.9983 21.1105C29.9983 18.6661 27.9981 16.666 25.5535 16.666H21.1088C18.6642 16.666 16.6641 18.6661 16.6641 21.1105V25.5551C16.6641 27.9995 18.6642 29.9996 21.1088 29.9996H25.5535C27.9981 29.9996 29.9983 27.9995 29.9983 25.5551V21.1105ZM21.1088 25.5551V21.1105H25.5535V25.5551H21.1088Z"
                                    fill="#43474E" />
                                <path
                                    d="M43.3342 38.889V34.4445C43.3342 32 41.3341 30 38.8895 30H34.4447C32.0001 30 30 32 30 34.4445V38.889C30 41.3335 32.0001 43.3336 34.4447 43.3336H38.8895C41.3341 43.3336 43.3342 41.3335 43.3342 38.889ZM34.4447 34.4445H38.8895V38.889H34.4447V34.4445Z"
                                    fill="#43474E" />
                                <path
                                    d="M34.4431 27.7773H36.6654C37.9989 27.7773 38.8878 26.8884 38.8878 25.5551V21.1105H41.1102C42.4436 21.1105 43.3325 20.2216 43.3325 18.8883C43.3325 17.5549 42.4436 16.666 41.1102 16.666H36.6654C35.332 16.666 34.4431 17.5549 34.4431 18.8883V23.3328C33.1097 23.3328 32.2207 24.2217 32.2207 25.5551C32.2207 26.8884 33.1097 27.7773 34.4431 27.7773Z"
                                    fill="#43474E" />
                                <path
                                    d="M25.5535 32.2227H18.8864C17.553 32.2227 16.6641 33.1116 16.6641 34.4449C16.6641 35.7783 17.553 36.6672 18.8864 36.6672H23.3312V41.1117C23.3312 42.4451 24.2201 43.334 25.5535 43.334C26.887 43.334 27.7759 42.4451 27.7759 41.1117V34.4449C27.7759 33.1116 26.887 32.2227 25.5535 32.2227Z"
                                    fill="#43474E" />
                            </g>
                            <defs>
                                <filter id="filter0_d_1448_76537" x="0" y="0" width="64" height="64"
                                    filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dx="2" dy="2" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix"
                                        result="effect1_dropShadow_1448_76537" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1448_76537"
                                        result="shape" />
                                </filter>
                            </defs>
                        </svg>
                    </div>
                </div>
            </mat-dialog-content>
            <mat-dialog-actions class="footerbtnswrps footer-btn">
                <button type="button" class="cancel-button" (click)="closeAllDialogs()">Cancel</button>
                <button type="submit" class="saveclosebnt" (click)="openConfirmDialog()">Continue</button>
            </mat-dialog-actions>
        </form>
    </ng-template>
    <!-- Part Number Popup End-->

    <!--Confirm Put Away Start-->
    <ng-template #ConfirmModal>
        <form [formGroup]="confirmForm">
            <div class="mat-dialog-header">
                <h3 class="scan-location-heading">Confirm Put Away Count</h3>
            </div>
            <mat-dialog-content>
                <div class="scan-location-para">
                    <p>Please select the number of items you are putting away in
                        this
                        location.</p>
                </div>

                <div class="row justify-content-center mt-4 mb-4">
                    <button class="btnMinus" (click)="Counter('sub')"><b>-</b></button>
                    <input matInput class="put-away-input w-25" formControlName="moveQty"
                        (input)="slider($event.target.value)" appNumberOnly>
                    <button class="btnPlus" (click)="Counter('add')"><b>+</b></button>
                </div>
                <div class="row">
                    <div class="col-1 commonMargin">
                        <span class="textColor" (click)="slider(0)">0</span>
                    </div>
                    <div class="col-md-9 col-9">
                        <mat-slider class="custom-mat-slider w-100" [disabled]="disabled" max="{{moveQuantity}}" min="0"
                            step="1" [discrete]="thumbLabel" [showTickMarks]="showTicks">
                            <input type="range" [value]="confirmForm.value.moveQty"
                                (change)="slider($event.target.value)" matSliderThumb />
                        </mat-slider>
                    </div>
                    <div class="col-2 commonMargin">
                        <span class="textColor" (click)="slider(moveQuantity)">{{moveQuantity}}</span>
                    </div>
                </div>
            </mat-dialog-content>
            <mat-dialog-actions class="footerbtnswrps footer-btn">
                <button type="button" class="cancel-button" (click)="closeAllDialogs()">Cancel</button>
                <button type="submit" [disabled]="loader" 
                [ngClass]="loader == true ? 'disablebntPA' : 'savebntPA'" (click)="confirmPutAway()">Continue</button>
            </mat-dialog-actions>
        </form>
    </ng-template>
    <!-- Confirm Put Away End-->

    <!-- Serial Flyout Start-->
    <div class="Over-Manual-flyout" *ngIf="isSerialFlyout"></div>
    <div class="serial-flyout" *ngIf="isSerialFlyout">
        <app-serial-lot-flyout [seriallotflyoutObj]="seriallotflyoutObj"
            (closeSerialLotFlyoutEvent)="closeSerialLotFlyout($event)"></app-serial-lot-flyout>
    </div>
    <!-- Serial Flyout End -->


    <!-- Congratulations modal -->
    <section class="content-section-1" *ngIf="showCongrats">
        <div class="loading lodingtext" *ngIf="loader"></div>
        <div class="row page_breadcrumb">
            <div class="col-12">
                <div class="main-section mt-3">
                    <div id="congrats-card" class="put-away-card-2">
                        <div class="congrats-title d-flex justify-content-center align-items-center">
                            <img src="/assets/image/congrats.png" alt="congrats" />
                            <span>Congratulations!</span>
                        </div>
                        <div class="row justify-content-center">
                            <span class="CongratulationTitle">You have completed Putting Away {{sumOfMoveQty}}
                                items.</span>
                        </div>
                        <div class="row justify-content-center">
                            <span class="CongratulationText">Please click the Back to the Put Away List button to
                                prepare
                                another order to be Put Away</span>
                            <span class="CongratulationText">or the proceed to Receiving button to receive another
                                Purchase
                                Order.</span>
                        </div>
                        <div class="row justify-content-center">
                            <button type="button" class="btnProceedToShipping" data-dismiss="modal">
                                <a routerLink="/receiving" class="'proceed-shipping">Proceed to Receiving</a>
                            </button>
                        </div>
                        <div class="row justify-content-center">
                            <button class="row btnBackToDashboard" type="button" (click)="isputAwayList()"
                                data-dismiss="modal">Back
                                to the Put Away List</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- modal end -->

    <!-- pick list image popup -->
    <div *ngIf="pickListImages.length > 0 && ImagesModal">
        <div class="modal-dialog product-image-slider modal fade">
            <div class="modal-content modal-height">
                <div class="modal-body slider-main">
                    <ng-image-slider [images]="pickListImages" [infinite]="true" [autoSlide]="false"
                        [imageSize]="{ width: '100%', height: 'auto' }" [imagePopup]="false"></ng-image-slider>
                </div>
                <div class="modal-footer sweet-footer">
                    <button type="button" class="btn red-bg round-btn cancel-button"
                        (click)="PickListImagesModalClose()" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <!-- -------------picklist image slider bootstrap------------- -->
</section>
<!-- -------------third section end here------------------------ -->