<section class="content-section-2" id="new-layout">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>
    <div class="top-bredcumps">
        <div class="page_title_cstms">
            <ul class="link_current">
                <li>
                    <a class="backbtns" (click)="BackToList()">
                        <mat-icon>keyboard_arrow_left</mat-icon>
                        <span>Back to List</span>
                    </a>
                </li>
                <li>
                    <span>Inventory Details</span>
                </li>
            </ul>
        </div>
    </div>
    <div class="content-section-1">
        <div class="container-fluid">
            <div class="main-section">
                <div class="top-cards">
                    <div class="row">
                        <div class="col-xl-4 col-md-12 col-12 first-card  mb-4 column-width">
                            <mat-card class="Inventory-Details-Card card-bg box-shadow-none">
                                <mat-card-header class="mat-header-inventory">
                                    <mat-card-title>Information</mat-card-title>
                                </mat-card-header>
                                <mat-card-content class=" card-content">
                                    <mat-form-field class="col-12 p-0 mb-3" appearance="outline">
                                        <mat-label>Item Number</mat-label>
                                        <input matInput [(ngModel)]="SKU" type="text" placeholder="Item Number"
                                            [disabled]="true">
                                    </mat-form-field>
                                    <mat-form-field class="col-12 p-0 mb-3" appearance="outline">
                                        <mat-label>Item Description</mat-label>
                                        <input matInput [(ngModel)]="infoItemDescription" type="text"
                                            placeholder="Item Description" [disabled]="true">
                                    </mat-form-field>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div class="col-xl-4 col-md-6 col-12 second-card  mb-4 column-width">
                            <mat-card class="Inventory-Details-Card card-bg box-shadow-none">
                                <mat-card-header class="mat-header-inventory">
                                    <mat-card-title>Quantity</mat-card-title>
                                </mat-card-header>
                                <mat-card-content class=" card-content">
                                    <div class="row">
                                        <div class="col-6 mb-3">
                                            <span class="card-content-left"> On Hand</span>
                                        </div>
                                        <div class="col-6 mb-3">
                                            <span class="card-content-right">{{partOnHand}}</span>
                                        </div>
                                        <div class="col-6 mb-3">
                                            <span class="card-content-left">Available</span>
                                        </div>
                                        <div class="col-6 mb-3">
                                            <span class="card-content-right">{{partAvailable}}</span>
                                        </div>
                                        <div class="col-6 mb-3">
                                            <span class="card-content-left">Committed</span>
                                        </div>
                                        <div class="col-6 mb-3">
                                            <span class="card-content-right">{{partCommitted}}</span>
                                        </div>
                                        <div class="col-6 mb-3">
                                            <span class="card-content-left">Back Ordered</span>
                                        </div>
                                        <div class="col-6 mb-3">
                                            <span class="card-content-right">{{partBackOrdered}}</span>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div class="col-xl-4 col-md-6 col-12 fourth-card  mb-4 column-width-full">
                            <mat-card class="Inventory-Details-Card card-bg box-shadow-none">
                                <mat-card-header class="mat-header-inventory">
                                    <mat-card-title>Allocation</mat-card-title>
                                </mat-card-header>
                                <mat-card-content class=" card-content">
                                    <div class="row">
                                        <div class="col-4 top-heading">Channel</div>
                                        <div class="col-4 top-heading">Available</div>
                                        <div class="col-4 top-heading">Committed</div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-4 channel-heading">Retail</div>
                                        <div class="col-4">--</div>
                                        <div class="col-4">--</div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-4 channel-heading">Wholesale</div>
                                        <div class="col-4">--</div>
                                        <div class="col-4">--</div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-4 channel-heading">Internal</div>
                                        <div class="col-4">--</div>
                                        <div class="col-4">--</div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                </div>
                <div class="warehouse-location-section mb-3">
                    <div class="custom-padding">
                        <mat-card class="card-bg box-shadow-none warehouse-heading-section warehouse-location">
                            <mat-card-content>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-12 p-0 d-flex justify-content-between">
                                    <div class="heading-top">
                                        Default Warehouse Location
                                    </div>
                                    <div>
                                        <a type="button" (click)="editDefaultLocation()">
                                            <h3 class="view-history-btn mb-0">Edit</h3>
                                        </a>
                                    </div>
                                </div>

                                <div class="col-xl-12 col-lg-12 col-md-12 col-12 p-0">
                                    <hr />
                                    <form [formGroup]="locationForm">
                                        <div class="row warehouse-row">
                                            <div class="custom-col-2 select-arrow-change-icon">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label for="wareHouse-id">Warehouse</mat-label>
                                                    <input id="wareHouse-id" matInput formControlName="wareHouseName"
                                                        placeholder="Warehouse" />
                                                </mat-form-field>
                                            </div>
                                            <div class="custom-col-2 select-arrow-change-icon">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label for="location-id">Location Name</mat-label>
                                                    <input id="location-id" matInput formControlName="locationName"
                                                        placeholder="Location Name" />
                                                </mat-form-field>
                                            </div>
                                            <div class="custom-col-2 select-arrow-change-icon">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label
                                                        for="level1">{{seletedLocation.nameOfLevels1}}</mat-label>
                                                    <input id="level1" matInput formControlName="level1"
                                                        placeholder="Level1" />
                                                </mat-form-field>
                                            </div>
                                            <div class="custom-col-2 select-arrow-change-icon">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label
                                                        for="level2">{{seletedLocation.nameOfLevels2}}</mat-label>
                                                    <input id="level2" matInput formControlName="level2"
                                                        placeholder="Level2" />
                                                </mat-form-field>
                                            </div>
                                            <div class="custom-col-2 select-arrow-change-icon">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label
                                                        for="level3">{{seletedLocation.nameOfLevels3}}</mat-label>
                                                    <input id="level3" matInput formControlName="level3"
                                                        placeholder="Level3" />
                                                </mat-form-field>
                                            </div>
                                            <div class="custom-col-2 select-arrow-change-icon">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label
                                                        for="level4">{{seletedLocation.nameOfLevels4}}</mat-label>
                                                    <input id="level4" matInput formControlName="level4"
                                                        placeholder="Level4" />
                                                </mat-form-field>
                                            </div>
                                            <div class="custom-col-2 select-arrow-change-icon">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label for="loc-capacity">Location Capacity</mat-label>
                                                    <input id="loc-capacity" matInput formControlName="locationCapacity"
                                                        placeholder="Location Capacity" [disabled]="true" />
                                                </mat-form-field>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>

                <div class="inventory-section">
                    <div class=" mt-4 ">
                        <div class="d-flex align-items-center justify-content-between mb-2 flex-wrap">
                            <div class="inventory-heading d-flex">
                                <h3 class="mb-0 fw-5">Inventory Details</h3>
                                <div class="view-history-btn">
                                    <a type="button" (click)="ViewHistory('')">
                                        <h3 class="view-history-btn mb-0">View History</h3>
                                    </a>
                                </div>
                            </div>

                            <div class="create-new-btn">
                                <button class="save-button" (click)="OpenTransFlyout(0)">
                                    <span>+ Create New Transaction</span>
                                </button>
                            </div>
                        </div>
                        <div class="table-scroll table-scroll-2">
                            <table mat-table [dataSource]="invListDetails"
                                class="mat-elevation-z8 box-shadow-none inventory-table-new">
                                <ng-container matColumnDef="item">
                                    <th mat-header-cell *matHeaderCellDef> Menu </th>
                                    <td mat-cell *matCellDef="let element" data-label="Menu">
                                        <!-- {{element.id}}  -->
                                        <a type="button" class="green-text"><mat-icon
                                                class=icon_color>more_vert</mat-icon></a>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef></td>
                                </ng-container>
                                <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef> Action </th>
                                    <td mat-cell *matCellDef="let element" data-label="Action">
                                        <div class="position-relative">
                                            <span id="onActionHover" class="action-data">
                                                {{element.actionValue}}
                                            </span>
                                            <span id="onHover" class="action-data">
                                                {{element.description}}
                                            </span>
                                        </div>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef></td>
                                </ng-container>
                                <ng-container matColumnDef="document">
                                    <th mat-header-cell *matHeaderCellDef> Document </th>
                                    <td mat-cell *matCellDef="let element" data-label="Document"> {{element.docNo}}
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef></td>
                                </ng-container>
                                <ng-container matColumnDef="batch">
                                    <th mat-header-cell *matHeaderCellDef> Batch </th>
                                    <td mat-cell *matCellDef="let element" data-label="Batch">
                                        <a type="button" (click)="ViewHistory(element.transID)" class="batch-btn">
                                            {{GetBatch(element.transID)}}</a>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef></td>
                                </ng-container>
                                <ng-container matColumnDef="balance">
                                    <th mat-header-cell *matHeaderCellDef> Balance </th>
                                    <td mat-cell *matCellDef="let element" data-label="Balance"> {{element.balance}}
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef data-label="Balance">Available:
                                        {{partAvailable}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="untiCost">
                                    <th mat-header-cell *matHeaderCellDef>Unit Cost </th>
                                    <td mat-cell *matCellDef="let element" data-label="Unit Cost"> {{element.cost |
                                        currency}} </td>
                                    <td mat-footer-cell *matFooterCellDef data-label="Unit Cost"></td>
                                </ng-container>
                                <ng-container matColumnDef="channel">
                                    <th mat-header-cell *matHeaderCellDef> Channel </th>
                                    <td mat-cell *matCellDef="let element" data-label="Channel"> {{element.channel}}
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef data-label="Channel"></td>
                                </ng-container>
                                <ng-container matColumnDef="availabilty">
                                    <th mat-header-cell *matHeaderCellDef> Availabilty </th>
                                    <td mat-cell *matCellDef="let element" data-label="Availabilty">
                                        {{element.invStatusValue}} </td>
                                    <td mat-footer-cell *matFooterCellDef data-label="Availabilty"></td>
                                </ng-container>
                                <ng-container matColumnDef="warehouse">
                                    <th mat-header-cell *matHeaderCellDef> Warehouse </th>
                                    <td mat-cell *matCellDef="let element" data-label="Warehouse">
                                        {{element.warehouseValue}}
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef data-label="Warehouse"></td>
                                </ng-container>
                                <ng-container matColumnDef="location">
                                    <th mat-header-cell *matHeaderCellDef> Location </th>
                                    <td mat-cell *matCellDef="let element" data-label="Location">
                                        {{element.locationValue}}
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef data-label="Location"></td>
                                </ng-container>
                                <ng-container matColumnDef="address">
                                    <th mat-header-cell *matHeaderCellDef> Address </th>
                                    <td mat-cell *matCellDef="let element" data-label="Address"> {{element.location}}
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef data-label="Address"></td>
                                </ng-container>
                                <ng-container matColumnDef="tracking">
                                    <th mat-header-cell *matHeaderCellDef> Tracking </th>
                                    <td mat-cell *matCellDef="let element" data-label="Tracking">
                                        <ng-container *ngIf="element.outTransID == null">
                                            <a type="button"
                                                (click)="GetSerialLotFlyout(element.id, element.trackingDetails, element.origBalance)"
                                                [style.color]="(element.trackingDetails === 3) ? '#585454' : '#005FAE'">
                                                {{element.trackingValue}}
                                            </a>
                                        </ng-container>
                                        <ng-container *ngIf="element.outTransID != null">
                                            {{element.trackingValue}}
                                        </ng-container>
                                    </td>
                                    <td mat-footer-cell *matFooterCellDef data-label="Tracking"></td>
                                </ng-container>
                                <ng-container matColumnDef="date">
                                    <th mat-header-cell *matHeaderCellDef> Date </th>
                                    <td mat-cell *matCellDef="let element" data-label="Date"> {{element.createdDate |
                                        date:'MM/dd/yyyy'}} </td>
                                    <td mat-footer-cell *matFooterCellDef data-label="Date"></td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="invDetailsColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: invDetailsColumns;"></tr>
                                <tr mat-footer-row *matFooterRowDef="invDetailsColumns"></tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="backorder-details mt-3">
                    <div class=" custom-padding">
                        <div class="d-flex align-items-center justify-content-between mb-2">
                            <div class="top-heading p-0">
                                <h3 class="mb-0 fw-5">Back Order Details</h3>
                            </div>
                        </div>
                        <div class="table-scroll table-scroll-2">
                            <table mat-table [dataSource]="BOListDetails"
                                class="mat-elevation-z8 box-shadow-none mt-0  inventory-table-new">
                                <ng-container matColumnDef="item">
                                    <th mat-header-cell *matHeaderCellDef>Menu</th>
                                    <td mat-cell *matCellDef="let element" data-label="Menu">
                                        <!-- {{element.id}}  -->
                                        <a type="button" class="green-text"><mat-icon
                                                class=icon_color>more_vert</mat-icon></a>
                                    </td>
                                </ng-container>
                                <!-- <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef> Action </th>
                                <td mat-cell *matCellDef="let element" data-label="Action"> {{element.action}} </td>
                            </ng-container> -->
                                <ng-container matColumnDef="podocument">
                                    <th mat-header-cell *matHeaderCellDef>PO Document</th>
                                    <td mat-cell *matCellDef="let element" data-label="Document">{{element.docNo}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="sodocument">
                                    <th mat-header-cell *matHeaderCellDef>SO Document</th>
                                    <td mat-cell *matCellDef="let element" data-label="Document">{{element.soDocNo}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="postatus">
                                    <th mat-header-cell *matHeaderCellDef>PO Status</th>
                                    <td mat-cell *matCellDef="let element" data-label="Balance">{{element.poStatus}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="balance">
                                    <th mat-header-cell *matHeaderCellDef>Balance</th>
                                    <td mat-cell *matCellDef="let element" data-label="Unit Cost">{{element.quantity}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="unitCost">
                                    <th mat-header-cell *matHeaderCellDef>Unit Cost</th>
                                    <td mat-cell *matCellDef="let element" data-label="Unit Cost">{{element.unitPrice |
                                        currency}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="channel">
                                    <th mat-header-cell *matHeaderCellDef>Channel</th>
                                    <td mat-cell *matCellDef="let element" data-label="Channel">{{element.channel}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef>Status</th>
                                    <td mat-cell *matCellDef="let element" data-label="Availabilty">{{element.status}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="warehouse">
                                    <th mat-header-cell *matHeaderCellDef>Warehouse</th>
                                    <td mat-cell *matCellDef="let element" data-label="Warehouse">{{element.wareHouse}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="date">
                                    <th mat-header-cell *matHeaderCellDef>Date</th>
                                    <td mat-cell *matCellDef="let element" data-label="Date">{{element.availableDate |
                                        date:'MM/dd/yyyy'}}
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="BODetailsColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: BODetailsColumns;"></tr>
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell" colspan="7" class="error text-center">
                                        <!-- Available : {{TotalInvetoryBalance}} -->
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="purchase-order-section mt-3">
                    <div class=" custom-padding">
                        <div class="d-flex align-items-center justify-content-between mb-2">
                            <div class="top-heading">
                                <h3 class="mb-0 fw-5 p-0">Purchase Order Details</h3>
                            </div>
                        </div>
                        <div class="table-scroll table-scroll-2">
                            <table mat-table [dataSource]="POListDetails"
                                class="mat-elevation-z8 box-shadow-none mt-0  inventory-table-new">
                                <ng-container matColumnDef="item">
                                    <th mat-header-cell *matHeaderCellDef>Menu</th>
                                    <td mat-cell *matCellDef="let element" data-label="Menu">
                                        <!-- {{element.id}}  -->
                                        <a type="button" class="green-text"><mat-icon
                                                class=icon_color>more_vert</mat-icon></a>
                                    </td>
                                </ng-container>
                                <!-- <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef> Action </th>
                                <td mat-cell *matCellDef="let element" data-label="Action"> {{element.action}} </td>
                            </ng-container> -->
                                <ng-container matColumnDef="podocument">
                                    <th mat-header-cell *matHeaderCellDef>PO Document</th>
                                    <td mat-cell *matCellDef="let element" data-label="Document">{{element.docNo}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="sodocument">
                                    <th mat-header-cell *matHeaderCellDef>SO Document</th>
                                    <td mat-cell *matCellDef="let element" data-label="Document">{{element.soDocNo}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="postatus">
                                    <th mat-header-cell *matHeaderCellDef>PO Status</th>
                                    <td mat-cell *matCellDef="let element" data-label="Balance">{{element.poStatus}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="balance">
                                    <th mat-header-cell *matHeaderCellDef>Balance</th>
                                    <td mat-cell *matCellDef="let element" data-label="Unit Cost">{{element.quantity}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="unitCost">
                                    <th mat-header-cell *matHeaderCellDef>Unit Cost</th>
                                    <td mat-cell *matCellDef="let element" data-label="Unit Cost">{{element.unitPrice |
                                        currency}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="channel">
                                    <th mat-header-cell *matHeaderCellDef>Channel</th>
                                    <td mat-cell *matCellDef="let element" data-label="Channel">{{element.channel}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef>Status</th>
                                    <td mat-cell *matCellDef="let element" data-label="Availabilty">{{element.status}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="warehouse">
                                    <th mat-header-cell *matHeaderCellDef>Warehouse</th>
                                    <td mat-cell *matCellDef="let element" data-label="Warehouse">{{element.wareHouse}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="date">
                                    <th mat-header-cell *matHeaderCellDef>Date</th>
                                    <td mat-cell *matCellDef="let element" data-label="Date">{{element.availableDate |
                                        date:'MM/dd/yyyy'}}
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="PODetailsColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: PODetailsColumns;"></tr>
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell" colspan="7" class="error text-center">
                                        <!-- Available : {{TotalInvetoryBalance}} -->
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- -- Inventory History Start-->
            <div class="overcstfixedsidebar active" *ngIf="showViewHistory"></div>
            <div class="sidebar_trackingnum active card-bg" *ngIf="showViewHistory">
               <app-trans-history-flyout [batchId]="batchId" [SKU]="SKU" [companyId]="companyId" [partId]="partId" [invListDetails]="invListDetails" [invListHistory]="invListHistory" [HBatchList]="HBatchList" [HBatch]="HBatch" (closeEvent)="historyclose($event)"></app-trans-history-flyout>
            </div>
            
            <!-- -- Inventory History End-->

            <!-- Serial Flyout Start-->
            <div class="Over-Manual-flyout" *ngIf="isSerialFlyout"></div>
            <div class="serial-flyout" *ngIf="isSerialFlyout">
                <app-serial-lot-flyout [seriallotflyoutObj]="seriallotflyoutObj"
                    (closeSerialLotFlyoutEvent)="closeSerialLotFlyout($event)"></app-serial-lot-flyout>
            </div>
            <!-- Serial Flyout End -->

            <!-- -- Inventory NewTransaction Flyout Start-->
            <div class="overcstfixedsidebar active" *ngIf="showNewTransaction"></div>
            <div class="sidebar_trackingnum active select-arrow-change-icon" *ngIf="showNewTransaction">
                <app-inventory-flyout [flyoutObject]="flyoutObject"
                    (closeFlyoutEvent)="closeTransFlyout($event)"></app-inventory-flyout>
            </div>
            <!-- -- Inventory NewTransaction End-->
        </div>
    </div>

    <!-- Location Modal Start -->
    <!-- Location Modal Start -->
    <app-default-location *ngIf="LocationSetUp != undefined && LocationSetUp != null" [CompanyDetail]="LocationSetUp"
        (CloseDefLocation)="saveDefaultLocation($event)"></app-default-location>
    <!-- <div class="modal" id="LocationModal" style="color: #74777F;">
        <div class="modal-dialog">
            <div class="modal-content change-backg">
                <div class="modal-header flex-column">
                    <div class="row mt-2">
                        <div class="col-12">
                            <h2>Inventory Location</h2>
                            <span>Please edit the Inventory Location for
                                Default Warehouse Location.</span>
                        </div>
                    </div>
                </div>
                <div class="modal-body pt-0 select-arrow-change-icon">
                    <form [formGroup]="defaulLocationForm">
                        <div class="row">
                            <div class="col-6 first-card mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Warehouse</mat-label>
                                    <mat-select formControlName="wareHouseId" placeholder="Warehouse">
                                        <mat-option *ngFor="let item of CompanyWareHouseList"
                                            [value]="item.id">{{item.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-6 fourth-card mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Location Name</mat-label>
                                    <mat-select formControlName="locationId" placeholder="Location Name"
                                        (selectionChange)="locNameChange($event.value, 1)">
                                        <mat-option *ngFor="let item of InvLocationList"
                                            [value]="item.id">{{item.locationName}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 col-6 first-card mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>{{seletedDefaultLocation.nameOfLevels1}}</mat-label>
                                    <mat-select formControlName="level1" placeholder="Level1">
                                        <mat-option *ngFor="let item of level1List" [value]="item">{{item}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 col-6 second-card mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>{{seletedDefaultLocation.nameOfLevels2}}</mat-label>
                                    <mat-select formControlName="level2" placeholder="Level2">
                                        <mat-option *ngFor="let item of level2List" [value]="item">{{item}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 col-6 third-card mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>{{seletedDefaultLocation.nameOfLevels3}}</mat-label>
                                    <mat-select formControlName="level3" placeholder="Level3">
                                        <mat-option *ngFor="let item of level3List" [value]="item">{{item}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 col-6 fourth-card mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>{{seletedDefaultLocation.nameOfLevels4}}</mat-label>
                                    <mat-select formControlName="level4" placeholder="Level4">
                                        <mat-option *ngFor="let item of level4List" [value]="item">{{item}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Location Capacity</mat-label>
                                    <input matInput formControlName="locationCapacity" placeholder="Location Capacity"
                                        (input)="validateNumber()" />
                                </mat-form-field>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer border-0">
                    <div class="row mt-2">
                        <button type="button" class="cancel-button" (click)="closeLocationModel()">Cancel</button>
                        <button type="button" class="saveclosebnt" (click)="saveDefaultLocation()">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- Location Modal End -->
</section>