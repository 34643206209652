<div class="top-bredcumps">
    <div class="cstmbreadcum paddingmng-right">
        <div class="page_title_cstms">
            <ul class="link_current">
                <li *ngIf="previousPage.length>0">
                    <a class="backbtns" (click)="back()">
                        <mat-icon>keyboard_arrow_left</mat-icon>
                        <span>{{previousPage}}</span>
                    </a>
                </li>
                <li>
                    <span>{{currentPage}}</span>
                </li>
            </ul>
        </div>
        <div class="top-button d-flex justify-content-between align-items-center gap-3">
            <button *ngIf="isCancel" class="btn cancel-btn update-btn" (click)="cancel()">
                Cancel
            </button>
            <button *ngIf="isSave" class="btn save-btn update-btn">
                Save
            </button>
            <button *ngIf="isShare" class="save-btn btn update-btn me-2" [matMenuTriggerFor]="menu">Share</button>
            <mat-menu #menu="matMenu" style="background-color: #74777f;">
                <button mat-menu-item *ngFor="let item of Action" (click)="GetActionName(item.id)">{{item.name}}</button>
            </mat-menu>            
        </div>
    </div>
</div>