<!-- Location Modal Start -->
<form [formGroup]="locationForm">
    <div class="modal" id="LocationModal" style="color: #74777F;">
        <app-loader [loader]="loader"></app-loader>
        <div class="modal-dialog" *ngIf="CompanyDetail.pageName!='InvFlyOut' && CompanyDetail.pageName!='Putaway'">
            <div class="modal-content change-backg">
                <!-- Modal Header -->
                <div class="modal-header flex-column">
                    <div class="row mt-2">
                        <div class="col-12">
                            <h2>{{CompanyDetail.Heading}}</h2>
                            <span>{{CompanyDetail.SubHeading}}</span>
                        </div>
                    </div>
                </div>
                <div class="modal-body pt-0 select-arrow-change-icon">
                    <div class="row">
                        <div class="col-6 first-card mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Warehouse</mat-label>
                                <mat-select placeholder="Warehouse" formControlName="wareHouseId">
                                    <mat-option *ngFor="let item of CompanyWareHouseList"
                                        [value]="item.id">{{item.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6 fourth-card mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Location Name</mat-label>
                                <mat-select formControlName="locationId" placeholder="Location Name"
                                    (selectionChange)="locNameChange($event.value)">
                                    <mat-option *ngFor="let item of InvLocationList"
                                        [value]="item.id">{{item.locationName}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 col-6 first-card mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{seletedDefaultLocation.nameOfLevels1}}</mat-label>
                                <mat-select formControlName="level1" placeholder="Level1"
                                    (selectionChange)="Level2Change()">
                                    <mat-option *ngFor="let item of level1List" [value]="item">{{item}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3 col-6 second-card mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{seletedDefaultLocation.nameOfLevels2}}</mat-label>
                                <mat-select formControlName="level2" placeholder="Level2"
                                    (selectionChange)="Level3Change()">
                                    <mat-option *ngFor="let item of level2List" [value]="item">{{item}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3 col-6 third-card mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{seletedDefaultLocation.nameOfLevels3}}</mat-label>
                                <mat-select formControlName="level3" placeholder="Level3"
                                    (selectionChange)="Level4Change()">
                                    <mat-option *ngFor="let item of level3List" [value]="item">{{item}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3 col-6 fourth-card mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{seletedDefaultLocation.nameOfLevels4}}</mat-label>
                                <mat-select formControlName="level4" placeholder="Level4">
                                    <mat-option *ngFor="let item of level4List" [value]="item">{{item}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-12" *ngIf="CompanyDetail.pageName=='Inv'">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Location Capacity</mat-label>
                                <input matInput formControlName="locationCapacity" placeholder="Location Capacity"
                                    (input)="validateNumber()" />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer border-0">
                    <div class="row mt-2">
                        <button type="button" class="cancel-button" (click)="closeLocationModel(null)">Cancel</button>
                        <button type="button" [disabled]="locationForm.invalid" class="saveclosebnt"
                            (click)="AddDefReceivingLoc()">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 p-0" *ngIf="CompanyDetail.pageName=='InvFlyOut' || CompanyDetail.pageName=='Putaway'">
        <div class="row">
            <div class="col-12 flyout-card">
                <span>{{CompanyDetail.Heading}}</span>
            </div>
            <div class="col-6 first-card mb-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Warehouse</mat-label>
                    <mat-select placeholder="Warehouse" formControlName="wareHouseId">
                        <mat-option *ngFor="let item of CompanyWareHouseList"
                            [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-6 fourth-card mb-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Location Name</mat-label>
                    <mat-select formControlName="locationId" placeholder="Location Name"
                        (selectionChange)="locNameChange($event.value)">
                        <mat-option *ngFor="let item of InvLocationList"
                            [value]="item.id">{{item.locationName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12 flyout-card">
                <span>{{CompanyDetail.SubHeading}}</span>
            </div>
            <div class="col-md-3 col-6 first-card mb-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{seletedDefaultLocation.nameOfLevels1}}</mat-label>
                    <mat-select formControlName="level1" placeholder="Level1" (selectionChange)="Level2Change()">
                        <mat-option *ngFor="let item of level1List" [value]="item">{{item}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-3 col-6 second-card mb-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{seletedDefaultLocation.nameOfLevels2}}</mat-label>
                    <mat-select formControlName="level2" placeholder="Level2" (selectionChange)="Level3Change()">
                        <mat-option *ngFor="let item of level2List" [value]="item">{{item}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-3 col-6 third-card mb-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{seletedDefaultLocation.nameOfLevels3}}</mat-label>
                    <mat-select formControlName="level3" placeholder="Level3" (selectionChange)="Level4Change()">
                        <mat-option *ngFor="let item of level3List" [value]="item">{{item}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-3 col-6 fourth-card mb-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{seletedDefaultLocation.nameOfLevels4}}</mat-label>
                    <mat-select formControlName="level4" placeholder="Level4">
                        <mat-option *ngFor="let item of level4List" [value]="item">{{item}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</form>
<!-- Location Modal End -->