import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ConstantsService } from 'src/app/constants.service';
import { PartCategoryService } from 'src/app/services/Category/part-category.service';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { SharedService } from 'src/app/services/shared.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-partcategory',
  templateUrl: './partcategory.component.html',
  styleUrls: ['./partcategory.component.css']
})
export class PartcategoryComponent implements OnInit {
  IsAllCategory: boolean = false;
  loader: boolean = false;
  CategoryList: any = [];
  Purpose: any = [];
  CategoryType: any = [];
  PlateformType: any = [];
  EcoomerceAPIList: any = [];
  CategoryDetails: any;
  CategoryForm: FormGroup;
  companyId: number = Number(localStorage.getItem('GlobalSelectedCompanyId'))

  constructor(public partCategoryService: PartCategoryService,
    private constants: ConstantsService,
    private globalChangeService: GlobalChangeService,
    private fb: FormBuilder,
    public sharedService: SharedService,
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.GetCategoryList('');
    this.GetCategoryType();
    this.GetPurpose();
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      this.companyId = data
      this.IsAllCategory = false;
      this.GetCategoryList('');
      this.GetCategoryType();
      this.GetPurpose();
    })
  }
  // Form defination start
  createForm() {
    this.CategoryForm = this.fb.group({
      'id': new FormControl(0),
      'name': new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
      'description': new FormControl(''),
      'parentId': new FormControl(0),
      'isActive': new FormControl(true),
      'categoryTypeId': new FormControl(1),
      'purpose': new FormControl(1),
      'Ecommarce': new FormArray([]),
      'CreatedBy': new FormControl(localStorage.getItem('userId')),
    });
    this.AddEcommerce();
    this.Companyform.Ecommarce.disable()
  }
  get Companyform() {
    return this.CategoryForm.controls;
  }
  get Ecommarce() {
    return (<FormArray>this.CategoryForm.get('Ecommarce'));
  }
  AddEcommerce() {
    this.Ecommarce.push(new FormGroup({
      wordpressId: new FormControl(0),
      plateformTypeId: new FormControl(0),
      websiteId: new FormControl(0),
    }))
  }
  GetCategoryList(searchVal: string) {
    this.loader = searchVal != '' ? false : true;
    this.partCategoryService.GetCategoryList(this.companyId, searchVal)
      .subscribe({
        next: (data: any) => {
          this.CategoryList = data.dataList;
          this.loader = false;
        },
        error: (err) => {
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  GetCategoryById(row: any) {
    this.IsAllCategory = true;
    this.CategoryDetails = row;
    this.BindData();
  }
  BindData() {
    this.CategoryForm.patchValue(this.CategoryDetails);
  }
  Back() {
    this.IsAllCategory = false;
    this.GetCategoryList('');
  }
  ParentCategory(parentId: number) {
    this.CategoryForm.patchValue({ parentId: parentId });
  }
  AddCategory() {

  }
  SearchCategory(searchVal: any) {
    if (searchVal != '') {
      this.GetCategoryList(searchVal);
    }
  }
  ExpandMore(value: any) {
    value.collapsed = !value.collapsed;
    this.CategoryList.filter(c => c.parentId == value.id && c.level == value.level + 1).forEach(element => {
      element.isShow = value.collapsed;
    });
    if (!value.collapsed) {
      this.Collapse(value)
    }
  }
  Collapse(value: any) {
    this.CategoryList.filter(c => c.parentId == value.id && c.level == value.level + 1).forEach(element => {
      element.isShow = false;
      element.collapsed = false;
      if (element.isChild) {
        this.Collapse(element);
      }
    });
  }
  IsExpandAll(value: boolean) {
    this.CategoryList.forEach(element => {
      element.isShow = value;
      element.collapsed = value;
    });
  }
  GetPurpose() {
    this.partCategoryService.GetPurpose()
      .subscribe({
        next: (data: any) => {
          this.Purpose = data.dataList;
        },
        error: (err) => {
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  GetCategoryType() {
    this.partCategoryService.GetCategoryType()
      .subscribe({
        next: (data: any) => {
          this.CategoryType = data.dataList;
        },
        error: (err) => {
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  changePurpose(purposeId: number) {
    if (purposeId == 3) {
      this.Companyform.Ecommarce.enable()
      this.GetEcoomerceAPI();
      this.GetPlateformType();
    }
  }
  GetEcoomerceAPI() {
    this.loader = true;
    this.sharedService.GetAllEcommerceDetails(this.companyId, '')
      .subscribe({
        next: (data: any) => {
          this.EcoomerceAPIList = data.dataList;
          this.loader = false;
        },
        error: (err) => {
          this.loader = false;
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  GetPlateformType() {
    this.partCategoryService.GetPlateformType()
      .subscribe({
        next: (data: any) => {
          this.PlateformType = data.dataList;
        },
        error: (err) => {
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  // Swal Msg Start
  Warning() {
    Swal.fire({
      titleText: 'Change Warning',
      text: 'Changing the Status of and eCommerce Part Category will delete the Part Category in the eCommerce site(s).',
      confirmButtonText: 'Delete from eCommerce Site(s)',
      showConfirmButton: true,
      allowOutsideClick: false
    }).then((result: any) => {
    });
  }
  SuccessMsg() {
    Swal.fire({
      titleText: 'Export is Successful.',
      text: 'The Part Category has successfully been exported the the eCommerce site.',
      confirmButtonText: 'Close',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }).then((result: any) => {
    });
  }
  FailedMsg() {
    Swal.fire({
      titleText: 'Export Failed.',
      text: 'The Part Category was not able to be exported the the eCommerce site.',
      confirmButtonText: 'Close',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }).then((result: any) => {
    });
  }
}
