<section class="content-section-2" id="new-layout">
    <app-loader [loader]="loader"></app-loader>
    <app-breadcrumb [currentPage]="currentPage" [isShare]="true"
        (sharebtnClick)="GetActionName($event)"></app-breadcrumb>

    <div class="content-section-1">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-3 col-lg-3 col-xl-2 mb-3 mb-md-0 mb-sm-3 select-arrow-change-icon">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Report</mat-label>
                        <mat-select>
                            <mat-option *ngFor="let type of ReportType" [value]="type.id" style="width: 210px"
                                (click)="GetReportTypeName(type.name)">
                                {{type.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-3 col-lg-3 col-xl-2 mb-3 mb-md-0 mb-sm-3 select-arrow-change-icon">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Filter Type</mat-label>
                        <mat-select>
                            <mat-option *ngFor="let type of FilterReportData" [value]="type.id" style="width: 210px"
                                (click)="GetFilterType(type.id,type.name)">
                                {{type.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-3 col-lg-3 col-xl-2 mb-3 mb-md-0 mb-sm-3 select-arrow-change-icon" *ngIf="isShowFilter">
                    <mat-form-field class="w-100" appearance="outline">
                        <span class="material-icons">
                            search
                        </span>
                        <input class="w-100" matInput type="text" autocomplete="off"
                            (input)="GetFilterUsingSearch($event.target.value)" placeholder="Search by Vendor Name"
                            #input>
                    </mat-form-field>
                </div>

                <div class="col-md-3 col-lg-3 col-xl-2 mb-3" *ngIf="isShowCategory">
                    <div class="row">
                        <div class="col-lg-12">
                            <mat-form-field class="form-field w-100" appearance="outline">
                                <mat-label>Select Part Category</mat-label>
                                <mat-select [(value)]="selectedMulti" (selectionChange)="onParentCatChange($event)"
                                    multiple disableOptionCentering>
                                    <ng-container *ngTemplateOutlet="
                                      recursiveOptionsMulti;
                                      context: { $implicit: programs, depth: 1 } ">
                                    </ng-container>

                                    <ng-template #recursiveOptionsMulti let-list let-depth="depth">
                                        <ng-container *ngFor="let program of list">
                                            <mat-option [value]="program.value" [ngStyle]="{
                                          'padding-left.px': depth <= 1 ? null : 16 * depth
                                        }">
                                                {{ program.text }}
                                            </mat-option>

                                            <ng-container *ngIf="!!program.children && program.children.length > 0">
                                                <ng-container *ngTemplateOutlet="
                                            recursiveOptionsMulti;
                                            context: { $implicit: program.children, depth: depth + 1 }
                                          ">
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </ng-template>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="col-md-2 mb-md-0 mb-sm-3 select-arrow-change-icon">
                    <button mat-button color="primary" class="btn cancel-btn update-btn me-2"
                        (click)="GetInvList()">Apply</button>
                </div>
            </div>

            <div class="row mt-4 inventory-table" *ngIf="showInvTable && allInventory && invMasterList.length > 0">
                <div class="col-xl-11 col-12">
                    <label style="width:100%;text-align: center;font-weight: 500;">Inventory Report</label>
                    <div class="table-scroll mat-elevation-z8 master-table box-shadow-none">
                        <mat-table [dataSource]="invMasterList" class="table-inventory cstmTable">
                            <ng-container matColumnDef="item">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header itemHead"> Item
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element; let i = index;" data-label="Item"
                                    class="inventory-mat-cell itemcell"> {{i+1}}
                                </mat-cell>
                                <mat-footer-cell *matFooterCellDef class="inventory-table-header itemHead">Total:</mat-footer-cell>
                            </ng-container>
                            <ng-container matColumnDef="itemNumber">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Item Number
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Item Number" class="inventory-mat-cell">
                                    {{element.itemNumber}} </mat-cell>
                                    <mat-footer-cell *matFooterCellDef class="inventory-table-header"></mat-footer-cell>
                            </ng-container>
                            <ng-container matColumnDef="itemDescription">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header itemDescHead"> Item
                                    Description
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Item Description"
                                    class="inventory-mat-cell itemDescCell">
                                    {{element.itemDescription}} </mat-cell>
                                    <mat-footer-cell *matFooterCellDef class="inventory-table-header itemDescHead"></mat-footer-cell>
                            </ng-container>
                            <ng-container matColumnDef="inventoryType">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Inventory Type
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Inventory Type"
                                    class="inventory-mat-cell">
                                    {{GetInventoryTypeValue(element.inventoryType)}} </mat-cell>
                                    <mat-footer-cell *matFooterCellDef class="inventory-table-header"></mat-footer-cell>
                            </ng-container>
                            <ng-container matColumnDef="categories">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header categoryHead"> Category
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Inventory Type"
                                    class="inventory-mat-cell categoryCell" [innerHTML]="element.categories">
                                    <!-- {{element.categories}} -->
                                </mat-cell>
                                <mat-footer-cell *matFooterCellDef class="inventory-table-header categoryHead"></mat-footer-cell>
                            </ng-container>
                            <ng-container matColumnDef="onHand">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> On Hand
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="On Hand" class="inventory-mat-cell">
                                    {{element.onHand}}
                                </mat-cell>
                                <mat-footer-cell *matFooterCellDef class="inventory-table-header"></mat-footer-cell>
                            </ng-container>
                            <ng-container matColumnDef="available">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Available
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Available" class="inventory-mat-cell">
                                    {{element.available}} </mat-cell>
                                    <mat-footer-cell *matFooterCellDef class="inventory-table-header"></mat-footer-cell>
                            </ng-container>
                            <ng-container matColumnDef="committed">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Committed
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Committed" class="inventory-mat-cell">
                                    {{element.committed}} </mat-cell>
                                    <mat-footer-cell *matFooterCellDef class="inventory-table-header"></mat-footer-cell>
                            </ng-container>
                            <ng-container matColumnDef="totalCost">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Total Cost
                                </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Total cost"
                                        class="inventory-mat-cell totalCost">
                                        {{element.totalCost | currency}} </mat-cell>
                                <mat-footer-cell *matFooterCellDef class="inventory-table-header" style="padding: 32px;">{{SumTotalCost ==
                                    0 ? '$0.00':SumTotalCost | currency}}</mat-footer-cell>
                            </ng-container>
                            <ng-container matColumnDef="backOrdered">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Back Ordered
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Back Ordered"
                                    class="inventory-mat-cell">
                                    {{element.backOrdered}} </mat-cell>
                                    <mat-footer-cell *matFooterCellDef class="inventory-table-header"></mat-footer-cell>
                            </ng-container>
                            <ng-container matColumnDef="boAvailable">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header headTextCenter"> B/O
                                    <br> Available
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="B/O Available"
                                    class="inventory-mat-cell">
                                    {{element.boAvailable}} </mat-cell>
                                    <mat-footer-cell *matFooterCellDef class="inventory-table-header headTextCenter"></mat-footer-cell>
                            </ng-container>
                            <ng-container matColumnDef="boCommitted">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header headTextCenter"> B/O
                                    <br> Committed
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="B/O Committed"
                                    class="inventory-mat-cell">
                                    {{element.boCommitted}} </mat-cell>
                                    <mat-footer-cell *matFooterCellDef class="inventory-table-header headTextCenter"></mat-footer-cell>
                            </ng-container>
                            <ng-container matColumnDef="boTotalCost">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header headTextCenter"> B/O
                                    <br> TotalCost
                                </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="B/O TotalCost"
                                        class="inventory-mat-cell">
                                        {{element.boTotalCost | currency}} </mat-cell>
                                <mat-footer-cell *matFooterCellDef class="inventory-table-header headTextCenter">{{SumBOCost==
                                    0 ? '$0.00':SumBOCost | currency}}</mat-footer-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="invListColumns sticky: true"
                                class="header-style inventory-header"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: invListColumns;let i= index;"
                                class="material-inventory-row"></mat-row>
                                <mat-footer-row *matFooterRowDef="invListColumns sticky: true"></mat-footer-row>
                        </mat-table>
                    </div>
                </div>
            </div>
            <div class="row mt-4 inventory-table"
                *ngIf="!showInvTable && !allInventory && showPartTable && partReportList.length > 0">
                <div class="col-xl-11 col-12">
                    <label style="width:100%;text-align: center;font-weight: 500;">Part Report</label>
                    <div class="table-scroll mat-elevation-z8 master-table box-shadow-none">
                        <mat-table [dataSource]="partReportList" class="table-inventory cstmTable">
                            <ng-container matColumnDef="partNo">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header listParHead"> List Part
                                    #
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="List Part #"
                                    class="inventory-mat-cell listParCell">
                                    {{element.partNo}} </mat-cell>
                                    <mat-footer-cell *matFooterCellDef class="inventory-table-header listParHead">Total:</mat-footer-cell>
                            </ng-container>
                            <ng-container matColumnDef="name">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header listParHead"> List Part
                                    Name
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="List Part Name"
                                    class="inventory-mat-cell listParCell">
                                    {{element.name}} </mat-cell>
                                    <mat-footer-cell *matFooterCellDef class="inventory-table-header listParHead"></mat-footer-cell>
                            </ng-container>
                            <ng-container matColumnDef="vendorPartNumber">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header listParHead"> Vendor
                                    Part #
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Vendor Part #"
                                    class="inventory-mat-cell listParCell">
                                    {{element.vendorPartNumber}} </mat-cell>
                                    <mat-footer-cell *matFooterCellDef class="inventory-table-header listParHead"></mat-footer-cell>
                            </ng-container>
                            <ng-container matColumnDef="vendorPartName">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header VPNameHead"> Vendors
                                    Part Name(s)
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Vendor Part Name"
                                    class="inventory-mat-cell VPNameCell">
                                    {{element.vendorPartName}}
                                </mat-cell>
                                <mat-footer-cell *matFooterCellDef class="inventory-table-header listParHead VPNameCell"></mat-footer-cell>
                            </ng-container>
                            <ng-container matColumnDef="vendorPartCost">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header listParHead"> Part Cost                    
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="List Price"
                                    class="inventory-mat-cell listParCell vendorPartCost">
                                    {{element.vendorPartCost | currency}} </mat-cell>
                                    <mat-footer-cell *matFooterCellDef class="inventory-table-header listParHead vendorPartCost">{{SumTotalCost ==
                                        0 ? '$0.00':SumTotalCost | currency}}</mat-footer-cell>
                            </ng-container>
                            <ng-container matColumnDef="cost">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header listParHead"> List Price
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Part Cost"
                                    class="inventory-mat-cell listParCell cost">
                                    {{element.cost | currency}} </mat-cell>
                                    <mat-footer-cell *matFooterCellDef class="inventory-table-header listParHead cost">{{SumBOCost==
                                        0 ? '$0.00':SumBOCost | currency}}</mat-footer-cell>
                            </ng-container>
                            <ng-container matColumnDef="categories">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Category(s)
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Category" class="inventory-mat-cell">
                                    {{element.categories}} </mat-cell>
                                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                            </ng-container>
                            <ng-container matColumnDef="vendorName">
                                <mat-header-cell *matHeaderCellDef class="inventory-table-header"> Vendor(s)
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Vendor" class="inventory-mat-cell">
                                    {{element.vendorName}} </mat-cell>
                                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="partListColumns sticky: true"
                                class="header-style inventory-header"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: partListColumns;let i= index;"
                                class="material-inventory-row"></mat-row>
                                <mat-footer-row *matFooterRowDef="partListColumns sticky: true"></mat-footer-row>
                        </mat-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>