<mat-form-field class="w-100 icon-change-input" appearance="outline">
    <mat-label>Category Type</mat-label>
    <mat-select [(value)]="CatId" (selectionChange)="onParentCatChange($event)"
        disableOptionCentering>
        <ng-container *ngTemplateOutlet="
          recursiveOptionsMulti;
          context: { $implicit: programs, depth: 1 } ">
        </ng-container>

        <ng-template #recursiveOptionsMulti let-list let-depth="depth">
            <ng-container *ngFor="let program of list">
                <mat-option [value]="program.value" [ngStyle]="{
              'padding-left.px': depth <= 1 ? null : 16 * depth
            }">
                    {{ program.text }}
                </mat-option>

                <ng-container *ngIf="!!program.children && program.children.length > 0">
                    <ng-container *ngTemplateOutlet="
                recursiveOptionsMulti;
                context: { $implicit: program.children, depth: depth + 1 }
              ">
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-template>
    </mat-select>
</mat-form-field>