import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationSetupService {

  baseUrl = environment.baseUrl;

  constructor(private _http: HttpClient) { }

  getLocationDetails(companyId, searchTerm) {
    return this._http.get(this.baseUrl + 'api/LocationLevels/GetLocationDetails?companyId=' + companyId + '&searchTerm=' + searchTerm,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetMasterLocationTypeList() {
    return this._http.get(this.baseUrl + 'api/LocationLevels/GetLocationTypeList',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  //Error Handler Method
  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "server error.");
  }
  getLocationLevelList(companyId, locationTypeId, locationName) {
    return this._http.get(this.baseUrl + 'api/LocationLevels/GetLocationLevelDetails?companyId=' + companyId + '&locationTypeId='
      + locationTypeId + '&locationName=' + locationName,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  AddUpdateLocationDetails(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/LocationLevels/AddUpdateLocationDetails', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  AddUpdateLocation(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/LocationLevels/AddUpdateLocation', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  AddUpdLocationPositions(status: boolean, Id: number, CreatedBy: string): Observable<any> {
    return this._http.get(this.baseUrl + 'api/LocationLevels/AddUpdLocationPositions?Id=' + Id + '&status=' + status + '&CreatedBy=' + CreatedBy,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  deleteLocationLevelDetails(Id: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/LocationLevels/DeleteLocationLevelsDetails?Id=' + Id, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
}
