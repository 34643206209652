<form [formGroup]="Search">
    <ng-container *ngIf="Data != undefined">
        <div class="row">
            <div class="col-md-2 mb-md-0 mb-sm-3 select-arrow-change-icon">
                <mat-form-field class="w-70" appearance="outline">
                    <mat-label>Filter Type</mat-label>
                    <mat-select formControlName="filterTypeId">
                        <mat-option style="width: 227px" *ngFor="let item of Data.FilterTypeList"
                            [value]="item.id">{{item.value}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-2 mb-md-0 mb-sm-3 select-arrow-change-icon">
                <mat-form-field class="w-70" appearance="outline">
                    <mat-label>Status Filter</mat-label>
                    <mat-select formControlName="statusFilterId" (selectionChange)="changeStatusFilter($event.value)" multiple>
                        <mat-option style="width: 227px" *ngFor="let item of Data.StatusFilterList"
                            [value]="item.statusId">{{item.statusName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <ng-container *ngIf="Search.value.filterTypeId==1">
                <div class="col-md-2 mb-md-0 mb-sm-3 select-arrow-change-icon">
                    <mat-form-field class="w-70" appearance="outline">
                        <mat-label>Time Period</mat-label>
                        <mat-select formControlName="timeperiodId">
                            <mat-option style="width: 227px" *ngFor="let item of Data.TimePeriod"
                                [value]="item.id">{{item.value}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-2 col-6 mb-sm-3 align-items-center">
                    <mat-form-field class="w-70" appearance="outline">
                        <mat-label>From</mat-label>
                        <input matInput [min]="minFromDate" [max]="maxFromDate" [matDatepicker]="frompicker"
                            formControlName="fromDate">
                        <mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
                        <mat-datepicker #frompicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-md-2 col-6 mb-sm-3">
                    <mat-form-field class="w-70" appearance="outline">
                        <mat-label>To</mat-label>
                        <input matInput [min]="minToDate" [max]="maxToDate" [matDatepicker]="topicker"
                            formControlName="toDate">
                        <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
                        <mat-datepicker #topicker></mat-datepicker>
                    </mat-form-field>
                </div>
            </ng-container>
            <div class="col-md-2 col-12  d-flex align-items-center justify-content-between">
                <button mat-button color="primary" class="btn cancel-btn update-btn me-2"
                    (click)="Apply()">Apply</button>
            </div>
        </div>
    </ng-container>
</form>