import { DatePipe } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantsService } from 'src/app/constants.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { InventoryService } from 'src/app/services/inventory/inventory.service';
import { PartService } from 'src/app/services/part/part.service';
import { SharedService } from 'src/app/services/shared.service';
import { compareMoveChannelQtyData, compareMoveLocQtyData, greaterThanZeroValidator, isNumberValidator } from 'src/app/validators/inventoryValidator';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-inventory-flyout',
  templateUrl: './inventory-flyout.component.html',
  styleUrls: ['./inventory-flyout.component.css', '../inventory-details/inventory-details.component.css']
})
export class InventoryFlyoutComponent implements OnInit {
  @Input() flyoutObject: any = {};
  @Output() closeFlyoutEvent = new EventEmitter<boolean>();

  transactionTypeVal = 0;
  companyId = 0;
  partId = 0;
  showNewTransaction: boolean = false;
  isSerialFlyout: boolean = false;
  defaulLocationForm: any = {};
  TrackingObj: any = [];
  loader: boolean = false;
  transactionTypeLabel: string = this.constants.transactionTypeLabel;
  inventoryManualForm: FormGroup;
  inventoryGainForm: FormGroup;
  inventoryLossForm: FormGroup;
  inventoryMoveForm: FormGroup;
  InvActionList: any = [];
  InvActionListFull: any = [];
  InvLocationList: any = [];
  PartTrackingList: any = [];
  locationLevelList: any = [];
  level1List: string[] = [];
  level2List: string[] = [];
  level3List: string[] = [];
  level4List: string[] = [];
  moveFormlevel1List: string[] = [];
  moveFormlevel2List: string[] = [];
  moveFormlevel3List: string[] = [];
  moveFormlevel4List: string[] = [];
  moveTolevel1List: string[] = [];
  moveTolevel2List: string[] = [];
  moveTolevel3List: string[] = [];
  moveTolevel4List: string[] = [];
  BatchList: any = [];
  seletedLocation: any = {};
  seletedDefaultLocation: any = {};
  moveFromLocation: any = {};
  moveToLocation: any = {};
  SKU: string;
  infoItemDescription: string = '';
  infoTracking: any;
  InvActiveList: any = [];
  InvStatusList: any = [];
  InvDocTypeList: any = [];
  CompanyWareHouseList: any = [];
  invReasonList: any = [];
  invlossReasonList: any = [];
  invgainReasonList: any = [];
  invmoveTypeList: any = [];
  InvTypeList: any = [];
  businessChannelList: any = [];
  //validator messages
  validationMessages: any = {
    'isNumber': 'This field must contain a number.',
    'greaterThanZero': 'This value must be greater than zero.',
    'lessThanAvl': 'Move Quantity can not be greter than Available Quantity.',
  };
  seriallotflyoutObj: any = {};
  moveInvetoryList: any;
  LocationSetUp: any;

  constructor(
    public inventoryService: InventoryService,
    public fb: FormBuilder,
    private sharedService: SharedService,
    private companyService: CompanyService,
    private constants: ConstantsService,
    private partService: PartService,
  ) {
    this.getTrackingList();
    this.GetInventoryType();
    this.GetInventoryAction();
    this.GetInventoryActive();
    this.GetInventoryStatus();
    this.GetInventoryDocType();
    this.GetInventoryReason();
    //form creates
    this.inventoryManualFormCreate();
    this.inventoryLossFormCreate();
    this.inventoryGainFormCreate();
    this.inventoryMoveFormCreate();
  }

  ngOnInit(): void {
    this.transactionTypeVal = this.flyoutObject.transactionTypeVal;
    this.companyId = this.flyoutObject.companyId;
    this.partId = this.flyoutObject.partId;
    this.showNewTransaction = this.flyoutObject.showNewTransaction;
    this.isSerialFlyout = this.flyoutObject.isSerialFlyout;
    this.defaulLocationForm = this.flyoutObject.defaulLocationValues;
    this.SKU = this.flyoutObject.SKU;
    this.infoTracking = this.flyoutObject.infoTracking;
    this.infoItemDescription = this.flyoutObject.partName;

    if (this.companyId > 0) {
      this.GetLocationName(this.companyId);
      this.GetWareHouseWithChild(this.companyId);
      this.GetCompanyDetails(this.companyId);
      this.GetBatchList(Number(this.partId));
    }
  }

  inventoryManualFormCreate() {
    this.inventoryManualForm = this.fb.group({
      action: new FormControl('', Validators.required),
      description: new FormControl(''),
      manualTracking: new FormControl({ value: 3, disabled: true }, Validators.required),
      docType: new FormControl(''),
      docNumber: new FormControl(''),
      batchNumber: new FormControl({ value: this.constants.autoGenerated, disabled: true }),
      balance: new FormControl({ value: 0, disabled: false }, [Validators.required, isNumberValidator, greaterThanZeroValidator]),
      unitcost: new FormControl({ value: 0, disabled: false }, [Validators.required, isNumberValidator, greaterThanZeroValidator]),
      totalvalue: new FormControl({ value: 0, disabled: true }, Validators.required),
      businessChannel: new FormControl('', Validators.required),
      status: new FormControl('', Validators.required),
      warehouse: new FormControl('', Validators.required),
      LocName: new FormControl('', Validators.required),
      level1: new FormControl(''),
      level2: new FormControl(''),
      level3: new FormControl(''),
      level4: new FormControl(''),
    });
  }

  inventoryLossFormCreate() {
    this.inventoryLossForm = this.fb.group({
      action: new FormControl('', Validators.required),
      batchNumber: new FormControl('', Validators.required),
      reason: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      currentBalance: new FormControl({ value: 0, disabled: true }, Validators.required),
      currentUnitcost: new FormControl({ value: 0, disabled: true }, Validators.required),
      currentTotal: new FormControl({ value: 0, disabled: true }, Validators.required),
      adjBalance: new FormControl({ value: 0, disabled: true }, [Validators.required]),
      adjUnitCost: new FormControl({ value: 0, disabled: true }, Validators.required),
      adjTotal: new FormControl({ value: 0, disabled: true }, Validators.required),
      invLossBalance: new FormControl({ value: 0, disabled: true }, Validators.required),
      invLossUnitCost: new FormControl({ value: 0, disabled: true }, Validators.required),
      invLossTotal: new FormControl({ value: 0, disabled: true }, Validators.required)
    });
  }

  inventoryGainFormCreate() {
    this.inventoryGainForm = this.fb.group({
      action: new FormControl('', Validators.required),
      batchNumber: new FormControl('', Validators.required),
      reason: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      currentBalance: new FormControl({ value: 0, disabled: true }, Validators.required),
      currentUnitcost: new FormControl({ value: 0, disabled: true }, Validators.required),
      currentTotal: new FormControl({ value: 0, disabled: true }, Validators.required),
      adjBalance: new FormControl({ value: 0, disabled: true }, Validators.required),
      adjUnitCost: new FormControl({ value: 0, disabled: true }, Validators.required),
      adjTotal: new FormControl({ value: 0, disabled: true }, Validators.required),
      invGainBalance: new FormControl({ value: 0, disabled: true }, Validators.required),
      invGainUnitCost: new FormControl({ value: 0, disabled: true }, Validators.required),
      invGainTotal: new FormControl({ value: 0, disabled: true }, Validators.required)
    });
  }

  inventoryMoveFormCreate() {
    this.inventoryMoveForm = this.fb.group({
      action: new FormControl(''),
      batchNumber: new FormControl(''),
      moveType: new FormControl(null, Validators.required),
      description: new FormControl(''),
      avlLocQty: new FormControl({ value: 0, disabled: true }),
      moveLocQty: new FormControl(0),
      fromSource: new FormControl(''),
      fromWarehouse: new FormControl({ value: '', disabled: true }),
      fromLocName: new FormControl({ value: '', disabled: true }),
      fromLoc: new FormControl({ value: '', disabled: true }),
      // fromlevel1: new FormControl({ value: '', disabled: true }),
      // fromlevel2: new FormControl({ value: '', disabled: true }),
      // fromlevel3: new FormControl({ value: '', disabled: true }),
      // fromlevel4: new FormControl({ value: '', disabled: true }),
      toWarehouse: new FormControl(''),
      toLocName: new FormControl(''),
      tolevel1: new FormControl(''),
      tolevel2: new FormControl(''),
      tolevel3: new FormControl(''),
      tolevel4: new FormControl(''),
      avlChannelQty: new FormControl({ value: 0, disabled: true }),
      frombusinessChannel: new FormControl({ value: '', disabled: true }),
      moveChannelQty: new FormControl(''),
      tobusinessChannel: new FormControl(''),
    });
    //this.moveFormValidation();
  }

  moveFormValidation(ctrl) {
    // this.inventoryMoveForm.get('moveType')?.valueChanges.subscribe(ctrl => {
    //   if (ctrl !== '' || ctrl != null) {
    if (ctrl == 10) {
      this.inventoryMoveForm.setValidators(null);
      this.inventoryMoveForm.setValidators([compareMoveLocQtyData]);
      this.inventoryMoveForm.updateValueAndValidity();
      this.inventoryMoveForm.get('moveLocQty')?.setValidators([Validators.required, isNumberValidator, greaterThanZeroValidator]);
      this.inventoryMoveForm.get('moveLocQty')?.updateValueAndValidity();
      this.inventoryMoveForm.get('toWarehouse')?.setValidators([Validators.required]);
      this.inventoryMoveForm.get('toWarehouse')?.updateValueAndValidity();
      this.inventoryMoveForm.get('toLocName')?.setValidators([Validators.required]);
      this.inventoryMoveForm.get('toLocName')?.updateValueAndValidity();
      this.inventoryMoveForm.get('moveChannelQty')?.setValidators(null);
      this.inventoryMoveForm.get('moveChannelQty')?.updateValueAndValidity();
      this.inventoryMoveForm.get('tobusinessChannel')?.setValidators(null);
      this.inventoryMoveForm.get('tobusinessChannel')?.updateValueAndValidity();
      // Added By Navneet
      this.LocationSetUp = {
        "locationId": this.inventoryMoveForm.value.toLocName,
        "wareHouseId": this.inventoryMoveForm.value.toWarehouse,
        "level2": this.inventoryMoveForm.value.tolevel2,
        "level3": this.inventoryMoveForm.value.tolevel3,
        "level4": this.inventoryMoveForm.value.tolevel4,
        "level1": this.inventoryMoveForm.value.tolevel1,
        "companyId": this.companyId,
        "pageName": 'InvFlyOut',
        "Heading": 'Move to Location',
        "SubHeading": '',
      }
      // Ended By Navneet
    } else if (ctrl == 11) {
      this.inventoryMoveForm.setValidators(null);
      this.inventoryMoveForm.setValidators([compareMoveChannelQtyData]);
      this.inventoryMoveForm.updateValueAndValidity();
      this.inventoryMoveForm.get('moveLocQty')?.setValidators(null);
      this.inventoryMoveForm.get('moveLocQty')?.updateValueAndValidity();
      this.inventoryMoveForm.get('toWarehouse')?.setValidators(null);
      this.inventoryMoveForm.get('toWarehouse')?.updateValueAndValidity();
      this.inventoryMoveForm.get('toLocName')?.setValidators(null);
      this.inventoryMoveForm.get('toLocName')?.updateValueAndValidity();
      this.inventoryMoveForm.get('moveChannelQty')?.setValidators([Validators.required, isNumberValidator, greaterThanZeroValidator]);
      this.inventoryMoveForm.get('moveChannelQty')?.updateValueAndValidity();
      this.inventoryMoveForm.get('tobusinessChannel')?.setValidators([Validators.required]);
      this.inventoryMoveForm.get('tobusinessChannel')?.updateValueAndValidity();
    } else {
      this.inventoryMoveForm.get('moveLocQty')?.setValidators(null);
      this.inventoryMoveForm.get('moveLocQty')?.updateValueAndValidity();
      this.inventoryMoveForm.get('toWarehouse')?.setValidators(null);
      this.inventoryMoveForm.get('toWarehouse')?.updateValueAndValidity();
      this.inventoryMoveForm.get('toLocName')?.setValidators(null);
      this.inventoryMoveForm.get('toLocName')?.updateValueAndValidity();
      this.inventoryMoveForm.get('moveChannelQty')?.setValidators(null);
      this.inventoryMoveForm.get('moveChannelQty')?.updateValueAndValidity();
      this.inventoryMoveForm.get('tobusinessChannel')?.setValidators(null);
      this.inventoryMoveForm.get('tobusinessChannel')?.updateValueAndValidity();
    }
    //   }
    // });
  }

  inventoryManualFormSubmit(inventoryManualFormVal: any) {
    this.CreateManualInventory(inventoryManualFormVal);
  }
  // Added By Navneet
  saveDefaultLocation(value: any) {
    if (this.transactionTypeVal == 1) {
      this.inventoryManualForm.patchValue(
        {
          warehouse: value.wareHouseId,
          LocName: value.locationId,
          level1: value.level1,
          level2: value.level2,
          level3: value.level3,
          level4: value.level4,
        }
      )
    } else if (this.transactionTypeVal == 4) {
      this.inventoryMoveForm.patchValue(
        {
          toWarehouse: value.wareHouseId,
          toLocName: value.locationId,
          tolevel1: value.level1,
          tolevel2: value.level2,
          tolevel3: value.level3,
          tolevel4: value.level4,
        }
      )
    }
  }
  // Ended By Navneet
  inventoryLossFormSubmit(inventoryLossFormVal: any) {
    if (this.inventoryLossForm.invalid) {
      return;
    }
    if (inventoryLossFormVal.reason != 5) {
      this.CreateLossInventory(inventoryLossFormVal);
    } else {
      this.CreateWriteDownInventory(inventoryLossFormVal);
    }
  }

  GetBatch(trackingId): string {
    const indexOfDecimal = trackingId.indexOf('.');
    if (indexOfDecimal !== -1) {
      return trackingId.substring(0, indexOfDecimal);
    }
    return trackingId;
  }

  inventoryGainFormSubmit(inventoryGainFormVal: any) {
    if (this.inventoryGainForm.invalid) {
      return;
    }
    this.CreateGainInventory(inventoryGainFormVal);
  }

  inventoryMoveFormSubmit(inventoryMoveFormVal: any) {
    if (this.inventoryMoveForm.invalid) {
      return;
    }
    this.CreateMoveInventory(inventoryMoveFormVal);
  }

  CreateManualInventory(initialformValue) {
    const reqObj = {
      CompanyId: Number(this.companyId),
      Action: Number(initialformValue.action), //Manual
      Description: initialformValue.description,
      InvID: Number(this.partId), // partid or assemblyid
      QtyIn: Number(initialformValue.balance),
      OrigBalance: Number(initialformValue.balance),
      Balance: Number(initialformValue.balance),
      Cost: parseFloat(initialformValue.unitcost),
      Channel: initialformValue.businessChannel,
      InvStatus: Number(initialformValue.status),
      DocType: initialformValue.docType,
      DocNo: initialformValue.docNumber,
      TrackingDetails: Number(this.infoTracking),
      WarehouseID: Number(initialformValue.warehouse),
      LocationName: initialformValue.LocName,
      Location: (initialformValue.level1 ? initialformValue.level1 : '') + (initialformValue.level2 ? '-' + initialformValue.level2 : '') + (initialformValue.level3 ? '-' + initialformValue.level3 : '') + (initialformValue.level4 ? '-' + initialformValue.level4 : ''),
      Tracking: this.TrackingObj,
    };
    this.SaveInventory(reqObj);
  }

  CreateWriteDownInventory(inventoryLossForm) {
    const reqObj = {
      Action: Number(inventoryLossForm.action),
      CompanyId: Number(this.companyId),
      Description: inventoryLossForm.description,
      InvID: Number(this.partId), // partid or assemblyid
      QtyIn: Number(inventoryLossForm.adjBalance),
      ReasonCode: Number(inventoryLossForm.reason),
      OutTransID: inventoryLossForm.batchNumber,
      OrigBalance: Number(inventoryLossForm.adjBalance),
      Balance: Number(inventoryLossForm.adjBalance),
      Cost: parseFloat(inventoryLossForm.adjUnitCost),
      TrackingDetails: Number(this.infoTracking),
      Tracking: this.TrackingObj,
    };
    this.SaveInventory(reqObj);
  }

  CreateLossInventory(lossformValue) {
    const reqObj = {
      CompanyId: Number(this.companyId),
      Action: Number(lossformValue.action), //Inventory Loss
      Description: lossformValue.description,
      InvID: Number(this.partId), // partid or assemblyid
      QtyIn: Number(lossformValue.invLossBalance),
      ReasonCode: Number(lossformValue.reason),
      QtyOut: Number(lossformValue.invLossBalance),
      OutTransID: lossformValue.batchNumber,
      OrigBalance: Number(lossformValue.invLossBalance),
      Balance: Number(lossformValue.invLossBalance),
      Cost: parseFloat(lossformValue.invLossUnitCost),
      TrackingDetails: Number(this.infoTracking),
      Tracking: this.TrackingObj,
    };
    this.SaveInventory(reqObj);
  }

  CreateGainInventory(gainformValue) {
    const reqObj = {
      CompanyId: Number(this.companyId),
      Action: Number(gainformValue.action), //Inventory Loss
      Description: gainformValue.description,
      InvID: Number(this.partId), // partid or assemblyid
      QtyIn: Number(gainformValue.invGainBalance),
      ReasonCode: Number(gainformValue.reason),
      QtyOut: Number(gainformValue.invGainBalance),
      OutTransID: gainformValue.batchNumber,
      OrigBalance: Number(gainformValue.invGainBalance),
      Balance: Number(gainformValue.invGainBalance),
      Cost: parseFloat(gainformValue.invGainUnitCost),
      Tracking: this.TrackingObj,
    };
    this.SaveInventory(reqObj);
  }

  CreateMoveInventory(moveformValue) {
    if (Number(moveformValue.moveType) == 10) {
      const reqObj = {
        CompanyId: Number(this.companyId),
        Action: Number(moveformValue.action),
        Description: moveformValue.description,
        InvID: Number(this.partId), // partid or assemblyid
        QtyIn: Number(moveformValue.moveLocQty),
        ReasonCode: Number(moveformValue.moveType),
        QtyOut: Number(moveformValue.moveLocQty),
        // OutTransID: moveformValue.batchNumber,
        OutTransID: moveformValue.fromSource.transID,
        OrigBalance: Number(moveformValue.moveLocQty),
        Balance: Number(moveformValue.moveLocQty),
        TrackingDetails: Number(this.infoTracking),
        WarehouseID: Number(moveformValue.toWarehouse),
        LocationName: moveformValue.toLocName,
        Location: (moveformValue.tolevel1 ? moveformValue.tolevel1 : '') + (moveformValue.tolevel2 ? '-' + moveformValue.tolevel2 : '') + (moveformValue.tolevel3 ? '-' + moveformValue.tolevel3 : '') + (moveformValue.tolevel4 ? '-' + moveformValue.tolevel4 : ''),
        Tracking: this.TrackingObj,
      };
      this.SaveInventory(reqObj);
    }
    if (Number(moveformValue.moveType) == 11) {
      const reqObj = {
        CompanyId: Number(this.companyId),
        Action: Number(moveformValue.action),
        Description: moveformValue.description,
        InvID: Number(this.partId), // partid or assemblyid
        QtyIn: Number(moveformValue.moveChannelQty),
        ReasonCode: Number(moveformValue.moveType),
        QtyOut: Number(moveformValue.moveChannelQty),
        OutTransID: moveformValue.batchNumber,
        OrigBalance: Number(moveformValue.moveChannelQty),
        Balance: Number(moveformValue.moveChannelQty),
        TrackingDetails: Number(this.infoTracking),
        Tracking: this.TrackingObj,
        Channel: moveformValue.tobusinessChannel,
      };
      this.SaveInventory(reqObj);
    }
  }

  SaveInventory(reqObj: any) {
    this.loader = true;
    this.inventoryService.CreateInventory(reqObj).subscribe(
      (data) => {
        this.loader = false;
        this.CloseNewTransaction();
      },
      (error) => {
        this.loader = false;
        this.PopupModal(this.constants.APIError, error);
      }
    );
  }

  CloseNewTransaction() {
    this.showNewTransaction = false;
    this.isSerialFlyout = false;
    this.inventoryManualForm.reset();
    this.inventoryLossForm.reset();
    this.inventoryGainForm.reset();
    this.inventoryMoveForm.reset();
    this.closeFlyoutEvent.emit(this.showNewTransaction);
  }
  changetransactionType(value: any) {
    this.transactionTypeVal = value;
    this.transactionTypeLabel = this.InvActionList.filter(x => x.actionId == this.transactionTypeVal)[0].actionName;
    switch (value) {
      case 1:
        this.inventoryManualForm.reset();
        // if (this.defaulLocationForm.locationId > 0) {

        //   this.locNameChange(this.defaulLocationForm.locationId, 1);
        // }
        // Added By Navneet
        this.LocationSetUp = {
          "locationId": this.defaulLocationForm.locationId,
          "wareHouseId": this.defaulLocationForm.wareHouseId,
          "level1": this.defaulLocationForm.level1,
          "level2": this.defaulLocationForm.level2,
          "level3": this.defaulLocationForm.level3,
          "level4": this.defaulLocationForm.level4,
          "companyId": this.companyId,
          "pageName": 'InvFlyOut',
          "Heading": 'Physical Location',
          "SubHeading": 'Location Address',
        }
        // Ended By Navneet
        this.inventoryManualForm.patchValue(
          {
            action: this.transactionTypeVal,
            batchNumber: this.constants.autoGenerated,
            warehouse: this.defaulLocationForm.wareHouseId,
            LocName: this.defaulLocationForm.locationId,
            level1: this.defaulLocationForm.level1,
            level2: this.defaulLocationForm.level2,
            level3: this.defaulLocationForm.level3,
            level4: this.defaulLocationForm.level4,
            itemNumber: this.SKU,
            manualTracking: this.infoTracking
          }
        );
        break;
      case 4:
        this.inventoryMoveForm.reset();
        this.inventoryMoveForm.patchValue({ action: this.transactionTypeVal });
        break;
      case 9:
        this.inventoryGainForm.reset();
        this.inventoryGainForm.patchValue({ action: this.transactionTypeVal });
        break;
      case 10:
        this.inventoryLossForm.reset();
        this.inventoryLossForm.patchValue({ action: this.transactionTypeVal });
        break;
    }
  }

  onLossBatchChange(event) {
    if (event.value != 0) {
      this.inventoryService.GetInvetoryByBatchNo(this.companyId, event.value, Number(this.partId)).subscribe(
        (data: any) => {
          if (data.data != null) {
            this.inventoryLossForm.patchValue({
              currentBalance: Number(data.data.balance),
              currentUnitcost: parseFloat(data.data.cost).toFixed(5),
              currentTotal: parseFloat(String(data.data.balance * data.data.cost)).toFixed(5),
              adjUnitCost: parseFloat(data.data.cost).toFixed(5),
            });
          }
        },
        (error) => {
          this.PopupModal(this.constants.APIError, error);
        }
      );
    } else {
      this.inventoryLossForm.patchValue({
        currentBalance: 0,
        currentUnitcost: 0,
        currentTotal: 0,
        adjBalance: 0,
        adjUnitCost: 0,
        adjTotal: 0,
        invLossBalance: 0,
        invLossUnitCost: 0,
        invLossTotal: 0,
      });
    }
  }

  onGainBatchChange(event) {
    if (event.value != 0) {
      this.inventoryService.GetInvetoryByBatchNo(this.companyId, event.value, Number(this.partId)).subscribe(
        (data: any) => {
          if (data.data != null) {
            this.inventoryGainForm.patchValue({
              currentBalance: data.data.balance,
              currentUnitcost: parseFloat(data.data.cost).toFixed(5),
              currentTotal: parseFloat(String(data.data.cost * data.data.balance)).toFixed(5),
              adjUnitCost: parseFloat(data.data.cost).toFixed(5),
            });
          }
        },
        (error) => {
          this.PopupModal(this.constants.APIError, error);
        }
      );
    } else {
      this.inventoryLossForm.patchValue({
        currentBalance: 0,
        currentUnitcost: 0,
        currentTotal: 0,
        adjBalance: 0,
        adjUnitCost: 0,
        adjTotal: 0,
        invGainBalance: 0,
        invGainUnitCost: 0,
        invGainTotal: 0,
      });
    }
  }

  // onMoveBatchChange(event) {
  //   if (event.value != 0) {
  //     this.inventoryService.GetInvetoryByBatchNo(this.companyId, event.value, Number(this.partId)).subscribe(
  //       (data: any) => {
  //         if (data.data != null) {
  //           this.inventoryMoveForm.patchValue({
  //             avlLocQty: Number(data.data.balance),
  //             fromWarehouse: Number(data.data.warehouseID),
  //             fromLocName: Number(data.data.locationName),
  //             avlChannelQty: Number(data.data.balance),
  //             frombusinessChannel: data.data.channel
  //           });
  //           this.locNameChange(data.data.locationName, 2);
  //           this.SetLocationLevels(data.data.location, 2);
  //         }
  //       },
  //       (error) => {
  //         this.PopupModal(this.constants.APIError, error);
  //       }
  //     );
  //   } else {
  //     this.inventoryMoveForm.patchValue({
  //       avlLocQty: 0,
  //       fromWarehouse: 0,
  //       fromLocName: 0,
  //       avlChannelQty: 0,
  //       frombusinessChannel: ''
  //     });
  //   }
  // }

  onMoveBatchChange(event) {
    if (event.value != 0) {
      this.inventoryService.GetInvetoryListByBatchNo(this.companyId, event.value, Number(this.partId)).subscribe(
        (data: any) => {
          if (data.data != null) {
            this.moveInvetoryList = data.data.filter(x => ((x.balance == null || x.balance == undefined) ? 0 : x.balance) > 0);
            //this.moveInvetoryList = {};
            // let groupedData = Object.keys(data.data.reduce((acc, current) => {
            //   let key = [this.GetWareHouseValue(current.warehouseID), this.GetLocationValue(current.locationName), current.location].join(', ');
            //   if (!acc[key]) {
            //     acc[key] = [];
            //   }
            //   //acc[key].push(current);
            //   acc[key].push({ ...current, totalBalance: acc[key].reduce((balance, item) => balance + item.balance, 0) });
            //   return acc;
            // }, this.moveInvetoryList));
          }
        },
        (error) => {
          this.PopupModal(this.constants.APIError, error);
        }
      );
    }
  }

  fromSourceChange(obj: any) {
    if (this.inventoryMoveForm.value.moveType == 10) {
      this.inventoryMoveForm.patchValue({
        avlLocQty: Number(obj.balance),
        fromWarehouse: Number(obj.warehouseID),
        fromLocName: Number(obj.locationName),
        fromLoc: obj.location
      });
    } else if (this.inventoryMoveForm.value.moveType == 11) {
      this.inventoryMoveForm.patchValue({
        frombusinessChannel: obj.channel,
        avlChannelQty: Number(obj.balance),
      });
    } else {
      this.inventoryMoveForm.patchValue({
        avlLocQty: 0,
        fromWarehouse: 0,
        fromLocName: 0,
        avlChannelQty: 0,
        frombusinessChannel: ''
      });
    }
  }

  locNameChange(value, flag: number) {
    if (flag == 0) {
      this.inventoryManualForm.patchValue({ LocName: value });
    }
    this.setLocationLabels(value, flag);
    this.setLocationLevels(this.companyId, value, flag);
  }

  setLocationLabels(locationId: number, flag: number) {
    if (flag == 0) {
      this.seletedLocation = this.InvLocationList.filter(x => x.id == locationId)[0];
    } else if (flag == 1) {
      this.seletedDefaultLocation = this.InvLocationList.filter(x => x.id == locationId)[0];
    } else if (flag == 2) {
      this.moveFromLocation = this.InvLocationList.filter(x => x.id == locationId)[0];
    } else if (flag == 3) {
      this.moveToLocation = this.InvLocationList.filter(x => x.id == locationId)[0];
    }
  }

  setLocationLevels(companyId: number, locationId: number, flag: number) {
    this.inventoryService.GetLocationLevelsPositions(companyId, locationId).subscribe(
      (data: any) => {
        this.locationLevelList = data.dataList;
        this.loader = false;
        if (data.dataList.length > 0) {
          const level1 = data.dataList.map(function (item) { return item['aileName']; });
          const level2 = data.dataList.map(function (item) { return item['sectionName']; });
          const level3 = data.dataList.map(function (item) { return item['shelfName']; });
          const level4 = data.dataList.map(function (item) { return item['positionName']; });
          if (flag == 0) {
            // this.l1List = [];
            // this.l2List = [];
            // this.l3List = [];
            // this.l4List = [];
            // this.l1List.push(...(level1.filter((value, index, array) => array.indexOf(value) === index)));
            // this.l2List.push(...(level2.filter((value, index, array) => array.indexOf(value) === index)));
            // this.l3List.push(...(level3.filter((value, index, array) => array.indexOf(value) === index)));
            // this.l4List.push(...(level4.filter((value, index, array) => array.indexOf(value) === index)));
          } else if (flag == 1) {
            this.level1List = [];
            this.level2List = [];
            this.level3List = [];
            this.level4List = [];
            this.level1List.push(...(level1.filter((value, index, array) => array.indexOf(value) === index)));
            this.level2List.push(...(level2.filter((value, index, array) => array.indexOf(value) === index)));
            this.level3List.push(...(level3.filter((value, index, array) => array.indexOf(value) === index)));
            this.level4List.push(...(level4.filter((value, index, array) => array.indexOf(value) === index)));
          } else if (flag == 2) {
            this.moveFormlevel1List = [];
            this.moveFormlevel2List = [];
            this.moveFormlevel3List = [];
            this.moveFormlevel4List = [];
            this.moveFormlevel1List.push(...(level1.filter((value, index, array) => array.indexOf(value) === index)));
            this.moveFormlevel2List.push(...(level2.filter((value, index, array) => array.indexOf(value) === index)));
            this.moveFormlevel3List.push(...(level3.filter((value, index, array) => array.indexOf(value) === index)));
            this.moveFormlevel4List.push(...(level4.filter((value, index, array) => array.indexOf(value) === index)));
          } else if (flag == 3) {
            this.moveTolevel1List = [];
            this.moveTolevel2List = [];
            this.moveTolevel3List = [];
            this.moveTolevel4List = [];
            this.moveTolevel1List.push(...(level1.filter((value, index, array) => array.indexOf(value) === index)));
            this.moveTolevel2List.push(...(level2.filter((value, index, array) => array.indexOf(value) === index)));
            this.moveTolevel3List.push(...(level3.filter((value, index, array) => array.indexOf(value) === index)));
            this.moveTolevel4List.push(...(level4.filter((value, index, array) => array.indexOf(value) === index)));
          }
        }
      },
      (error: any) => {
        this.loader = false;
        this.PopupModal(this.constants.APIError, error);
      }
    );
  }

  onLossReasonChange(event) {
    if (this.inventoryLossForm.getRawValue().batchNumber != '0') {
      switch (Number(event.value)) {
        case 0:
          this.inventoryLossForm.get('adjBalance').disable();
          this.inventoryLossForm.get('adjUnitCost').disable();
          this.inventoryLossForm.patchValue({
            adjUnitCost: this.inventoryLossForm.getRawValue().adjUnitCost
          });
        case 5:
          this.inventoryLossForm.get('adjBalance').disable();
          this.inventoryLossForm.get('adjUnitCost').enable();
          this.inventoryLossForm.patchValue({
            adjBalance: this.inventoryLossForm.getRawValue().currentBalance,
            adjUnitCost: this.inventoryLossForm.getRawValue().adjUnitCost
          });
          break;
        case 7:
          this.inventoryLossForm.get('adjBalance').enable();
          this.inventoryLossForm.get('adjUnitCost').enable();
          this.inventoryLossForm.patchValue({
            adjUnitCost: this.inventoryLossForm.getRawValue().adjUnitCost
          });
          break;
        default:
          this.inventoryLossForm.get('adjBalance').enable();
          this.inventoryLossForm.get('adjUnitCost').disable();
          this.inventoryLossForm.patchValue({
            adjUnitCost: this.inventoryLossForm.getRawValue().adjUnitCost
          });
          break;
      }
    } else {
      this.inventoryLossForm.patchValue({
        currentBalance: 0,
        currentUnitcost: 0,
        currentTotal: 0,
        adjBalance: 0,
        adjUnitCost: 0,
        adjTotal: 0,
        invLossBalance: 0,
        invLossUnitCost: 0,
        invLossTotal: 0,
      });
    }
  }

  onGainReasonChange(event) {
    if (this.inventoryGainForm.getRawValue().batchNumber != '0') {
      switch (Number(event.value)) {
        case 0:
          this.inventoryGainForm.get('adjBalance').disable();
          this.inventoryGainForm.get('adjUnitCost').disable();
          this.inventoryGainForm.patchValue({
            adjUnitCost: this.inventoryGainForm.getRawValue().adjUnitCost
          });
          break;
        case 8:
          this.inventoryGainForm.get('adjBalance').enable();
          this.inventoryGainForm.get('adjUnitCost').disable();
          this.inventoryGainForm.patchValue({
            adjUnitCost: this.inventoryGainForm.getRawValue().adjUnitCost
          });
          break;
        case 9:
          this.inventoryGainForm.get('adjBalance').enable();
          this.inventoryGainForm.get('adjUnitCost').enable();
          this.inventoryGainForm.patchValue({
            adjUnitCost: this.inventoryGainForm.getRawValue().adjUnitCost
          });
          break;
        default:
          this.inventoryGainForm.get('adjBalance').enable();
          this.inventoryGainForm.get('adjUnitCost').disable();
          this.inventoryGainForm.patchValue({
            adjUnitCost: this.inventoryGainForm.getRawValue().adjUnitCost
          });
          break;
      }
    } else {
      this.inventoryGainForm.patchValue({
        currentBalance: 0,
        currentUnitcost: 0,
        currentTotal: 0,
        adjBalance: 0,
        adjUnitCost: 0,
        adjTotal: 0,
        invGainBalance: 0,
        invGainUnitCost: 0,
        invGainTotal: 0,
      });
    }
  }

  adjLossBalanceChange() {
    const obj = this.inventoryLossForm.getRawValue();
    this.inventoryLossForm.patchValue({
      adjUnitCost: parseFloat(String(Number(obj.adjUnitCost))).toFixed(5),
      adjTotal: parseFloat(String(Number(obj.adjBalance) * Number(obj.adjUnitCost))).toFixed(5),
      invLossBalance: parseFloat(String(Number(obj.currentBalance) - Number(obj.adjBalance))).toFixed(5),
      invLossUnitCost: parseFloat(String(Number(obj.currentUnitcost) - Number(obj.adjUnitCost))).toFixed(5),
      invLossTotal: parseFloat(String(Number(obj.currentTotal) - (Number(obj.adjBalance) * Number(obj.adjUnitCost)))).toFixed(5),
    });
  }

  adjGainBalanceChange() {
    const obj = this.inventoryGainForm.getRawValue();
    this.inventoryGainForm.patchValue({
      adjUnitCost: parseFloat(String(Number(obj.adjUnitCost))).toFixed(5),
      adjTotal: parseFloat(String(Number(obj.adjBalance) * Number(obj.adjUnitCost))).toFixed(5),
      invGainBalance: parseFloat(String(Number(obj.adjBalance) - Number(obj.currentBalance))).toFixed(5),
      invGainUnitCost: parseFloat(String(Number(obj.adjUnitCost) - Number(obj.currentUnitcost))).toFixed(5),
      invGainTotal: parseFloat(String((Number(obj.adjBalance) * Number(obj.adjUnitCost)) - Number(obj.currentTotal))).toFixed(5),
    });
  }

  SetLocationLevels(defaultLocation, flag) {
    let levels = defaultLocation.split("-");
    levels.forEach((element, index) => {
      switch (index) {
        case 0:
          if (flag == 2) {
            this.inventoryMoveForm.patchValue({ fromlevel1: element });
          }
          break;
        case 1:
          if (flag == 2) {
            this.inventoryMoveForm.patchValue({ fromlevel2: element });
          }
          break;
        case 2:
          if (flag == 2) {
            this.inventoryMoveForm.patchValue({ fromlevel3: element });
          }
          break;
        case 3:
          if (flag == 2) {
            this.inventoryMoveForm.patchValue({ fromlevel4: element });
          }
          break;
      }
    });
  }

  getTrackingList() {
    this.partService.GetPartTrackingList().subscribe(
      (data: any) => {
        if (data.length > 0) {
          this.PartTrackingList = data;
        }
      }
    )
  }

  GetInventoryType() {
    this.inventoryService.GetInventoryType().subscribe(
      (data: any) => {
        this.InvTypeList = data.data;
      }
    )
  }
  GetInventoryAction() {
    this.inventoryService.GetInventoryAction().subscribe(
      (data: any) => {
        this.InvActionListFull = data.data;
        const actionIds = [1, 4, 9, 10];
        this.InvActionList = this.InvActionListFull.filter(item => actionIds.includes(item.actionId));
      }
    )
  }
  GetInventoryActive() {
    this.inventoryService.GetInventoryActive().subscribe(
      (data: any) => {
        this.InvActiveList = data.data;
      }
    )
  }
  GetInventoryStatus() {
    this.inventoryService.GetInventoryStatus().subscribe(
      (data: any) => {
        this.InvStatusList = data.data;
      }
    )
  }
  GetInventoryDocType() {
    this.inventoryService.GetInventoryDocType().subscribe(
      (data: any) => {
        this.InvDocTypeList = data.data;
      }
    )
  }
  GetInventoryReason() {
    this.inventoryService.GetInventoryReason().subscribe(
      (data: any) => {
        if (data.data != null) {
          this.invReasonList = data.data;
          this.invlossReasonList = data.data.filter(x => x.reasonFor == this.constants.reasonList.loss);
          this.invgainReasonList = data.data.filter(x => x.reasonFor == this.constants.reasonList.gain);
          this.invmoveTypeList = data.data.filter(x => x.reasonFor == this.constants.reasonList.movetype);
        }
      }
    )
  }

  GetWareHouseWithChild(companyId: Number) {
    this.companyService.GetWareHouseWithChild(companyId).subscribe(
      (data: any) => {
        if (data.data != null) {
          this.CompanyWareHouseList = data.data;
        }
      }
    )
  }

  GetCompanyDetails(companyId) {
    this.companyService.GetCompanyById(companyId).subscribe(
      (data) => {
        this.loader = false;
        const response: any = data;
        if (response.statusCode == 200) {
          if (response.data.company != null) {
            this.businessChannelList = JSON.parse(response.data.company.businessChannels);
          }
        }
      },
      (error) => {
        this.loader = false;
        this.PopupModal(this.constants.APIError, error);
      }
    );
  }

  GetBatchList(partId: number) {
    this.inventoryService.GetBatchList(this.companyId, partId).subscribe(
      (data: any) => {
        this.BatchList = [];
        if (data.data.length > 0) {
          this.BatchList = data.data;
        }
      },
      (error) => {
        this.PopupModal(this.constants.APIError, error);
      }
    );
  }

  GetLocationName(companyId) {
    this.sharedService.getLocationLevel(companyId, '').subscribe(
      (response: any) => {
        this.InvLocationList = response.dataList;
        // if (this.InvLocationList.dataList.length > 0) {
        //   this.InvLocationList = this.InvLocationList.filter(x => x.locationTypeId == 1);
        // }
      },
      (error) => {
        this.PopupModal(this.constants.APIError, error);
      }
    )
  }

  GetWareHouseValue(id): String {
    if (this.CompanyWareHouseList.length > 0) {
      const wareHouseList = this.CompanyWareHouseList.filter(x => x.id == Number(id));
      if (wareHouseList.length > 0) {
        return wareHouseList[0].name;
      }
    }
    return '';
  }

  GetLocationValue(id): String {
    if (this.InvLocationList.length > 0) {
      const locationList = this.InvLocationList.filter(x => x.id == Number(id));
      if (locationList.length > 0) {
        return locationList[0].locationName;
      }
    }
    return '';
  }

  onBalanceChange() {
    const unitCost = parseFloat(String(Number(this.inventoryManualForm.get('unitcost').value))).toFixed(5);
    const balance: number = Number(this.inventoryManualForm.get('balance').value);
    const result = parseFloat(String(Number(unitCost) * balance)).toFixed(5);
    this.inventoryManualForm.patchValue(
      {
        totalvalue: result,
        unitcost: unitCost
      });
    if (Number(this.infoTracking) == 1) {
      // //create searial number list
      // this.serialNumberList = [];
      // for (let i = 0; i <= balance; i++) {
      //   this.serialNumberList.push(i);
      // }
      // //push data in form array
      // this.clearFormArray(this.SerialNumberGet);
      // for (let x = 0; x < this.serialNumberList.length - 1; x++) {
      //   this.SerialNumberGet.push(this.initSerial());
      // }
    }
  }

  PopupModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }

  GetSerialLotFlyout(inventoryId, trackingId, balance) {
    if (trackingId == 3 || Number(balance) <= 0) {
      this.isSerialFlyout = false;
    } else {
      this.isSerialFlyout = true;
      this.seriallotflyoutObj = {
        page: this.seriallotflyoutObj.page,
        companyId: this.companyId,
        partId: this.partId,
        SKU: this.SKU,
        partName: this.infoItemDescription,
        inventoryId: inventoryId,
        trackingId: trackingId,
        balance: balance,
        trackingObj: this.TrackingObj,
      }
      // this.GetSerialLotList(inventoryId, Number(trackingId), Number(balance));
    }
  }

  closeSerialLotFlyout(object: any) {
    this.TrackingObj = object.trackingObj;
    this.isSerialFlyout = object.isShow;
  }
}
