import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantsService } from 'src/app/constants.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { SalesOrderService } from 'src/app/services/salesOrder/sales-order.service';
import { SharedService } from 'src/app/services/shared.service';
import Swal from 'sweetalert2';
import { FulfillmentService } from '../../services/Fulfillment/fulfillment.service';
import JsBarcode from 'jsbarcode';
import pdfMake from "pdfmake/build/pdfmake";
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-fulfillment',
  templateUrl: './fulfillment.component.html',
  styleUrls: ['./fulfillment.component.css']
})
export class FulfillmentComponent implements OnInit {
  start: number = 500;
  searchTerm: string = '';
  loader: boolean = false;
  FulfillmentDS: any;
  FulfillmentList: any;
  FulfillmentStatusCount: any;
  FulfillmentById: any;
  IsFulfillmentList: boolean = false;
  IsHistoryFlyOut: boolean = false;
  companyId: any;
  rolePermissionsList: any;
  permissionMessage = '';
  userId: any;
  StatusList: any = [];
  OrderHistoryList: any = [];
  FulfillmentForm: FormGroup;
  NetTermsList: any = [];
  StateList: any[] = [];
  CountryList: any[] = [];
  allSelected = false;
  selectedSearchText: string = 'Customer or Sales Order Number'
  @ViewChild('select') select: MatSelect;
  CompanyDetail: any;
  ShipAs: string = '0,1';
  fulfillStatus: string = '0,5,15,30';
  FulfillmentType: string = 'Manual,Auto';
  HistoryFlyOutVal: any;
  constructor(
    public sharedService: SharedService,
    private router: Router,
    private fb: FormBuilder,
    private constants: ConstantsService,
    public companyService: CompanyService,
    public salesOrderService: SalesOrderService,
    public fulfillmentService: FulfillmentService,
    public customerService: CustomerService,
    private globalChangeService: GlobalChangeService,
    private currencyPipe: CurrencyPipe,
    public datepipe: DatePipe,
  ) {
  }
  ngOnInit(): void {
    this.companyId = Number(localStorage.getItem('GlobalSelectedCompanyId'));
    this.createForm();
    this.Constant();
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
      this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 2);
      this.permissionMessage = '';
      if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
        this.rolePermissions()
        this.Status();
        this.getAllState();
        this.GetCountry();
        this.GetFulfillmentList(true, false);
        this.GetFulfillmentStatusCount();
        this.GetCompanyById(this.companyId);
      }
      if (this.permissionMessage != '') {
        this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
      }
      this.userId = localStorage.getItem(this.constants.userId);
    }
    //on change function 
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      window.location.reload();
    })
  }
  // All form Creation
  createForm() {
    this.CreateOrderPartForm();
  }
  Status() {
    this.salesOrderService.GetSOStatusList()
      .subscribe({
        next: (data: any) => {
          this.StatusList = data.dataList
        },
        error: (err) => {
          this.loader = false
        },
      });
  }
  selectedRow(Id: number) {
    this.loader = true;
    this.IsFulfillmentList = true;
    this.GetFulfillPartsByOrderId(Id);
  }
  AddHistoryFlyOut() {
    this.HistoryFlyOutVal = {
      "purchaseOrder": this.FulfillmentById.salesOrder.purchaseOrder,
      "StateList": this.StateList,
      "CountryList": this.CountryList,
      "BalanceDue": this.FulfillmentById.salesOrder.balanceDue,
      "id": this.FulfillmentById.salesOrder.id,
      "orderNumber": this.FulfillmentById.salesOrder.orderNumber,
      "customerPONumber": this.FulfillmentById.salesOrder.customerPONumber,
      "BillToName": this.FulfillmentById.billAddress.name,
      "statusId": this.FulfillmentById.salesOrder.statusId,
      "CompanyId": this.FulfillmentById.salesOrder.companyId,
      "IsOpenFlyOut": true,
      "CompanyDetail": this.CompanyDetail,
    }
    this.IsHistoryFlyOut = false;
    this.IsHistoryFlyOut = true;
  }
  CloseHistoryFlyOut() {
    this.IsHistoryFlyOut = false;
  }
  GetFulfillmentStatusCount() {
    this.fulfillmentService.GetFulfillmentStatusCount(Number(this.companyId))
      .subscribe({
        next: (data: any) => {
          this.FulfillmentStatusCount = data.data;
        },
        error: (err) => {
          this.loader = false
        },
      });
  }
  ChangeSearch() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
    this.select.value.length > 0 ? this.SearchText() : this.selectedSearchText = 'Customer or Sales Order Number';
  }
  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
      this.SearchText();
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
      this.selectedSearchText = 'Customer or Sales Order Number'
    }
  }
  Search(searchTerm: string) {
    this.searchTerm = searchTerm;
    this.GetFulfillmentList(false, false);
  }
  SearchText() {
    this.selectedSearchText = '';
    this.select.value.forEach((element, index) => {
      this.selectedSearchText = this.selectedSearchText + element + ((index === this.select.value.length - 1) ? '' : ' or ')
    });
  }
  Filter() {
    this.GetFulfillmentList(true, false)
  }
  GetFulfillmentList(IsLoader: boolean, isUpdate: boolean) {
    this.loader = IsLoader;
    let fullfillRequest = {
      companyId: Number(this.companyId),
      isActive: 1,
      offsetNo: 0,
      pageSize: this.start,
      searchTerm: this.searchTerm != '' ? this.searchTerm.trim() : '',
      ShipAs: this.ShipAs,
      FulfillmentType: this.FulfillmentType,
      FulfillStatus: this.fulfillStatus,
      Columns: this.select != undefined && this.select.value != undefined ? this.select.value : []
    }
    this.FulfillmentList = [];
    this.fulfillmentService.GetFulfillmentList(fullfillRequest)
      .subscribe({
        next: (data: any) => {
          this.FulfillmentList = data.dataList;
          this.FulfillmentDS = new MatTableDataSource(this.FulfillmentList);
          this.loader = false;
          if (isUpdate) {
            this.updateIndex();
          }
        },
        error: (err) => {
          this.loader = false
        },
      });
  }
  // for lazyload first table
  SalesOrderListScroll(e) {
    const tableViewHeight = e.target.offsetHeight // viewport: ~500px
    const tableScrollHeight = e.target.scrollHeight // length of all table
    const scrollLocation = e.target.scrollTop; // how far user scrolled

    // If the user has scrolled within 200px of the bottom, add more data
    const buffer = 200;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit) {
      this.GetFulfillmentList(false, true);
    }
  }
  updateIndex() {
    this.start = this.FulfillmentList.length + 500;
  }
  removeDuplicates(myArray, Prop) {
    return myArray.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[Prop]).indexOf(obj[Prop]) === pos;
    });
  }
  GetFulfillPartsByOrderId(Id) {
    this.loader = true;
    this.fulfillmentService.GetFulfillPartsByOrderId(Id, true)
      .subscribe({
        next: (data: any) => {
          this.FulfillmentById = data;
          this.FulfillmentById.salesOrder.collectAccount = !this.FulfillmentById.salesOrder.isCollectAmount ? '' : this.FulfillmentById.salesOrder.collectAccount
          this.BindFulfillPart();
        },
        error: (err) => {
          this.loader = false;
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  //Constant value Get
  Constant() {
    this.NetTermsList = this.constants.NetTermsListVal.sort((low, high) => high.id - low.id);
  }
  // Constant Value End
  // Role Permission Start
  rolePermissions() {
    if (!this.rolePermissionsList[0].isView) {
      this.permissionMessage += 'view';
    } if (!this.rolePermissionsList[0].isInsert) {
      if (this.permissionMessage != '') {
        this.permissionMessage += ', ';
      }
      this.permissionMessage += 'add';
    } if (!this.rolePermissionsList[0].isUpdate) {
      if (this.permissionMessage != '') {
        this.permissionMessage += ', ';
      }
      this.permissionMessage += 'edit';
    } if (!this.rolePermissionsList[0].isDelete) {
      if (this.permissionMessage != '') {
        this.permissionMessage += ', ';
      }
      this.permissionMessage += 'delete';
    }
  }
  // Role Permission End

  // Swal Msg Start
  CancelStatusModal() {
    Swal.fire({
      titleText: 'Confirm Canceled Status!',
      text: 'Click the Cancel button to update the status of this order to Canceled or Click the Close button to keep the current status.',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'close',
      showConfirmButton: true,
      showCancelButton: true,
      allowOutsideClick: false
    }).then((result: any) => {
    });
  }
  OrderShippedMsg() {
    Swal.fire({
      html: `
      <div class="row">
    <h1 style="color:red">Order is Shipped.</h1> 
      </div>
     `,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  OrderInvoicedMsg() {
    Swal.fire({
      html: `
      <div class="row">
    <h1 style="color:red">Order is Invoiced or <br/> Exported to Accounting</h1> 
      </div>
     `,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  OrderFulfilledMsg() {
    Swal.fire({
      html: `
      <div class="row">
    <h1 style="color:red">Order is being <br/> (Compltely) fulfilled</h1> 
      </div>
     `,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  PartiallyShippeddMsg() {
    Swal.fire({
      html: `
      <div class="row">
    <h1 style="color:red">Order is Partially <br/> Shipped</h1> 
      </div>
     `,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  // Swal Msg End
  // Form defination start
  get FulfillPart() {
    return (<FormArray>this.FulfillmentForm.get('FulfillPart'));
  }
  AddCurrency(value): string {
    return value != '' ? this.currencyPipe.transform(value, '$', 'symbol', '1.0-2') : '';
  }
  CreateOrderPartForm() {
    this.FulfillmentForm = this.fb.group({
      'FulfillPart': new FormArray([]),
    });
  }
  // Form defination End

  DisableNetTerms(): Number {
    let val = 0;
    if (this.NetTermsList.filter(x => x.name == this.FulfillmentById.netTerms).length) {
      this.NetTermsList.filter(x => x.name == this.FulfillmentById.netTerms)[0].IsDisable = false;
      val = this.NetTermsList.filter(x => x.name == this.FulfillmentById.netTerms)[0].id;
      this.NetTermsList.filter(x => x.name > this.FulfillmentById.netTerms).forEach(element => {
        element.IsDisable = true;
      });
    } else {
      val = 0;
    }
    return val;
  }
  BindFulfillPart() {
    this.FulfillPart.clear();
    this.FulfillmentById.fulfillPartsVM.forEach((element, index) => {
      this.FulfillPart.push(new FormGroup({
        id: new FormControl(element.id),
        vendorId: new FormControl(element.vendorId),
        salesOrderId: new FormControl(element.salesOrderId),
        partId: new FormControl(element.partId),
        partNumber: new FormControl(element.partNumber),
        partName: new FormControl(element.partName),
        quantity: new FormControl(element.quantity),
        needed: new FormControl(element.needed),
        shipped: new FormControl(element.shipped),
        available: new FormControl(element.available),
        bo: new FormControl(element.bo),
        ead: new FormControl(element.ead),
        invQty: new FormControl({ value: (element.invQty == 0 || element.invQty == null) ? undefined : element.invQty, disabled: (element.invQty == 0 || element.invQty == null || element.isFulfilled) ? true : false }),
        boQty: new FormControl({ value: (element.boQty == 0 || element.boQty == null) ? undefined : element.boQty, disabled: (element.boQty == 0 || element.boQty == null || element.isFulfilled) ? true : false }),
        npoQty: new FormControl({ value: (element.npoQty == 0 || element.npoQty == null) ? undefined : element.npoQty, disabled: (element.needed == 0 || element.needed == null || element.statusId == 5 || element.isFulfilled) ? true : false }),
        shipAll: new FormControl({ value: element.shipAll, disabled: (element.isFulfilled) ? true : element.shipAll }),
        statusId: new FormControl(element.statusId),
        childFulfillParts: new FormArray([]),
      }))
      if (element.childFulfillPartsVM != null) {
        this.BindChildFulfillParts(element.childFulfillPartsVM, index);
      }
    });
    this.loader = false;
  }
  InvQty(Qty: number, type: string, i: number) {

    if (type == 'invQty') {
      this.FulfillPart.value[i].invQty = (Qty >= this.FulfillPart.value[i].needed ? this.FulfillPart.value[i].needed : Number(Qty));
      if (this.FulfillPart.value[i].available > this.FulfillPart.value[i].needed && this.FulfillPart.value[i].invQty != this.FulfillPart.value[i].needed) {
        this.FulfillPart.value[i].shipAll = false;
      } else if (this.FulfillPart.value[i].available > this.FulfillPart.value[i].needed && this.FulfillPart.value[i].invQty == this.FulfillPart.value[i].needed) {
        this.FulfillPart.value[i].shipAll = true;
      }
      this.FulfillmentForm.get('FulfillPart')['controls'][i].patchValue(this.FulfillPart.value[i])
      this.IsexceedsQty(i) ? this.FulfillPart.controls[i].get('invQty').setErrors({ 'Exceed': true }) : this.FulfillPart.controls[i].get('invQty').setErrors(null)
    } else if (type == 'boQty') {
      this.FulfillPart.value[i].boQty = (Qty >= this.FulfillPart.value[i].bo ? this.FulfillPart.value[i].bo : Number(Qty));
      this.FulfillmentForm.get('FulfillPart')['controls'][i].patchValue(this.FulfillPart.value[i])
      this.IsexceedsQty(i) ? this.FulfillPart.controls[i].get('boQty').setErrors({ 'Exceed': true }) : this.FulfillPart.controls[i].get('boQty').setErrors(null)
    } else if (type == 'npoQty') {
      this.FulfillPart.value[i].npoQty = (Qty >= this.FulfillPart.value[i].needed ? this.FulfillPart.value[i].needed : Number(Qty));
      this.FulfillmentForm.get('FulfillPart')['controls'][i].patchValue(this.FulfillPart.value[i])
      this.IsexceedsQty(i) ? this.FulfillPart.controls[i].get('npoQty').setErrors({ 'Exceed': true }) : this.FulfillPart.controls[i].get('npoQty').setErrors(null)
    }
  }
  ShipAll(IsShipAll: boolean, i: number, IsChecked: boolean) {
    if (!IsShipAll && this.FulfillPart.value[i].available > this.FulfillPart.value[i].needed) {
      this.FulfillPart.value[i].invQty = this.FulfillPart.value[i].needed;
      this.FulfillPart.value[i].shipAll = IsChecked;
      this.FulfillmentForm.get('FulfillPart')['controls'][i].patchValue(this.FulfillPart.value[i])
    } else if (IsShipAll) {
      this.FulfillPart.value.forEach(q => {
        if (q.available > q.needed) {
          q.invQty = q.needed
          q.shipAll = true
        }
      });
      this.FulfillmentForm.patchValue({ FulfillPart: this.FulfillPart.value });
    }
  }
  IsNum(value: any): number {
    return value === null || value === undefined ? 0 : value;
  }
  IsexceedsQty(i: number): boolean {
    return this.FulfillPart.value[i].needed >= (this.IsNum(this.FulfillPart.value[i].invQty) + this.IsNum(this.FulfillPart.value[i].boQty) + this.IsNum(this.FulfillPart.value[i].npoQty)) ? false : true
  }
  Cancel() {
    this.BindFulfillPart();
  }
  Save() {
    if (this.FulfillmentForm.valid) {
      this.fulfillmentService.UpdFulfillment(this.FulfillPart.value)
        .subscribe({
          next: (data: any) => {
            this.loader = false;
            this.selectedRow(this.FulfillmentById.salesOrder.id)
          },
          error: (err) => {
            this.loader = false
            this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
          },
        });
    }
  }
  FulFillOrder() {
    if (this.FulfillmentForm.valid) {
      this.loader = true;
      this.fulfillmentService.FulFillOrder(this.FulfillPart.value, this.FulfillmentById.salesOrder.id)
        .subscribe({
          next: (data: any) => {
            this.selectedRow(this.FulfillmentById.salesOrder.id)
            this.constants.SwalModal(this.constants.Success, data.successMessage);
          },
          error: (err) => {
            this.loader = false
            this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
          },
        });
    }
  }
  childFulfillParts(index: number): FormArray {
    return this.FulfillPart.at(index).get('childFulfillParts') as FormArray
  }
  BindChildFulfillParts(childFulfillParts: any, i: number) {
    childFulfillParts.forEach((q) => {
      this.childFulfillParts(i).push(new FormGroup({
        id: new FormControl(q.id),
        salesOrderId: new FormControl(q.salesOrderId),
        partId: new FormControl(q.partId),
        orderTypeId: new FormControl(q.orderTypeId),
        shipped: new FormControl(q.shipped),
        shippedDate: new FormControl(q.shippedDate),
        tracking: new FormControl(q.tracking),
        shipAll: new FormControl({ value: q.shipAll, disabled: q.statusId == 5 ? true : false }),
        statusId: new FormControl(q.statusId),
        IsShow: new FormControl(false)
      }))
    });
  }
  //Get All State
  getAllState() {
    this.salesOrderService.getAllState()
      .subscribe({
        next: (data: any) => {
          this.StateList = data;
        },
        error: (err) => {
          this.loader = false
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  //Get All Country
  GetCountry() {
    this.sharedService.GetCountry()
      .subscribe({
        next: (data: any) => {
          this.CountryList = data.dataList;
        },
        error: (err) => {
          this.loader = false
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  BackToList() {
    this.FulfillPart.clear();
    this.FulfillmentById = undefined;
    this.IsFulfillmentList = false;
    this.searchTerm = '';
    this.GetFulfillmentList(true, false);
  }
  ShowRow(i: number, value: boolean) {
    this.childFulfillParts(i).value.forEach(IsShow => {
      IsShow.IsShow = value;
    });
    this.FulfillmentForm.patchValue({ FulfillPart: this.FulfillPart.value })
  }
  GetCountryCode(countryId: number) {
    return (this.CountryList.length > 0 && countryId != null && this.CountryList.filter(x => x.id == countryId).length > 0) ? this.CountryList.filter(x => x.id == countryId)[0].countryCode : ''
  }
  GetStateCode(stateId: number) {
    return (this.StateList.length > 0 && Number(stateId) && this.StateList.filter(x => x.id == stateId).length > 0) ? this.StateList.filter(x => x.id == stateId)[0].abbreviation : ''
  }
  GetStatusName(Id: number) {
    return this.constants.FulfillmentStatus.length > 0 && Id != 0 && this.constants.FulfillmentStatus.filter(x => x.id == Id).length > 0 ? this.constants.FulfillmentStatus.filter(x => x.id == Id)[0].name : ''
  }
  IsCollapseAll(value: boolean) {
    this.FulfillPart.value.forEach(ql => {
      ql.childFulfillParts.forEach(IsShow => {
        IsShow.IsShow = value;
      });
    });
    this.FulfillmentForm.patchValue({ FulfillPart: this.FulfillPart.value });
  }
  GetCompanyById(compId: number) {
    this.companyService.GetCompanyById(compId).subscribe(
      (data: any) => {
        this.CompanyDetail = data.data.company;
      }
    ), (err) => {
      this.loader = false;
    };
  }
  ISNullOrEmpty(value: any): boolean {
    let result = false;
    if (value === null || value === undefined || value === '') {
      result = true;
    }
    return result;
  }
}