import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-category-dropdown',
  templateUrl: './category-dropdown.component.html',
  styleUrls: ['./category-dropdown.component.css']
})
export class CategoryDropdownComponent {
  loader: boolean = false;
  programs: Program[] = [];
  @Input() CompanyId: Number;
  @Input() CatId: Number;
  @Output() CategoryIds = new EventEmitter<number>();

  constructor(public sharedService: SharedService,) {

  }
  ngOnInit(): void {
    this.getAllCategories_search()
  }
  getAllCategories_search() {
    this.loader = true;
    this.sharedService.GetDropdownCategoryHierarchyModel(Number(this.CompanyId)).subscribe(
      (data: any) => {
        this.loader = false;
        this.programs = data.dataList;
      });
  }
  onParentCatChange(value: any): void {
    this.CategoryIds.emit(value.value);
  }
}
interface Program {
  value: number;
  text: string;
  isActive?: boolean;
  isEcommerece?: boolean;
  wordpressId?: number;
  children?: Program[];
}
