import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/constants.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { InventoryService } from 'src/app/services/inventory/inventory.service';
import { SharedService } from 'src/app/services/shared.service';
import pdfMake from "pdfmake/build/pdfmake";
import Swal from 'sweetalert2';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { IInventoryItems, IInventoryItemWithCategoryId } from 'src/app/shared/inventoryItem';
import { PartService } from 'src/app/services/part/part.service';
import { MatTableDataSource } from '@angular/material/table';


interface Program {
  value: number;
  text: string;
  isActive?: boolean;
  isEcommerece?: boolean;
  wordpressId?: number;
  children?: Program[];
}
interface Report {
  available: any;
  backOrdered: any;
  boAvailable: any;
  boCommitted: any;
  boTotalCost: any;
  committed: any;
  inventoryType: any;
  itemDescription: any;
  IndividualPart: any;
  onHand: any;
  totalCost: any;
  uom: any;
}

@Component({
  selector: 'app-all-reports',
  templateUrl: './all-reports.component.html',
  styleUrls: ['./all-reports.component.css']
})
export class AllReportsComponent implements OnInit {
  currentPage: string = 'Report';
  loader: boolean = false;
  ReportType: { id: number; name: string; }[];
  FilterReportData: { id: number; name: string; }[];
  Action: { id: number; name: string; }[];
  InvTypeList: any = [];
  invMasterList: any;
  start: number = 0;
  limit: number = 15;
  end: number = this.limit + this.start;
  companyId: number = 0;
  CompanyWareHouseList: any = [];
  InvLocationList: any = [];
  OnHand: any = 0;
  OnHandTotal: any = 0.00;
  Available: any = 0;
  AvailableTotal: any = 0.00;
  Committed: any = 0;
  CommittedTotal: any = 0.00;
  BackOrdered: any = 0;
  BackOrderedTotal: any = 0.00;
  invListColumns: string[] = this.constants.invListReportColumns;
  partListColumns: string[] = this.constants.partColumnReports;
  partReprtColumn: string[] = this.constants.partColumnReport;
  PartAction: any[] = this.constants.actionList;
  ReportColumn: string[] = this.constants.InventoryReportColumn;
  PartColumn: string[] = this.constants.PartReportColumn;
  inventoryLength: any;
  formula: string = 'Inventory Report';
  partFormula: string = 'Part Report';
  invReportList: any[] = [];
  action: number;
  SumTotalCost: string;
  SumBOCost: string;
  MasterInvType: number[] = [0];
  InvSearchItem: string = '';
  data: Object;
  showInvTable: boolean = false;
  allInventory: boolean;
  isShowFilter: boolean;
  filterPartName: boolean;
  selectedMulti = [];
  programs: Program[] = [];
  CategoryArr = [];
  CheckCategory: boolean;
  CheckCategoryDisable: boolean = false;
  arr: any[];
  isShowCategory: boolean;
  value_search: number;
  getAction: any;
  isShowCategoryData: boolean = false;
  getReportTitle: any;
  showPartTable: boolean = false;
  CSVReportList: any;
  filterName: any;
  selectedArr: any[] = [];
  selectedCatName: any[];
  bindPdfData: any;
  partReportList: any[] = [];
  isShowVendorName: boolean = false;
  categoryIds: any[];
  partReportCSVList: any[];

  constructor(
    private inventoryService: InventoryService,
    private constants: ConstantsService,
    private globalChangeService: GlobalChangeService,
    private companyService: CompanyService,
    private sharedService: SharedService,
    private currencyPipe: CurrencyPipe,
    private partService: PartService,
  ) {
    this.ReportType = this.constants.ReportTypes;
    this.Action = this.constants.actionList;
    this.GetInventoryType();
  }

  ngOnInit(): void {
    this.showInvTable = false;
    this.isShowCategory = false;
    // get company by default set value
    var company = this.globalChangeService.getGlobalCompany();
    if (company > 0) {
      this.CompanyDepedsFn(company);
      this.GetCatList(company);
      // this.getMasterTableData(this.companyId, this.start, this.end, false);
    } else {
      this.PopupModal(this.constants.Error, this.constants.companyId);
    }
    //on change function 
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      this.start = 0;
      this.limit = 15;
      this.end = this.limit + this.start;
      this.CompanyDepedsFn(data);
      this.onValueChange_search(data);

      // this.getMasterTableData(this.companyId, this.start, this.end, false);
    })
  }

  onValueChange_search(value: any): void {
    this.value_search = value;
    this.GetCatList(value);
  }

  GetInventoryType() {
    this.inventoryService.GetInventoryType().subscribe(
      (data: any) => {
        this.InvTypeList = data.data;
      }
    )
  }

  GetInventoryTypeValue(id): string {
    if (this.InvTypeList.length > 0 && id > 0) {
      const list = this.InvTypeList.filter(x => x.typeId == Number(id));
      if (list.length > 0) {
        return list[0].typeValue;
      }
    }
    return '';
  }

  CompanyDepedsFn(comapanyId: number) {
    this.companyId = comapanyId;
    this.GetWareHouseWithChild(this.companyId);
    this.CalculateInventoryQuantity(this.companyId);
    this.GetLocationName();
    this.invMasterList = [];
  }


  GetWareHouseWithChild(companyId: Number) {
    this.companyService.GetWareHouseWithChild(companyId).subscribe(
      (data: any) => {
        if (data.data != null) {
          this.CompanyWareHouseList = data.data;
        }
      }
    )
  }

  CalculateInventoryQuantity(companyId) {
    this.inventoryService.GetCalculateInventoryQuantity(companyId).subscribe(
      (data: any) => {
        this.loader = false;
        const response = data.data;
        if (response != null) {
          this.OnHand = response.onHand;
          this.OnHandTotal = response.onHandTotal;
          this.Available = response.available;
          this.AvailableTotal = response.availableTotal;
          this.Committed = response.committed;
          this.CommittedTotal = response.committedTotal;
          this.BackOrdered = response.backOrdered;
          this.BackOrderedTotal = response.backOrderedTotal;
        }
      },
      (error) => {
        this.loader = false;
        this.PopupModal(this.constants.APIError, error);
      }
    );
  }

  GetLocationName() {
    this.sharedService.getLocationLevel(this.companyId, '').subscribe(
      (response) => {
        this.InvLocationList = response;
        if (this.InvLocationList.dataList.length > 0) {
          this.InvLocationList = this.InvLocationList.dataList.filter(x => x.locationTypeId == 1);
        }
      },
      (error) => {
        this.PopupModal(this.constants.APIError, error);
      }
    )
  }

  // for lazyload first table
  onInventoryScroll(e) {
    const tableViewHeight = e.target.offsetHeight;
    const tableScrollHeight = e.target.scrollHeight;
    const scrollLocation = e.target.scrollTop;
    // If the user has scrolled within 200px of the bottom, add more data
    const buffer = 200;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit) {
      // this.getMasterTableData(this.companyId, this.start, this.end, true);
      this.getMasterTableData(this.companyId, this.start, this.end, false);
    }
  }

  getMasterTableData(companyId, start, end, isUpdate) {
    const obj: IInventoryItems = {
      CompanyId: companyId,
      SearchTerm: this.InvSearchItem,
      InvetoryTypes: this.MasterInvType
    }
    this.loader = true;
    this.inventoryService.GetInventoryItems(obj).subscribe(
      (data: any) => {
        this.loader = false;
        this.data = data;
        this.invMasterList = [];
        this.invMasterList = this.invMasterList.concat(data.data);
        this.invMasterList = this.RemoveDuplicates(this.invMasterList, 'item');
        this.inventoryLength = this.invMasterList.length;
        this.SumTotalCost = this.invMasterList.reduce((acc, current) => acc + current.totalCost, 0);
        this.SumBOCost = this.invMasterList.reduce((acc, current) => acc + current.boTotalCost, 0);
        if (isUpdate) {
          this.updateIndex();
        }
      },
      error => {
        this.loader = false;
        this.PopupModal(this.constants.APIError, error);
      });
  }

  RemoveDuplicates(myArray, Prop) {
    return myArray.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[Prop]).indexOf(obj[Prop]) === pos;
    });
  }
  updateIndex() {
    this.start = this.end + 1;
    this.end = this.limit + this.start;
  }

  PopupModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }

  GetActionName(id: any) {
    this.action = id;
    if (this.getReportTitle == 'Inventory') {
      this.GetInvReportData();
    } else if (this.getReportTitle == 'Parts') {
      this.GetDetailPartReport();
    }
  }

  exportCSV() {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Inventory Report',
      useTextFile: false,
      useBom: true,
      headers: this.ReportColumn
    };
    if (this.invReportList.length > 0) {
      const fileInfo = new ngxCsv(this.invReportList, this.formula, options);
    }
  }

  exportCSVPart() {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Part Report',
      useTextFile: false,
      useBom: true,
      headers: this.partReprtColumn
    };
    if (this.partReportCSVList.length > 0) {
      // let immutableArray = this.partReportList.map(({id, categoriesIds, ...rest})=> rest);
      const fileInfo = new ngxCsv(this.partReportCSVList, this.partFormula, options);
    }
  }

  GetInvReportData() {
    const obj: IInventoryItemWithCategoryId = {
      CompanyId: this.companyId,
      StartIndex: this.start,
      EndIndex: 2000,
      SearchTerm: this.InvSearchItem,
      InvetoryTypes: this.MasterInvType,
      CategoryIds: this.CategoryArr.length != 0 ? this.CategoryArr : []
    }
    this.loader = true;
    this.inventoryService.GetInventoryReport(obj).subscribe(
      (data: any) => {
        this.loader = false;
        this.invReportList = data.data;
        if (this.action == 1) {
          this.CreateInventoryPdf();
        } else if (this.action == 2) {
          this.exportCSV();
        }
      },
      error => {
        this.loader = false;
        this.PopupModal(this.constants.APIError, error);
      });
  }

  CreateInventoryPdf() {
    if (this.CategoryArr.length > 0) {
      let idCategoryArray = new Set(this.CategoryArr);
      let catNames = this.arr.filter(item => idCategoryArray.has(item.id)).map(item => item.name);
      this.bindPdfData = catNames.join(", ");
    }
    const docDefinition = {
      pageOrientation: 'landscape',
      content: [
        { text: "Inventory Report", style: "header", alignment: "center" },
        { text: this.filterName + ': ' + this.bindPdfData, style: "subheader", alignment: 'right' },
        {
          style: "tableExample",
          table: {
            body: [
              [
                { text: 'Item Number', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'Item Description', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'Inventory Type', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'On Hand', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'Available', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'Committed', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'Total Cost', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'Back Ordered', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'B/O Available', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'B/O Committed', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'B/O TotalCost', fontSize: 10, bold: true, alignment: 'left' }
              ],
              ...this.invMasterList.map((p1, i) => ([
                // { text: i + 1 , fontSize: 10, alignment: 'left' },
                { text: p1.itemNumber, fontSize: 10, alignment: 'left' },
                { text: p1.itemDescription, fontSize: 10, alignment: 'left' },
                { text: this.GetInventoryTypeValue(p1.inventoryType), fontSize: 10, alignment: 'left' },
                { text: p1.onHand, fontSize: 10, alignment: 'right' },
                { text: p1.available, fontSize: 10, alignment: 'right' },
                { text: p1.committed, fontSize: 10, alignment: 'right' },
                // { text: p1.totalCost.includes("$") ? p1.totalCost : this.currencyPipe.transform(p1.totalCost), fontSize: 10, alignment: 'right' },
                { text: this.currencyPipe.transform(p1.totalCost), fontSize: 10, alignment: 'right' },
                { text: p1.backOrdered, fontSize: 10, alignment: 'right' },
                { text: p1.boAvailable, fontSize: 10, alignment: 'right' },
                { text: p1.boCommitted, fontSize: 10, alignment: 'right' },
                // { text: p1.boTotalCost.includes("$") ? p1.boTotalCost : this.currencyPipe.transform(p1.boTotalCost), fontSize: 10, alignment: 'right' },
                { text: this.currencyPipe.transform(p1.boTotalCost), fontSize: 10, alignment: 'right' }
              ])),
            ]
          }
        },
        { text: this.SumTotalCost != '' || undefined ? this.currencyPipe.transform(this.SumTotalCost) : '$0.00', style: "totalCost", fontSize: 10, bold: true },
        { text: this.SumBOCost != '' || undefined ? this.currencyPipe.transform(this.SumBOCost) : '$0.00', style: "totalBOCost", fontSize: 10, bold: true },
      ], footer: (currentPage, pageCount) => {
        var t = {
          layout: "noBorders",
          margin: [680, 10, 0, 0],
          table: {
            widths: ["*", "*"],
            body: [
              [
                { text: "Page  " + currentPage.toString() + " of " + pageCount, fontSize: 10, alignment: 'right' },
              ]
            ]
          }
        };
        return t;
      },
      styles: {
        header: {
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 12,
          bold: false,
          margin: [0, 0, 0, 5]
        },
        tableExample: {
          margin: [0, 5, 0, 10]
        },
        totalCost: {
          margin: [492, 0, 0, 10]
        },
        totalBOCost: {
          margin: [710, -21, 0, 10]
        }
      }
    };
    pdfMake.createPdf(docDefinition).print();
  }

  CreateInventoryPdfParts() {
    if (this.CategoryArr.length > 0) {
      let idCategoryArray = new Set(this.CategoryArr);
      let catNames = this.arr.filter(item => idCategoryArray.has(item.id)).map(item => item.name);
      this.bindPdfData = catNames.join(", ");
    }
    const docDefinition = {
      pageOrientation: 'landscape',
      content: [
        { text: "Part Report", style: "header", alignment: "center" },
        { text: this.filterName + ': ' + this.bindPdfData, style: "subheader", alignment: 'right' },
        {
          style: "tableExample",
          table: {
            body: [
              [
                { text: 'List Part #', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'List Part Name', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'Vendor Part #', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'Vendors Part Name(s)', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'Part Cost', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'List Price', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'Category(s)', fontSize: 10, bold: true, alignment: 'left' },
                { text: 'Vendor(s)', fontSize: 10, bold: true, alignment: 'left' },
              ],
              ...this.partReportList.map((p1, i) => ([
                { text: p1.partNo, fontSize: 10, alignment: 'left' },
                { text: p1.name, fontSize: 10, alignment: 'left' },
                { text: p1.vendorPartNumber, fontSize: 10, alignment: 'left' },
                { text: p1.vendorPartName, fontSize: 10, alignment: 'right' },
                { text: this.currencyPipe.transform(p1.cost), fontSize: 10, alignment: 'right' },
                { text: this.currencyPipe.transform(p1.vendorPartCost), fontSize: 10, alignment: 'right' },
                { text: p1.categories, fontSize: 10, alignment: 'right' },
                { text: p1.vendorName, fontSize: 10, alignment: 'right' },
              ])),
            ]
          }
        },
        { text: this.SumTotalCost != '' || undefined ? this.currencyPipe.transform(this.SumTotalCost) : '$0.00', style: "totalCost", fontSize: 10, bold: true },
        { text: this.SumBOCost != '' || undefined ? this.currencyPipe.transform(this.SumBOCost) : '$0.00', style: "totalBOCost", fontSize: 10, bold: true },
      ], footer: (currentPage, pageCount) => {
        var t = {
          layout: "noBorders",
          margin: [680, 10, 0, 0],
          table: {
            widths: ["*", "*"],
            body: [
              [
                { text: "Page  " + currentPage.toString() + " of " + pageCount, fontSize: 10, alignment: 'right' },
              ]
            ]
          }
        };
        return t;
      },
      styles: {
        header: {
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 12,
          bold: false,
          margin: [0, 0, 0, 5]
        },
        tableExample: {
          margin: [0, 5, 0, 10]
        },
        totalCost: {
          margin: [370, 0, 0, 10]
        },
        totalBOCost: {
          margin: [440, -21, 0, 10]
        }
      }
    };
    pdfMake.createPdf(docDefinition).print();
  }

  GetReportTypeName(event: any) {
    if (event == 'Inventory') {
      this.getReportTitle = event;
      this.showInvTable = true;
      this.FilterReportData = this.constants.FilterReports;
    } else if (event == 'Parts') {
      this.getReportTitle = event;
      this.FilterReportData = this.constants.PartFilterReports;
      this.showInvTable = false;
      this.allInventory = false;
      this.invMasterList = [];
      this.isShowCategory = false;
    }
  }

  GetFilterType(event: any, name: any) {
    if (this.getReportTitle === 'Inventory') {
      this.filterName = name;
      this.getAction = event;
      switch (event) {
        case 1:
          this.allInventory = true;
          this.isShowFilter = false;
          this.isShowCategory = false;
          this.CategoryArr = [];
          this.selectedMulti = [];
          this.InvSearchItem = '';
          this.bindPdfData = '';
          this.isShowVendorName = false;
          break;
        case 2:
          this.allInventory = true;
          this.isShowFilter = true;
          this.filterPartName = true;
          this.isShowCategory = false;
          this.CategoryArr = [];
          this.selectedMulti = [];
          this.isShowVendorName = false;
          break;
        case 3:
          this.isShowFilter = false;
          this.isShowCategory = true;
          this.CategoryArr = [];
          this.selectedMulti = [];
          this.InvSearchItem = '';
          this.isShowVendorName = false;
          this.getAllCategories_search(this.companyId);
      }
    } else if (this.getReportTitle === 'Parts') {
      this.filterName = name;
      this.getAction = event;
      switch (event) {
        case 1:
          this.isShowCategory = false;
          this.isShowFilter = false;
          this.CategoryArr = [];
          this.selectedMulti = [];
          this.isShowVendorName = false;
          this.bindPdfData = '';
          break;
        case 2:
          this.isShowFilter = false;
          this.isShowCategory = true;
          this.CategoryArr = [];
          this.selectedMulti = [];
          this.isShowVendorName = false;
          this.getAllCategories_search(this.companyId);
          break;
        case 3:
          this.filterPartName = false;
          this.isShowFilter = true;
          this.isShowCategory = false;
          this.isShowVendorName = true;
          this.CategoryArr = [];
          this.selectedMulti = [];
      }
    }
  }


  GetFilterUsingSearch(value: any) {
    this.InvSearchItem = value
    this.bindPdfData = this.InvSearchItem;
  }

  GetInvList() {
    if (this.showInvTable == true && this.allInventory == true && this.getAction == 1) {
      this.isShowCategoryData = false;
      this.getMasterTableData(this.companyId, this.start, this.end, false);
    } else if (this.filterPartName == true && this.isShowFilter == true && this.getAction == 2) {
      this.isShowCategoryData = false;
      this.getMasterTableData(this.companyId, this.start, this.end, false);
    } else if (this.getAction == 3) {
      this.isShowCategoryData = true;
      this.GetDetailForCategory();
    } else if (this.showInvTable == false && this.allInventory == false && this.getReportTitle == 'Parts' && this.isShowVendorName == false) {
      this.showPartTable = true;
      this.GetPartListForReports(this.companyId, "")
    } else if (this.showInvTable == false && this.allInventory == false && this.getReportTitle == 'Parts' && this.isShowVendorName == true) {
      this.showPartTable = true;
      this.GetPartListForReports(this.companyId, this.InvSearchItem)
    }
  }

  GetDetailForCategory() {
    const obj: IInventoryItemWithCategoryId = {
      CompanyId: this.companyId,
      StartIndex: this.start,
      EndIndex: 2000,
      SearchTerm: this.InvSearchItem,
      InvetoryTypes: this.MasterInvType,
      CategoryIds: this.CategoryArr.length != 0 ? this.CategoryArr : []
    }
    this.loader = true;
    this.inventoryService.GetInventoryReport(obj).subscribe(
      (data: any) => {
        this.loader = false;
        this.showInvTable = true;
        this.allInventory = true;
        this.invMasterList = data.data;
        this.SumTotalCost = this.invMasterList.reduce((acc, current) => acc + current.totalCost, 0);
        this.SumBOCost = this.invMasterList.reduce((acc, current) => acc + current.boTotalCost, 0);
      },
      error => {
        this.loader = false;
        this.PopupModal(this.constants.APIError, error);
      });
  }

  getAllCategories_search(companyId) {
    this.loader = true;
    this.sharedService.GetDropdownCategoryHierarchyModel(companyId).subscribe(
      (data: any) => {
        this.loader = false;
        this.data = data;
        this.programs = data.dataList;
      });
  }

  GetCatList(value) {
    this.loader = true;
    this.sharedService.getAllCatogaryWithProduction(value, '').subscribe((data: any) => {
      this.arr = data.dataList;
      this.loader = false;
    })
  }

  onParentCatChange(value: any): void {
    this.CategoryArr = value.value;
    let partcatarr = [];
    if (this.CategoryArr.length > 0) {
      this.CategoryArr.forEach(element => {
        const catList = this.arr.filter(x => x.id == element && x.wordpressId > 0);
        if (catList.length > 0) {
          partcatarr.push(catList)
          this.selectedMulti = partcatarr;
        }
      });
    }
    if (partcatarr.length > 0) {
      this.CheckCategory = true;
      this.CheckCategoryDisable = false;
      partcatarr = [];
    }
    else {
      this.CheckCategory = false;
      this.CheckCategoryDisable = true;
    }
  }

  GetPartListForReports(companyId: any, searchterm: any) {
    this.loader = true;
    this.categoryIds = this.CategoryArr.length != 0 ? this.CategoryArr : [];
    let obj = {
      CompanyId: companyId,
      Searchterm: searchterm,
      CategoryIds: this.categoryIds
    }
    this.partService.GetAllPartForReports(obj).subscribe((data: any) => {
      this.loader = false;
      this.partReportList = [];
      this.partReportList = this.partReportList.concat(data.dataList);
      this.partReportList = this.RemoveDuplicates(this.partReportList, 'id');
      this.SumTotalCost = this.partReportList.reduce((acc, current) => acc + current.cost, 0);
      this.SumBOCost = this.partReportList.reduce((acc, current) => acc + current.vendorPartCost, 0);
    },
      (error) => {
        this.loader = false;
        this.PopupModal(this.constants.APIError, error);
      })
  }

  GetDetailPartReport() {
    if (this.action == 1) {
      this.CreateInventoryPdfParts();
    } else if (this.action == 2) {
      this.GetDataForCSV();
    }
  }

  GetDataForCSV() {
    this.loader = true;
    this.categoryIds = this.CategoryArr.length != 0 ? this.CategoryArr : [];
    let obj = {
      CompanyId: this.companyId,
      Searchterm: this.InvSearchItem,
      CategoryIds: this.categoryIds
    }
    this.partService.GetAllPartReportsCSV(obj).subscribe((data: any) => {
      this.loader = false;
      this.partReportCSVList = [];
      this.partReportCSVList = this.partReportCSVList.concat(data.dataList);
      this.exportCSVPart();
    },
      (error) => {
        this.loader = false;
        this.PopupModal(this.constants.APIError, error);
      })
  }
}
