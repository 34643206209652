import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PartTilesComponent } from 'src/app/admin/common-components/part-tiles/part-tiles.component';
import { ConstantsService } from 'src/app/constants.service';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { PartService } from 'src/app/services/part/part.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-part-list',
  templateUrl: './part-list.component.html',
  styleUrls: ['./part-list.component.css']
})
export class PartListComponent implements OnInit {
  @ViewChild('partTilesRef') partTiles: PartTilesComponent;
  addNewbtnText: string = "Add New Part";
  loader: boolean = false;
  previousPage: string = '';
  currentPage: string = 'Part List';
  searchplaceholder: string = 'Search by Part Number or Description';
  companyId: number = 0;
  listColumns: string[] = ['item', 'partNo', 'name', 'uom', 'onhand', 'available', 'committed', 'backOrdered', 'unitcost']
  masterList: any = [];
  start: number = 0;
  limit: number = 15;
  end: number = this.limit + this.start;
  SearchCategoryies: any = this.constants.SearchCategoryies;

  constructor(private globalChangeService: GlobalChangeService, private constants: ConstantsService, private router: Router, private partService: PartService) {

  }
  ngOnInit(): void {
    // get company by default set value
    var company = this.globalChangeService.getGlobalCompany();
    if (company > 0) {
      this.companyId = company;
      this.GetList(0, 15, false);
    } else {
      this.PopupModal(this.constants.Error, this.constants.companyId);
    }
    //on change function 
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      this.companyId = data;
      this.partTiles.CalculateInventoryQuantity(this.companyId);
      this.GetList(0, 15, false);
    });
  }

  PopupModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }

  AddNewPart() {
    this.router.navigate(['/part-details']);
  }

  SelectedRow(row) {
    this.router.navigate(['/part-details', row.id])
  }

  Search(text: string) {
    this.masterList = [];
    this.GetList(0, 15, false, text);
  }

  // for lazyload first table
  onScroll(e) {
    const tableViewHeight = e.target.offsetHeight;
    const tableScrollHeight = e.target.scrollHeight;
    const scrollLocation = e.target.scrollTop;
    // If the user has scrolled within 200px of the bottom, add more data
    const buffer = 200;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit) {
      this.GetList(this.start, this.end, true);
    }
  }

  GetList(start: number, end: number, isUpdate: boolean, searchText?: string) {
    this.partService.GetAllPart(this.companyId, searchText == undefined ? '' : searchText, start, end, 1).subscribe(
      (data: any) => {
        this.masterList = this.masterList.concat(data.dataList);
        this.masterList = this.RemoveDuplicates(this.masterList, 'id');
        if (isUpdate) {
          this.UpdateIndex();
        }
      },
      error => {
        this.PopupModal(this.constants.Error, this.constants.SomethingWentWrong);
      });
  }

  RemoveDuplicates(myArray, Prop) {
    return myArray.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[Prop]).indexOf(obj[Prop]) === pos;
    });
  }

  UpdateIndex() {
    this.start = this.end + 1;
    this.end = this.limit + this.start;
  }
}
