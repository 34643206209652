import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantsService } from 'src/app/constants.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { InventoryService } from 'src/app/services/inventory/inventory.service';
import { SharedService } from 'src/app/services/shared.service';
import { IInventoryItems } from 'src/app/shared/inventoryItem';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-inventory-list',
  templateUrl: './inventory-list.component.html',
  styleUrls: ['./inventory-list.component.css','../inventory-details.component.css']
})
export class InventoryListComponent implements OnInit{
  loader: boolean = false;
  data: any;
  OnHand: any = 0;
  OnHandTotal: any = 0.00;
  Available: any = 0;
  AvailableTotal: any = 0.00;
  Committed: any = 0;
  CommittedTotal: any = 0.00;
  BackOrdered: any = 0;
  BackOrderedTotal: any = 0.00;
  MasterInvType: number[] = [0];
  InvSearchItem: string = '';
  invMasterList: any = [];
  rolePermissionsList: any;
  permissionMessage: string;
  start: number = 0;
  limit: number = 50;
  end: number = this.limit + this.start;
  InventoryType: any = [];
  TimePeriodList: any = [];
  companyId: number = 0;
  // InvLocationList: any = [];
  // CompanyWareHouseList: any = [];
  InvTypeList: any = [];
  inventSearch: string = this.constants.all;
  SearchText: string = this.constants.SearchText;
  invListColumns: string[] = this.constants.invListColumns;
  PartsCount: number = 0;
  SearchCategoryies: any = this.constants.SearchCategoryies;

  constructor(
    private inventoryService: InventoryService,
    private router: Router,
    private globalChangeService: GlobalChangeService,
    private constants: ConstantsService,
    // private companyService: CompanyService,
    // private sharedService: SharedService
  ){
    this.GetInventoryType();
  }

  ngOnInit(): void {
    //For this we have to create this page in pages table
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    }
    this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
    this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 1018);
    this.permissionMessage = '';
    if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
      if (!this.rolePermissionsList[0].isView) {
        this.permissionMessage += 'view';
      } if (!this.rolePermissionsList[0].isInsert) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'add';
      } if (!this.rolePermissionsList[0].isUpdate) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'edit';
      } if (!this.rolePermissionsList[0].isDelete) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'delete';
      }
    }
    if (this.permissionMessage != '') {
      this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
    }
    // get company by default set value
    var company = this.globalChangeService.getGlobalCompany();
    if (company > 0) {
      this.CompanyDepedsFn(company);
    } else {
      this.PopupModal(this.constants.APIError, this.constants.SelectCompany);
    }
    //on change function 
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      this.CompanyDepedsFn(data);
    })

    this.InventoryType = this.constants.InventoryActionType;
    this.TimePeriodList = this.constants.CustomDate;
  }

  CompanyDepedsFn(comapanyId: number) {
    this.companyId = comapanyId;
    //this.GetCompanyDetails(this.companyId);
    // this.GetWareHouseWithChild(this.companyId);
    this.CalculateInventoryQuantity(this.companyId);
    // this.GetLocationName();
    this.invMasterList = [];
    this.getMasterTableData();
  }

  // GetLocationName() {
  //   this.sharedService.getLocationLevel(this.companyId, '').subscribe(
  //     (response) => {
  //       this.InvLocationList = response;
  //       if (this.InvLocationList.dataList.length > 0) {
  //         this.InvLocationList = this.InvLocationList.dataList.filter(x => x.locationTypeId == 1);
  //       }
  //     },
  //     (error) => {
  //       this.PopupModal(this.constants.APIError, error);
  //     }
  //   )
  // }

  CalculateInventoryQuantity(companyId) {
    this.inventoryService.GetCalculateInventoryQuantity(companyId).subscribe(
      (data: any) => {
        this.loader = false;
        const response = data.data;
        if (response != null) {
          this.OnHand = response.onHand;
          this.OnHandTotal = response.onHandTotal;
          this.Available = response.available;
          this.AvailableTotal = response.availableTotal;
          this.Committed = response.committed;
          this.CommittedTotal = response.committedTotal;
          this.BackOrdered = response.backOrdered;
          this.BackOrderedTotal = response.backOrderedTotal;
          this.PartsCount = response.partsCount;
        }
      },
      (error) => {
        this.loader = false;
        this.PopupModal(this.constants.APIError, error);
      }
    );
  }

  // GetWareHouseWithChild(companyId: Number) {
  //   this.companyService.GetWareHouseWithChild(companyId).subscribe(
  //     (data: any) => {
  //       if (data.data != null) {
  //         this.CompanyWareHouseList = data.data;
  //       }
  //     }
  //   )
  // }

  GetInventoryType() {
    this.inventoryService.GetInventoryType().subscribe(
      (data: any) => {
        this.InvTypeList = data.data;
      }
    )
  }

  Search(text: any){
    this.InvSearchItem = text;
    this.getMasterTableData();
  }

  onMaterInvTypeChange() {
    this.invMasterList = [];
    this.getMasterTableData();
  }

  PopupModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }

  getMasterTableData() {
    const obj: IInventoryItems = {
      CompanyId: this.companyId,
      SearchTerm: this.InvSearchItem,
      InvetoryTypes: this.MasterInvType
    }
    this.inventoryService.GetInventoryItems(obj).subscribe(
      data => {
        this.data = data;
        this.invMasterList = this.data.data;
      },
      error => {
        this.PopupModal(this.constants.APIError, this.constants.SomethingWentWrong);
      });
  }

  SearchPart(event: any) {
  }

  GetInventoryTypeValue(id): string {
    if (this.InvTypeList.length > 0 && id > 0) {
      const list = this.InvTypeList.filter(x => x.typeId == Number(id));
      if (list.length >0) {
        return list[0].typeValue;
      }
    }
    return '';
  }

  selectedRow(row) {
    this.router.navigate(['/inventory-detail', row.item])
  }
}

