import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ConstantsService } from 'src/app/constants.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { InventoryService } from 'src/app/services/inventory/inventory.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-default-location',
  templateUrl: './default-location.component.html',
  styleUrls: ['./default-location.component.css']
})
export class DefaultLocationComponent implements OnInit {
  loader: boolean = false;
  level1List: string[] = [];
  level2List: string[] = [];
  level3List: string[] = [];
  level4List: string[] = [];
  level2List1: string[] = [];
  level3List1: string[] = [];
  level4List1: string[] = [];
  InActivePositionList: any = [];
  locationForm: FormGroup;
  CompanyWareHouseList: any = [];
  InvLocationList: any = [];
  @Input() CompanyDetail: any;
  @Output() CloseDefLocation = new EventEmitter<any>();
  seletedDefaultLocation: any = {};

  constructor(public sharedService: SharedService,
    public companyService: CompanyService,
    private constants: ConstantsService,
    public inventoryService: InventoryService,
    private fb: FormBuilder,
  ) {

  }
  ngOnInit(): void {
    this.locationFormCreate();
    this.GetWareHouseList();
    this.GetDefLocatioList();
    if (this.CompanyDetail.pageName == 'InvFlyOut' || this.CompanyDetail.pageName == 'Putaway') {
      $("#LocationModal").hide()
    } else {
      $("#LocationModal").show();
    }
    this.locationForm.patchValue({ locationId: this.CompanyDetail.locationId, wareHouseId: this.CompanyDetail.wareHouseId });
    this.setLocationLevels(this.locationForm.value.companyId, this.locationForm.value.locationId);
    if (this.CompanyDetail != undefined) {
      this.BindLocationLavel()
    }
    this.InvFlyOut();
  }

  GetWareHouseList() {
    this.companyService.GetWareHouseWithChild(this.CompanyDetail.companyId)
      .subscribe({
        next: (data: any) => {
          this.CompanyWareHouseList = data.data;
        },
        error: (err) => {
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  GetDefLocatioList() {
    this.loader = true;
    this.sharedService.getLocationLevel(this.CompanyDetail.companyId, '')
      .subscribe({
        next: (data: any) => {
          this.InvLocationList = data.dataList;
          this.loader = false;
        },
        error: (err) => {
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  locNameChange(value) {
    this.ResettLocationLevels();
    this.seletedDefaultLocation = this.InvLocationList.length > 0 ? this.InvLocationList.filter(x => x.id == value)[0] : {};
    this.setLocationLevels(this.CompanyDetail.companyId, value);
  }
  ResettLocationLevels() {
    this.locationForm.patchValue({ level1: '', level2: '', level3: '', level4: '', defReceivingLocName: '', locationCapacity: 0 });
  }
  locationFormCreate() {
    this.locationForm = this.fb.group({
      id: new FormControl(0),
      createdBy: new FormControl(localStorage.getItem('userId')),
      companyId: new FormControl(this.CompanyDetail.companyId),
      wareHouseId: new FormControl({ value: 0, disabled: false }),
      locationId: new FormControl({ value: 0, disabled: false }),
      level1: new FormControl({ value: '', disabled: false }),
      level2: new FormControl({ value: '', disabled: false }),
      level3: new FormControl({ value: '', disabled: false }),
      level4: new FormControl({ value: '', disabled: false }),
      defReceivingLocName: new FormControl(''),
      locationCapacity: new FormControl(0),
    });
  }
  BindLocationLavel() {
    this.locationForm.patchValue({
      level1: this.CompanyDetail.level1,
      level2: this.CompanyDetail.level2,
      level3: this.CompanyDetail.level3,
      level4: this.CompanyDetail.level4,
      locationCapacity: this.CompanyDetail.locationCapacity
    });
  }
  setLocationLevels(companyId: number, locationId: number) {
    this.loader = true;
    this.inventoryService.GetLocationLevelsPositions(companyId, locationId)
      .subscribe({
        next: (data: any) => {
          if (data.dataList.length > 0) {
            this.InActivePositionList = data.dataList.filter(s => s.isActive == false);
            data.dataList = data.dataList.filter(s => s.isActive == true);
            const level1 = data.dataList.map(function (item) { return item['aileName']; });
            const level2 = data.dataList.map(function (item) { return item['sectionName']; });
            const level3 = data.dataList.map(function (item) { return item['shelfName']; });
            const level4 = data.dataList.map(function (item) { return item['positionName']; });

            this.level1List = [];
            this.level2List = [];
            this.level2List1 = [];
            this.level3List = [];
            this.level3List1 = [];
            this.level4List = [];
            this.level4List1 = [];

            this.level1List.push(...(level1.filter((value, index, array) => array.indexOf(value) === index && value != null)).sort());
            this.level2List.push(...(level2.filter((value, index, array) => array.indexOf(value) === index && value != null)).sort());
            this.level3List.push(...(level3.filter((value, index, array) => array.indexOf(value) === index && value != null)).sort());
            this.level4List.push(...(level4.filter((value, index, array) => array.indexOf(value) === index && value != null)).sort());

            this.level1List = this.level1List.sort(function (a: any, b: any) { return a - b });
            this.level2List = this.level2List.sort(function (a: any, b: any) { return a - b });
            this.level3List = this.level3List.sort(function (a: any, b: any) { return a - b });
            this.level4List = this.level4List.sort(function (a: any, b: any) { return a - b });

            this.level2List1 = this.level2List
            this.level3List1 = this.level3List
            this.level4List1 = this.level4List

            this.locationForm.get('level2').disable();
            this.locationForm.get('level3').disable();
            this.locationForm.get('level4').disable();
          }
          this.loader = false;
        },
        error: (err) => {
          this.loader = false;
          this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
        },
      });
  }
  BindDefReceivingLocName(): string {
    const Loc = (this.CompanyWareHouseList.filter(x => x.id == this.locationForm.value.wareHouseId)[0].name + ', ' +
      this.InvLocationList.filter(x => x.id == this.locationForm.value.locationId)[0].locationName + ', ' +
      (this.ISNullOrEmpty(this.locationForm.value.level1) ? '-' : this.locationForm.value.level1) + '-' +
      (this.ISNullOrEmpty(this.locationForm.value.level2) ? '-' : this.locationForm.value.level2) + '-' +
      (this.ISNullOrEmpty(this.locationForm.value.level3) ? '-' : this.locationForm.value.level3) + '-' +
      (this.ISNullOrEmpty(this.locationForm.value.level4) ? '-' : this.locationForm.value.level4))
    return Loc;
  }
  AddDefReceivingLoc() {
    if (!this.locationForm.invalid && this.CompanyDetail.pageName == 'Comp') {
      this.loader = true;
      this.locationForm.patchValue({ defReceivingLocName: this.BindDefReceivingLocName() })
      this.companyService.AddDefReceivingLoc(this.locationForm.value)
        .subscribe({
          next: (data: any) => {
            this.closeLocationModel(data.data);
            this.loader = false;
          },
          error: (err) => {
            this.loader = false;
            this.constants.SwalModal(this.constants.Exception, this.constants.SomethingWentWrong);
          },
        });
    } else {
      this.closeLocationModel(this.locationForm.value);
    }
  }
  closeLocationModel(value: any) {
    this.InActivePositionList = [];
    $("#LocationModal").hide();
    this.CloseDefLocation.emit(value);
  }
  get locationFormErr() {
    return this.locationForm.controls;
  }
  Level2Change() {
    this.locationForm.patchValue({ level2: '', level3: '', level4: '' })
    if (this.InActivePositionList.length > 0 && this.level2List1.length > 0 && this.level3List1.length > 0) {
      this.level2List = this.level2List1.filter(x => !this.InActivePositionList.some(y => x === y.positionName && y.shelfName == this.locationFormErr.level2.value));
    } else {
      this.level2List = this.level2List1.filter(x => !this.InActivePositionList.some(y => x === y.sectionName && y.aileName == this.locationFormErr.level1.value));
    }
    this.level3List = [];
    this.level4List = [];
    this.locationForm.get('level2').enable()
  }
  Level3Change() {
    this.locationForm.patchValue({ level3: '', level4: '' })
    if (this.InActivePositionList.length > 0 && this.level3List1.length > 0 && this.level4List1.length == 0) {
      this.level3List = this.level3List1.filter(x => !this.InActivePositionList.some(y => x === y.shelfName && y.sectionName == this.locationFormErr.level2.value && y.aileName == this.locationFormErr.level1.value));
    } else {
      this.level3List = this.level3List1;
    }
    this.level4List = [];
    this.locationForm.get('level3').enable()
  }
  Level4Change() {
    this.locationForm.patchValue({ level4: '' })
    if (this.InActivePositionList.length > 0 && this.level4List1.length > 0) {
      this.level4List = this.level4List1.filter(x => !this.InActivePositionList.some(y => x === y.positionName && y.shelfName == this.locationFormErr.level3.value && y.sectionName == this.locationFormErr.level2.value && y.aileName == this.locationFormErr.level1.value));
      this.locationForm.get('level4').enable()
    }
  }
  ISNullOrEmpty(value: any): boolean {
    let result = false;
    if (value === null || value === undefined || value === '') {
      result = true;
    }
    return result;
  }
  validateNumber() {
    const quantity = this.locationForm.value.locationCapacity;
    this.locationForm.patchValue({ locationCapacity: (this.isWholeNumber(quantity) ? Number(quantity) : 1) });
  }
  isWholeNumber(input: any) {
    const value = Number(input);
    if (!isNaN(value) && value > 0) {
      return true;
    }
    return false;
  }
  InvFlyOut() {
    if (this.CompanyDetail.pageName == 'InvFlyOut' || this.CompanyDetail.pageName == 'Putaway') {
      this.locationForm.valueChanges.subscribe(value => {
        this.CloseDefLocation.emit(this.locationForm.getRawValue());
      });
    }
  }
}
